import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslations } from '../../hooks/useTranslations';

export interface DesktopNavigationProps {
  links?: { label: string; url: string }[];
}

const DesktopNavigation = ({ links }: DesktopNavigationProps) => {
  const ns = 'construo.desktopNavigation';
  const { t } = useTranslations();

  const translations = {
    links: () => {
      let linksArr: DesktopNavigationProps['links'] = [];

      // Max 5 links in Desktop Navigation
      for (let i = 0; i < 5; i++) {
        const linkLabel = t(`${ns}.link${i + 1}Label`);
        const linkUrl = t(`${ns}.link${i + 1}URL`);

        if (linkLabel !== '') {
          linksArr.push({
            label: linkLabel,
            url: linkUrl,
          });
        }
      }

      return linksArr;
    },
  };

  links = links !== undefined ? links : translations.links();

  return !!links?.length ? (
    <nav className='desktop-navigation'>
      <div className='container'>
        <ul className=''>
          {links?.map((link, index) => (
            <li key={index}>
              <NavLink to={link.url}>{link.label}</NavLink>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  ) : null;
};

export default DesktopNavigation;
