import React, { ReactElement, useEffect, useState } from 'react';
import {
  extendDataItem,
  mapTree,
  TreeList,
  TreeListColumnProps,
  TreeListExpandChangeEvent,
  TreeListRowProps,
} from '@progress/kendo-react-treelist';
import { TableData, TableFilterColumns } from './types';
import TableSkeleton from './TableSkeleton';
import TableFilters from './TableFilters';
import {
  CompositeFilterDescriptor,
  filterBy,
} from '@progress/kendo-data-query';
import TableLoadMoreBtn from './TableLoadMoreBtn';

export interface PortfolioTableProps {
  title: string;
  columns: TreeListColumnProps[];
  data: TableData;
  markLastAsFooter?: boolean;
  isLoading?: boolean;
  filterColumns?: TableFilterColumns;
  isLoadMoreEnabled?: boolean;
}

const Table = ({
  title,
  columns,
  data,
  markLastAsFooter,
  isLoading,
  filterColumns,
  isLoadMoreEnabled,
}: PortfolioTableProps) => {
  const expandField: string = 'expanded';
  const subItemsField: string = 'children';

  const [expanded, setExpanded] = useState<number[]>([]);

  const onExpandChange = (e: TreeListExpandChangeEvent) => {
    setExpanded(
      e.value
        ? expanded.filter(id => id !== e.dataItem.recordId)
        : [...expanded, e.dataItem.recordId],
    );
  };

  const callback = (item: any) => {
    const value = expanded.includes(item.recordId)
      ? extendDataItem(item, subItemsField, { [expandField]: true })
      : item;
    return value;
  };

  const rowRender = (
    row: ReactElement<HTMLTableRowElement>,
    rowProperties: TreeListRowProps,
  ) => {
    const isLast = data.length === rowProperties.level[0] + 1;
    const isHighlighted = rowProperties.dataItem.isHighlighted;

    if (markLastAsFooter && isLast) {
      return <row.type {...row.props} className='k-group-footer' />;
    } else if (isHighlighted) {
      return <row.type {...row.props} className='highlighted-row' />;
    } else {
      return row;
    }
  };

  // Filtering
  const initialFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  const [filter, setFilter] = useState(initialFilter);
  const filteredData = filterBy(data, filter);

  // Load more functionality
  const itemsPerPage = 10;
  const [pageNumber, setPageNumber] = useState<number>(1);
  const count = itemsPerPage * pageNumber;
  const visibleData = isLoadMoreEnabled
    ? filteredData.slice(0, count)
    : filteredData;
  const isAllDataVisible = visibleData.length === filteredData.length;

  // Reset Page Number if filter is changed
  useEffect(() => {
    setPageNumber(1);
  }, [filter]);

  return (
    <section className='portfolio-table'>
      {title !== '' && <h4>{title}</h4>}
      {isLoading ? (
        <TableSkeleton numberOfColumns={columns?.length} numberOfRows={4} />
      ) : (
        <>
          {!!filterColumns?.length && !!filteredData.length && (
            <TableFilters
              filteredData={filteredData}
              filterColumns={filterColumns}
              filterState={filter}
              setFilterState={setFilter}
            />
          )}
          <TreeList
            data={mapTree(visibleData, subItemsField, callback)}
            expandField={expandField}
            subItemsField={subItemsField}
            onExpandChange={onExpandChange}
            columns={columns}
            rowRender={rowRender}
          />
        </>
      )}
      {isLoadMoreEnabled && !isAllDataVisible && (
        <TableLoadMoreBtn
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      )}
    </section>
  );
};

export default Table;
