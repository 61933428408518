import React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { NavLink } from 'react-router-dom';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import HelmetTitle from '../common/HelmetTitle';
import Layout from '../layout/Layout';
import { useTranslations } from '../../hooks/useTranslations';
import { getSortedAccountsList } from '../../helpers/utils';
import { selectUser } from '../api-authorization/userSlice';
import {
  selectRepresentationRights,
  selectSelectedAccount,
  setSelectedRepresentation,
} from '../api-authorization/representationSlice';

const ReportPage = () => {
  const dispatch = useAppDispatch();

  const ns = 'construo.homepage';
  const { t } = useTranslations();

  const loginPath = ApplicationPaths.Login;

  const urlString = window.location.href;
  const url = new URL(urlString);
  const eSignStatus = url.searchParams.get('esign');

  /**
   * Provide personalAccount (as default selected account) if user is logged in and account is not selected
   */
  const isLoggedIn = useAppSelector(selectUser).isLoggedIn;
  const accounts = useAppSelector(selectRepresentationRights);
  const selectedAccount = useAppSelector(selectSelectedAccount);
  const hasSelectedAccount = !!selectedAccount;
  const accountsSorted = getSortedAccountsList(accounts);
  const personalAccount = accountsSorted[accountsSorted.length - 1];
  if (isLoggedIn && !hasSelectedAccount) {
    dispatch(setSelectedRepresentation(personalAccount));
  }

  return (
    <>
      <Layout>
        {!!eSignStatus && (
          <>
            <HelmetTitle
              title={
                eSignStatus === 'success'
                  ? t(`${ns}.signingCompleted`)
                  : t(`${ns}.signingUncompleted`)
              }
            />
            <section className='main-section'>
              <div className='container'>
                {eSignStatus === 'success' && (
                  <div
                    className='alert alert-success alert-signing'
                    role='alert'
                  >
                    <div className='icon'>
                      <i
                        className='fas fa-check-circle'
                        style={{ fontSize: '24px' }}
                      />
                    </div>
                    <div className='text'>
                      <h5>{t(`${ns}.signingCompleted`)}</h5>
                      <p>{t(`${ns}.signingCompletedText`)}</p>
                    </div>
                  </div>
                )}
                {eSignStatus === 'failed' && (
                  <div
                    className='alert alert-danger alert-signing'
                    role='alert'
                  >
                    <div className='icon'>
                      <i
                        className='fas fa-exclamation-circle'
                        style={{ fontSize: '24px' }}
                      />
                    </div>
                    <div className='text'>
                      <h5>{t(`${ns}.signingUncompleted`)}</h5>
                      <p>{t(`${ns}.signingUncompletedText`)}</p>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </>
        )}

        {!isLoggedIn && (
          <div className='main-section'>
            <div className='container'>
              <h1>{t(`${ns}.publicHeading`)}</h1>
              <p>{t(`${ns}.publicSubHeading`)}</p>
              <NavLink className='btn btn-primary' to={loginPath}>
                {t(`${ns}.login`)}
              </NavLink>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default ReportPage;
