import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/reduxHooks';
import {
  setVisibilityMenu,
  VisibilityMenu,
} from '../../app/slices/visibilityMenuSlice';
import { UserState } from '../api-authorization/userSlice';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import { Icon } from '@progress/kendo-react-common';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import { setSelectedPortfolioId } from '../pages/portfolios/portfoliosSlice';
import { useTranslations } from '../../hooks/useTranslations';

export type NavigationPropTypes = {
  isUserLogged?: boolean | null;
  isPortfoliosFeature?: boolean;
  isDocumentListFeature?: boolean;
  isPublicationListFeature?: boolean;
  hasNotifications: boolean;
  notifications: number;
};
export interface NavigationProps {
  onHideMenu: () => void;
  navigationProps: NavigationPropTypes;
  userProps: UserState;
}

const Navigation = ({ onHideMenu, navigationProps }: NavigationProps) => {
  const {
    isUserLogged,
    isPortfoliosFeature,
    isDocumentListFeature,
    isPublicationListFeature,
    hasNotifications,
    notifications,
  } = navigationProps;

  const loginPath = `${ApplicationPaths.Login}`;
  const logoutPath = `${ApplicationPaths.LogOut}`;

  const dispatch = useAppDispatch();

  const ns = 'construo.menu';
  const { t } = useTranslations();

  return (
    <>
      {isUserLogged ? (
        <>
          <nav className='global-navigation' aria-label='global-navigation'>
            <ul>
              <li>
                <NavLink
                  end
                  to='/'
                  onClick={() => {
                    dispatch(setVisibilityMenu(VisibilityMenu.Hide));
                  }}
                >
                  <span>{t(`${ns}.home`)}</span>
                </NavLink>
              </li>

              {isPortfoliosFeature && (
                <li>
                  <NavLink
                    to='/portfolios'
                    onClick={() => {
                      dispatch(setSelectedPortfolioId(null));
                      dispatch(setVisibilityMenu(VisibilityMenu.Hide));
                    }}
                  >
                    <span>{t(`${ns}.portfolios`)}</span>
                  </NavLink>
                </li>
              )}

              {isDocumentListFeature && (
                <li>
                  <NavLink
                    to='/documents'
                    onClick={() => {
                      dispatch(setVisibilityMenu(VisibilityMenu.Hide));
                    }}
                  >
                    <span>{t(`${ns}.documents`)}</span>
                  </NavLink>
                </li>
              )}

              {isPublicationListFeature && (
                <li>
                  <NavLink
                    to='/publications'
                    onClick={() => {
                      dispatch(setVisibilityMenu(VisibilityMenu.Hide));
                    }}
                  >
                    <span>{t(`${ns}.publications`)}</span>
                  </NavLink>
                </li>
              )}
            </ul>
          </nav>
          <nav
            className='secondary-navigation'
            aria-label='secondary-navigation'
          >
            <ul>
              <li>
                <NavLink
                  to='/inbox'
                  onClick={() => {
                    dispatch(setVisibilityMenu(VisibilityMenu.Hide));
                  }}
                >
                  <Icon className={'far fa-envelope'} />
                  <BadgeContainer>
                    {t(`${ns}.inbox`)}
                    {hasNotifications && (
                      <Badge
                        rounded={'full'}
                        size={'large'}
                        style={{ top: '50%', left: '100%' }}
                      >
                        {notifications}
                      </Badge>
                    )}
                  </BadgeContainer>
                </NavLink>
              </li>
              <li>
                <NavLink to={logoutPath} onClick={onHideMenu}>
                  <Icon className={'far fa-sign-out'} />
                  <span>{t(`${ns}.logout`)}</span>
                </NavLink>
              </li>
            </ul>
          </nav>
        </>
      ) : (
        <nav className='secondary-navigation'>
          <ul>
            <li>
              <NavLink
                to={loginPath}
                onClick={e => {
                  dispatch(setVisibilityMenu(VisibilityMenu.Hide));
                }}
              >
                <Icon className={'far fa-sign-in'} />
                <span>{t(`${ns}.login`)}</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      )}
    </>
  );
};

export default Navigation;
