import React, { useId } from 'react';
import { Label } from '@progress/kendo-react-labels';
import { ComboBox, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { TableData, TableFilterColumns } from './types';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { useTranslations } from '../../../hooks/useTranslations';

export interface TableFiltersProps {
  filteredData: TableData;
  filterColumns: TableFilterColumns;
  filterState: CompositeFilterDescriptor;
  setFilterState: React.Dispatch<
    React.SetStateAction<CompositeFilterDescriptor>
  >;
}

const TableFilters = ({
  filteredData,
  filterColumns,
  filterState,
  setFilterState,
}: TableFiltersProps) => {
  const ns = 'construo.portfolios';
  const { t } = useTranslations();
  const id = useId();

  const isDateColumn = (test: string) => {
    const dateColumns = [
      'transactionDate',
      'expectedSettlementDate',
      'date1',
      'date2',
      'date3',
      'date4',
      'date5',
    ];
    return dateColumns.includes(test);
  };

  const filters = filterColumns?.map((filterColumn, index, array) => {
    let filterItems = filteredData
      .map((data: any) => {
        let value;
        if (isDateColumn(filterColumn)) {
          value = new Date(data[filterColumn]).getFullYear().toString();
        } else {
          value = data[filterColumn];
        }
        return {
          label: value,
          value: value,
        };
      })
      // Remove duplicates
      .filter(
        (element, index, array) =>
          array.findIndex(item => item.label === element.label) === index,
      );

    // Sort items ascending
    filterItems.sort((a, b) => (a.label > b.label ? -1 : 1)).reverse();

    // Sort items descending for date (year) filter
    if (isDateColumn(filterColumn)) {
      filterItems.reverse();
    }

    return {
      name: filterColumn,
      items: filterItems,
    };
  });

  const handleFilterChange = (e: ComboBoxChangeEvent) => {
    const filterName = e.target.props.name;
    const filterValue = e.target.value && e.target.value.value;
    // Remove current filter from array of filters
    const filtersReduced = filterState.filters.filter((item: any) => {
      return item.field !== filterName;
    });
    const newFilterState = {
      ...filterState,
      filters: !!filterValue
        ? [
            ...filtersReduced,
            {
              field: filterName,
              operator: (itemValue: string, filterValue: string) => {
                if (isDateColumn(filterName!)) {
                  const itemYear = new Date(itemValue).getFullYear().toString();
                  return itemYear === filterValue;
                } else {
                  return itemValue === filterValue;
                }
              },
              value: filterValue,
            },
          ]
        : [...filtersReduced],
    };
    setFilterState(newFilterState);
  };

  return (
    <>
      <div className='portfolio-filters row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4'>
        {filters.map(filter => (
          <div className='col' key={filter.name}>
            <Label editorId={`${id}_${filter.name}`}>
              {t(`${ns}.tableFilterLabels.${filter.name}`)}
            </Label>
            <ComboBox
              name={filter.name}
              id={`${id}_${filter.name}`}
              data={filter.items}
              suggest={true}
              textField={'label'}
              dataItemKey={'value'}
              onChange={e => handleFilterChange(e)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default TableFilters;
