import React, { useRef, useState } from 'react';
import { useAppDispatch } from '../../../../hooks/reduxHooks';
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import envVars from '../../../../resources/envVars';
import { resetTaskDetailsData } from '../taskDetailsSlice';
import { setAlerts } from '../../../../app/slices/alertSlice';
import { resetWorkflowData } from '../../workflowSlice';
import { getAxiosConfig } from '../../../../helpers/utils';
import authService from '../../../api-authorization/AuthorizeService';
import { ActionProps } from '../Actions';
import UploadAttachmentsModal from '../UploadAttachmentsModal';
import { useTranslations } from '../../../../hooks/useTranslations';

const ActionExternalDocuments = ({
  workflowId,
  taskId,
  action,
}: ActionProps) => {
  const dispatch = useAppDispatch();

  const ns = 'construo.workflows';
  const { t } = useTranslations();

  const selectedActionId = useRef<string | null>(null);

  const actionId = action.id;
  const isActionId = actionId === selectedActionId.current;

  const actionName = action.displayName;
  const actionStatus = action.status;

  const inputData = !!action.inputDataBody && JSON.parse(action.inputDataBody);

  const outputData = !!action.outputData && JSON.parse(action.outputData);

  const attachments = outputData?.ExternalDocuments?.Documents;
  const messageToClient = inputData?.ExternalDocuments?.MessageToClient;

  const splitToParagraphs = (message: string) => {
    const result = message.split(/\r?\n/);
    return result;
  };

  const messageToClientResults =
    !!messageToClient && splitToParagraphs(messageToClient); // \n is a separator (line-break)

  const [isShowModal, setIsShowModal] = useState(false);

  const showModal = (modalState: boolean) => {
    setIsShowModal(modalState);
  };

  const completeAction = async (actionId: any) => {
    const token = await authService.getAccessToken();
    const API_BASE_URI = envVars.API_BASE_URI;
    const workflowsUrl = `${API_BASE_URI}/user/workflows`;
    const url = `${workflowsUrl}/${workflowId}/tasks/${taskId}/actions/${actionId}/complete`;
    const data = {};
    const config = getAxiosConfig(token, 'json');
    await axios
      .post(url, data, config)
      .then(response => response.data)
      .then(response => {
        // Reset task details data so that relevant section of the page is reloaded/updated
        dispatch(resetTaskDetailsData());
        // Reset workflows so that task statuses are updated in redux store
        dispatch(resetWorkflowData());
        return response;
      })
      .catch(error => {
        // Show error message after Complete Action Event fails
        dispatch(
          setAlerts({
            message: `${t(`${ns}.completeActionError`)} ${error.response.data.Message}`,
            type: 'error',
          }),
        );
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      });
  };

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 col-xl-9'>
            {isShowModal && !!workflowId && isActionId && (
              <UploadAttachmentsModal
                workflowId={workflowId}
                taskId={taskId}
                actionId={actionId}
                setModalStatus={modalStatus => {
                  showModal(modalStatus);
                }}
              />
            )}

            <h2>{actionName}</h2>

            {!!messageToClient && actionStatus !== 'Done' && (
              <div className='message-to-client'>
                {Array.isArray(messageToClientResults) &&
                messageToClientResults.length > 1 ? (
                  messageToClientResults.map(
                    (messageToClientResult: string, index) => {
                      return <p key={index}>{messageToClientResult}</p>;
                    },
                  )
                ) : (
                  <p>{messageToClientResults}</p>
                )}
              </div>
            )}

            {action.outputData === null ? (
              action.status === 'Done' ? (
                <span className='system-text'>
                  {t(`${ns}.reviewingSubmission`)}
                </span>
              ) : (
                <>
                  <p className='system-text'>{t(`${ns}.noAttachmentsAdded`)}</p>
                  <Button
                    themeColor='primary'
                    onClick={() => {
                      showModal(true);
                      selectedActionId.current = actionId;
                    }}
                  >
                    {t(`${ns}.addFirstDocument`)}
                  </Button>
                </>
              )
            ) : (
              <>
                {!!attachments && attachments.length > 0 && (
                  <ol className='attachments-list list-group list-group-numbered'>
                    {attachments.map(
                      (attachment: {
                        DocumentName: string;
                        DocumentId: string;
                      }) => {
                        return (
                          <li
                            key={attachment.DocumentId}
                            className='list-group-item'
                          >
                            {attachment.DocumentName}
                          </li>
                        );
                      },
                    )}
                  </ol>
                )}
                {actionStatus === 'Pending' && (
                  <p>
                    <Button
                      iconClass={'fal fa-plus'}
                      className={'btn-sm'}
                      onClick={() => {
                        showModal(true);
                        selectedActionId.current = actionId;
                      }}
                    >
                      {t(`${ns}.addDocuments`)}
                    </Button>
                  </p>
                )}
                <p>
                  {actionStatus === 'Done' ? (
                    <span className='system-text'>
                      {t(`${ns}.reviewingSubmission`)}
                    </span>
                  ) : (
                    <Button
                      themeColor='primary'
                      onClick={() => completeAction(actionId)}
                    >
                      {t(`${ns}.completeBtnLabel`)}
                    </Button>
                  )}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionExternalDocuments;
