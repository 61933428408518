import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useNavigate, useParams } from 'react-router-dom';
import envVars from '../../resources/envVars';
import HelmetTitle from '../common/HelmetTitle';
import {
  fetchWorkflows,
  selectWorkflows,
  selectWorkflowsError,
  selectWorkflowsFetched,
} from './workflowSlice';
import {
  fetchConversationsWithUnreadMessages,
  selectConversationsWithUnreadMessages,
  selectConversationsWithUnreadMessagesFetched,
} from './taskDetails/messagesSlice';
import Workflow, { WorkflowProps } from './Workflow';
import { TaskProps } from './Task';
import { useTranslations } from '../../hooks/useTranslations';

const WorkflowProvider = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const ns = 'construo.workflows';
  const { t } = useTranslations();

  // TODO: check why 4 out of 6 is not used
  /*
  const translations = {
    noWorkflowName: useTranslation(`${ns}.noWorkflowName`),
    workflowDescription: useTranslation(`${ns}.workflowDescription`),
    reviewingSubmission: useTranslation(`${ns}.reviewingSubmission`),
    unreadMessages: useTranslation(`${ns}.unreadMessages`),
    completedSubmission: useTranslation(`${ns}.completedSubmission`),
    workflowIdNotFound: useTranslation(`${ns}.workflowIdNotFound`),
  };
*/

  const API_BASE_URI = envVars.API_BASE_URI || '';
  const unreadMessagesUrl = `${API_BASE_URI}/user/conversations/unreadactivities?page=1&pageSize=99`;

  const reduxWorkflows = useAppSelector(selectWorkflows);

  const workflows = reduxWorkflows;

  const workflowMatch = workflows?.filter(
    (wf: WorkflowProps) => wf.id === id,
  )[0];
  const workflow =
    workflows !== null && workflowMatch ? workflowMatch : undefined;

  const workflowsFetched = useAppSelector(selectWorkflowsFetched);

  const workflowsFetchedError = useAppSelector(selectWorkflowsError);
  const workflowMatchError = !!workflowMatch
    ? null
    : t(`${ns}.workflowIdNotFound`);

  const workflowsError = workflowsFetchedError || workflowMatchError;

  useEffect(() => {
    if (workflowsFetched === null) {
      const dataObject = {
        baseUrl: API_BASE_URI,
      };
      dispatch(fetchWorkflows(dataObject));
    }
  }, [dispatch, API_BASE_URI, workflowsFetched]);

  const conversations = useAppSelector(selectConversationsWithUnreadMessages);
  const conversationsFetched = useAppSelector(
    selectConversationsWithUnreadMessagesFetched,
  );

  useEffect(() => {
    /**
     * The tricky behavior of useEffect hook in React 18
     * https://medium.com/geekculture/the-tricky-behavior-of-useeffect-hook-in-react-18-282ef4fb570a
     * let ignore
     */
    let ignore = false;
    if (conversationsFetched === null) {
      const dataObject = {
        url: unreadMessagesUrl,
      };
      setTimeout(() => {
        if (!ignore) {
          dispatch(fetchConversationsWithUnreadMessages(dataObject));
        }
      }, 0);
    }
    return () => {
      ignore = true;
    };
  }, [dispatch, conversationsFetched, unreadMessagesUrl]);

  const getConversationsUnreadMessages = (taskId: string) => {
    const taskConversation = conversations?.filter(
      (conversation: any) => conversation.resourceName === taskId,
    )[0];
    const taskConversationUnreadMessages =
      taskConversation?.numberOfUnreadActivities;
    return taskConversationUnreadMessages;
  };

  const taskList: TaskProps[] = workflow?.tasks?.length
    ? workflow.tasks.map((task: TaskProps) => ({
        ...task,
        conversationsUnreadMessages: getConversationsUnreadMessages(task.id),
      }))
    : [];

  // In case of workflows fetching error (or workflow GUID matching error) you're redirected to "No Resource Page"
  const [showError, setShowError] = useState<boolean>(false);
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setShowError(true);
    }
  }, []);
  useEffect(() => {
    if (workflowsFetched && workflow === undefined) {
      navigate(
        `/resource-not-found?type=workflow&guid=${id}${
          showError ? `&error=${workflowsError}` : ``
        }`,
        { replace: true },
      );
    }
  }, [navigate, workflow, workflowsFetched, id, showError, workflowsError]);

  return (
    <>
      {!!workflow?.displayName ? (
        <HelmetTitle title={workflow.displayName} />
      ) : (
        <HelmetTitle title={t(`${ns}.noWorkflowName`)} />
      )}

      {!!workflow && <Workflow {...workflow} tasks={taskList} />}
    </>
  );
};

export default WorkflowProvider;
