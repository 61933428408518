import React, { createRef, useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import {
  Dialog,
  DialogActionsBar,
  DialogCloseEvent,
} from '@progress/kendo-react-dialogs';
import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from '@progress/kendo-react-upload';
import envVars from '../../resources/envVars';
import authService from '../api-authorization/AuthorizeService';
import { getAxiosConfig } from '../../helpers/utils';
import { Modal } from '../../app/slices/modalSlice';
import { setUploadsFetched } from './uploadSlice';
import { setAlerts } from '../../app/slices/alertSlice';
import { Loading, setLoading } from '../../app/slices/loadingSlice';
import { setLoadingMessage } from '../../app/slices/loadingMessageSlice';
import { useTranslations } from '../../hooks/useTranslations';

export interface UploadRequestModalProps {
  documentId: string;
  documentName: string;
  commentToClient: string;
  companyName: string;
  setModalStatus: (status: Modal.Show | Modal.Hide) => void;
  modalTitle?: string;
  desc?: string;
  fileRestriction?: string;
  btnLabel?: string;
}

const UploadRequestModal = (props: UploadRequestModalProps) => {
  const {
    documentId,
    documentName,
    commentToClient,
    companyName,
    setModalStatus,
    modalTitle,
    desc,
    fileRestriction,
    btnLabel,
  } = props;

  const dispatch = useAppDispatch();

  const ns = 'construo.uploadRequests';
  const { t } = useTranslations();

  const customTitle = !!modalTitle ? modalTitle : t(`${ns}.docUpload`);
  const modalDesc = !!desc ? desc : t(`${ns}.requireDoc`);
  const note = !!fileRestriction ? fileRestriction : t(`${ns}.fileRestriction`);
  const uploadBtnLabel = !!btnLabel ? btnLabel : t(`${ns}.upload`);

  const CustomTitleBar: any = () => {
    return <>{customTitle}</>;
  };

  const [files, setFiles] = useState<Array<UploadFileInfo>>([]);
  const [filePreview, setFilePreview]: any = useState({});
  const [affectedFiles, setAffectedFiles] = useState<Array<UploadFileInfo>>([]);

  useEffect(() => {
    affectedFiles
      .filter((file: any) => !file.validationErrors)
      .forEach((file: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.getRawFile());
        reader.onloadend = (e: any) => {
          setFilePreview({
            [file.uid]: e.target.result,
          });
        };
      });
  }, [affectedFiles]);

  const [hasValidationErrors, setHasValidationErrors]: any = useState(false);

  const onAdd = (event: UploadOnAddEvent) => {
    setFiles(event.newState);
    setAffectedFiles(event.affectedFiles);
    event.affectedFiles.forEach((file: UploadFileInfo) => {
      setHasValidationErrors(!!file.validationErrors ? true : false);
    });
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    let newFilePreview: any = { ...filePreview };
    event.affectedFiles.forEach((file: UploadFileInfo) => {
      delete newFilePreview[file.uid];
    });
    setFiles(event.newState);
    setFilePreview(newFilePreview);
  };

  const UPLOAD_REQUESTS_URI = envVars.API_BASE_URI + '/user/document-requests/';

  const uploadDocument = () => {
    const url = UPLOAD_REQUESTS_URI + documentId + '/upload';

    dispatch(setLoading(Loading.Show));
    dispatch(setLoadingMessage(t(`${ns}.uploadingDoc`)));

    affectedFiles
      .filter((file: any) => !file.validationErrors)
      .forEach((file: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.getRawFile());
        reader.onloadend = async (e: any) => {
          const result = await e.target.result.split(';base64,');
          const mimeType = result[0].split('data:')[1];
          const imageData = result[1];
          const fileName = file.name;
          const data = {
            FileName: fileName,
            MimeType: mimeType,
            ImageData: imageData,
          };
          const dataStringify = JSON.stringify(data);
          const token = await authService.getAccessToken();
          const config = getAxiosConfig(token, 'json');
          uploadDocumentRequest(url, dataStringify, config)
            .then(response => {
              if (!!response) {
                dispatch(setUploadsFetched(null));
                // Show success message after upload resolves successfully
                dispatch(
                  setAlerts({
                    message: t(`${ns}.uploadSuccessful`),
                    type: 'success',
                  }),
                );
                // And scroll to top so that alert is visible
                window.scrollTo(0, 0);
              }
            })
            .catch(err => {
              dispatch(
                setAlerts({
                  message: `${t(`${ns}.uploadFailed`)} ${err.message}`,
                  type: 'error',
                }),
              );
            })
            .finally(() => {
              // After Upload is done (successfully or not) hide modal, remove loading message.
              // In case of an error error message will be displayed
              setModalStatus(Modal.Hide);
              dispatch(setLoading(Loading.Hide));
              dispatch(setLoadingMessage(null));
            });
        };
      });
  };

  const uploadDocumentRequest = async (
    url: string,
    data: string,
    options: object,
  ) => {
    const responseData = await axios
      .put(url, data, options)
      .then(response => response.data)
      .then(response => {
        return response;
      })
      .catch(error => {
        // Show error message after Upload fails
        dispatch(
          setAlerts({
            message: `${t(`${ns}.uploadFailed`)} ${error.message}`,
            type: 'error',
          }),
        );
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      });
    return responseData;
  };

  const uploadRef = createRef<Upload>();

  return (
    <>
      <Dialog
        className='upload-request-modal'
        title={<CustomTitleBar />}
        onClose={(event: DialogCloseEvent) => {
          setModalStatus(Modal.Hide);
        }}
      >
        <p>{modalDesc}</p>
        <div className='upload-file'>
          {!!companyName && <span>{companyName}</span>}
          <h5>{documentName}</h5>
          {!!commentToClient && <p>{commentToClient}</p>}
        </div>
        <Upload
          ref={uploadRef}
          batch={false}
          autoUpload={false}
          multiple={false}
          showActionButtons={false}
          defaultFiles={[]}
          files={files}
          onAdd={onAdd}
          onRemove={onRemove}
          restrictions={{
            allowedExtensions: ['.jpg', '.jpeg', '.png', '.pdf'],
            maxFileSize: 10485760, // 10MB
          }}
        />
        <div className='note'>{note}</div>
        <DialogActionsBar layout='center'>
          <Button
            iconClass='fal fa-upload'
            disabled={hasValidationErrors || files.length === 0 ? true : false}
            themeColor='primary'
            onClick={() => {
              uploadDocument();
            }}
          >
            {uploadBtnLabel}
          </Button>
        </DialogActionsBar>
      </Dialog>
    </>
  );
};

export default UploadRequestModal;
