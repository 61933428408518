import React from 'react';
import UploadActionDetails from './UploadActionDetails';
import { useTranslations } from '../../../hooks/useTranslations';

const UploadActionDetailsProvider = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const actionId = urlParams.get('actionId');
  const actionDisplayName = urlParams.get('displayName');
  const actionUrl = urlParams.get('url');
  const ns = 'construo.inbox';
  const { t } = useTranslations();
  return (
    <UploadActionDetails
      actionId={actionId}
      actionDisplayName={actionDisplayName}
      actionUrl={actionUrl}
      title={t(`${ns}.uploadActionDetailsTitle`)}
      text={t(`${ns}.uploadActionDetailsText`)}
      note={t(`${ns}.uploadActionDetailsNote`)}
      buttonLabel={t(`${ns}.uploadActionDetailsButtonLabel`)}
    />
  );
};

export default UploadActionDetailsProvider;
