import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import authService, { AuthenticationResultStatus } from './AuthorizeService';
import { LoginActions, QueryParameterNames } from './ApiAuthorizationConstants';
import { navigateToReturnUrl } from '../../helpers/utils';
import {
  showMessage,
  setUser,
  selectUser,
  setIsAuthenticated,
} from './userSlice';
import OverallLoader from '../common/OverallLoader';
import Layout from '../layout/Layout';
import { selectTemplateData } from '../pages/public-forms/publicFormSlice';
import { useTranslations } from '../../hooks/useTranslations';

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.

interface Props {
  action: string;
}
export const Login = ({ action }: Props) => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector(selectUser);

  // Get redirectToSubmissionURL from session storage
  let redirectToSubmissionURL = sessionStorage.getItem(
    'redirectToSubmissionURL',
  );
  redirectToSubmissionURL =
    !!redirectToSubmissionURL && JSON.parse(redirectToSubmissionURL);

  const notFirstAttemptRef: any = useRef(null);

  const ns = 'construo.errors';
  const { t } = useTranslations();

  const publicForm = useAppSelector(selectTemplateData);
  const acrValues = publicForm?.RequiredAuthentication;

  const ignore = useRef<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      if (!ignore.current) {
        ignore.current = true;
        const login = async (
          returnUrl: string,
          acrValues?: string[],
        ): Promise<void> => {
          const result = await authService.signIn(returnUrl, acrValues);
          switch (result?.status) {
            case AuthenticationResultStatus.Redirect:
              break;
            case AuthenticationResultStatus.Success:
              const name = `${result?.user?.profile?.given_name} ${result?.user?.profile?.family_name}`;
              dispatch(setUser({ username: name }));
              navigateToReturnUrl(returnUrl);
              break;
            case AuthenticationResultStatus.Fail:
              dispatch(showMessage(result?.message));
              break;
            default:
              throw new Error(`${t(`${ns}.invalidStatus`)} ${result?.status}.`);
          }
        };

        const getReturnUrl = (state?: any) => {
          const params = new URLSearchParams(window.location.search);
          const fromQuery = params.get(QueryParameterNames.ReturnUrl);
          if (
            fromQuery &&
            !fromQuery.startsWith(`${window.location.origin}/`)
          ) {
            // This is an extra check to prevent open redirects.
            throw new Error(t(`${ns}.invalidReturnUrl`));
          }
          var returnUrl = state || fromQuery || `${window.location.origin}/`;
          return returnUrl;
        };

        const processLoginCallback = async (): Promise<void> => {
          const url = window.location.href;
          const result = await authService.completeSignIn(url);
          switch (result?.status) {
            case AuthenticationResultStatus.Redirect:
              // There should not be any redirects as the only time completeSignIn finishes
              // is when we are doing a redirect sign in flow.
              throw new Error(t(`${ns}.shouldNotRedirect`));
            case AuthenticationResultStatus.Success:
              navigateToReturnUrl(getReturnUrl(result?.state));
              const name = `${result?.user?.profile?.given_name} ${result?.user?.profile?.family_name}`;
              dispatch(setUser({ username: name }));
              // Redirect if necessary for "BankId" Form Submission
              if (!!redirectToSubmissionURL && !notFirstAttemptRef.current) {
                notFirstAttemptRef.current = true;
                navigateToReturnUrl(redirectToSubmissionURL);
              }
              break;
            case AuthenticationResultStatus.Fail:
              dispatch(showMessage(result?.message));
              break;
            default:
              throw new Error(`${t(`${ns}.invalidAuth`)} '${result?.status}'.`);
          }
        };

        const processFormAuthenticationCallback = async (): Promise<void> => {
          const url = window.location.href;

          try {
            const result = await authService.completeSignIn(url);
            dispatch(setIsAuthenticated(true));
            const formUrl = sessionStorage.getItem('authUrl');

            switch (result?.status) {
              case AuthenticationResultStatus.Redirect:
                // There should not be any redirects as the only time completeSignIn finishes
                // is when we are doing a redirect sign in flow.
                throw new Error(t(`${ns}.shouldNotRedirect`));
              case AuthenticationResultStatus.Success:
                navigateToReturnUrl(
                  formUrl! + '?isFormAuthenticationCallback=true',
                );
                const name = `${result?.user?.profile?.given_name} ${result?.user?.profile?.family_name}`;
                dispatch(setUser({ username: name }));

                break;
              case AuthenticationResultStatus.Fail:
                dispatch(showMessage(result?.message));
                break;
              default:
                throw new Error(
                  `${t(`${ns}.invalidAuth`)} '${result?.status}'.`,
                );
            }
          } catch (error: any) {
            dispatch(setIsAuthenticated(false));
            throw new Error(error.toString());
          }
        };

        switch (action) {
          case LoginActions.Login:
            login(getReturnUrl());
            break;
          case LoginActions.LoginCallback:
            processLoginCallback();
            break;
          case LoginActions.LoginFailed:
            const params = new URLSearchParams(window.location.search);
            const error = params.get(QueryParameterNames.Message);
            dispatch(showMessage(error));
            break;
          case LoginActions.FormAuthentication:
            login(getReturnUrl(), acrValues);
            break;
          case LoginActions.FormAuthenticationCallback:
            processFormAuthenticationCallback();
            break;
          case LoginActions.FormAuthenticationFailed:
            // const params = new URLSearchParams(window.location.search);
            // const error = params.get(QueryParameterNames.Message);
            // dispatch(showMessage(error));
            break;
          default:
            throw new Error(`${t(`${ns}.invalidAction`)} '${action}'`);
        }
      }
    }, 0);
  }, [action, dispatch, redirectToSubmissionURL, acrValues, t]);

  if (!!userState.message) {
    return (
      <>
        <Layout>
          <div className='main-section'>
            <div className='container'>
              <h1>{userState.message}</h1>
            </div>
          </div>
        </Layout>
      </>
    );
  } else {
    switch (action) {
      case LoginActions.Login:
        return <OverallLoader message={t(`construo.global.processingLogin`)} />;
      case LoginActions.LoginCallback:
        return (
          <OverallLoader
            message={t(`construo.global.processingLoginCallback`)}
          />
        );
      case LoginActions.FormAuthentication:
        return (
          <OverallLoader
            message={t(`construo.forms.processingFormAuthentication`)}
          />
        );
      case LoginActions.FormAuthenticationCallback:
        return (
          <OverallLoader
            message={t(`construo.forms.processingFormAuthenticationCallback`)}
          />
        );
      default:
        throw new Error(`${t(`${ns}.invalidAction`)} '${action}'`);
    }
  }
};
