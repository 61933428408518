import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { Button } from '@progress/kendo-react-buttons';
import { Loading, selectLoading } from '../../../app/slices/loadingSlice';
import { Modal, setModal } from '../../../app/slices/modalSlice';
import { selectPreviewProps } from '../../pages/documents/documentsSlice';
import {
  Dialog,
  DialogActionsBar,
  DialogCloseEvent,
} from '@progress/kendo-react-dialogs';
import { useTranslations } from '../../../hooks/useTranslations';
import { isSafari } from '../../../helpers/utils';

// import { saveAs } from '@progress/kendo-file-saver';

export interface DocumentModalProps {
  props?: any;
  blobSrc?: string;
}

const DocumentModal = (props: DocumentModalProps) => {
  const dispatch = useAppDispatch();

  const ns = 'construo.global';
  const { t } = useTranslations();

  const isEmptyObject = Object.keys(props).length === 0;

  const reduxPreviewProps = useAppSelector(selectPreviewProps);
  const previewProps = !isEmptyObject ? props.props : reduxPreviewProps;

  const contentType = previewProps?.docType;
  const documentSrc = previewProps?.url;

  const documentName = previewProps?.name;
  const documentContacts = previewProps?.contacts;
  const documentCategory = previewProps?.category;

  const blobSource = props.blobSrc;

  const docLoading = useAppSelector(selectLoading) === Loading.Show;

  const isPdf = contentType === 'application/pdf';
  const isImage = contentType.startsWith('image/');

  /**
   * This part of code is used to read blob - readAsDataURL
   */
  // let blob;
  // let reader: any;
  // let readerResult: any;
  // const readBlob = async () => {
  //   blob = await fetch(documentSrc).then(r => r.blob());
  //   reader = new FileReader();
  //   reader.readAsDataURL(blob);
  //   reader.onloadend = (event: any) => {
  //     // The contents of the BLOB are in reader.result:
  //     readerResult = reader.result;
  //     console.log('blob: ', readerResult);
  //   };
  // };
  // if (!!documentSrc) {
  //   readBlob();
  // }

  const contactsList =
    documentContacts?.length > 0
      ? documentContacts?.map((contact: any, index: number) => {
          const fullName = contact.FullName;
          return <span key={index}>{fullName}</span>;
        })
      : '';

  useEffect(() => {
    if (!docLoading && contentType !== '') {
      const object: HTMLElement | null = document.getElementById('object');
      const img: HTMLElement | null = document.getElementById('image');

      if (isPdf) {
        if (!!blobSource) {
          object?.setAttribute('data', blobSource);
        } else {
          object?.setAttribute('data', documentSrc);
        }
      } else if (isImage) {
        if (!!blobSource) {
          img?.setAttribute('src', blobSource);
        } else {
          img?.setAttribute('src', documentSrc);
        }
      }
    }
  }, [documentSrc, contentType, docLoading, blobSource, isImage, isPdf]);

  const downloadDocument = () => {
    const link = document.createElement('a');
    link.href = documentSrc;
    link.download = documentName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // saveAs(documentSrc, documentName);
  };

  const CustomTitleBar: any = () => {
    return (
      <>
        {!!documentCategory && (
          <div className='document-category'>{documentCategory}</div>
        )}
        <h5>{documentName}</h5>
        {!!documentContacts && (
          <div className='document-contacts'>{contactsList}</div>
        )}
      </>
    );
  };

  return (
    <>
      <Dialog
        className='document-modal-dialog'
        title={<CustomTitleBar />}
        onClose={(event: DialogCloseEvent) => dispatch(setModal(Modal.Hide))}
      >
        {isPdf ? (
          <>
            <object
              id='object'
              title=''
              type={isSafari ? '' : 'application/pdf'}
            >
              <div id='append-container'>
                {t(`${ns}.previewNotSupported`)}
                <br />
              </div>
            </object>
          </>
        ) : isImage ? (
          <>
            <img
              id='image'
              alt={documentName}
              title={documentName}
              style={{
                maxHeight: '100%',
                maxWidth: '100%',
              }}
            />
          </>
        ) : (
          <>{t(`${ns}.previewNotAvailable`)}</>
        )}

        <DialogActionsBar layout='center'>
          <Button
            themeColor='primary'
            iconClass='fal fa-download'
            onClick={() => {
              dispatch(setModal(Modal.Hide));
              downloadDocument();
            }}
          >
            {t(`${ns}.download`)}
          </Button>
        </DialogActionsBar>
      </Dialog>
    </>
  );
};

export default DocumentModal;
