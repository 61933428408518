import React from 'react';
import { useAppDispatch } from '../../hooks/reduxHooks';
import ActionRequest from '../common/ActionRequest';
import envVars from '../../resources/envVars';
import authService from '../api-authorization/AuthorizeService';
import { getAxiosConfig } from '../../helpers/utils';
import { Loading, setLoading } from '../../app/slices/loadingSlice';
import { setLoadingMessage } from '../../app/slices/loadingMessageSlice';
import axios from 'axios';
import { setAlerts } from '../../app/slices/alertSlice';
import { useInternationalization } from '@progress/kendo-react-intl';
import { useTranslations } from '../../hooks/useTranslations';

type SignatureRequestProps = {
  signature: {
    taskId: string;
    signingRequestTaskUrl: string;
    taskSubject: {
      fullName: string;
    };
    taskDocumentActions: {
      documentName: string;
    };
    expiryDateTime: string;
  };
};

const SignatureRequest = ({ signature }: SignatureRequestProps) => {
  const dispatch = useAppDispatch();

  const ns = 'construo.signatureRequests';
  const { t } = useTranslations();

  const eSignTaskId = signature.taskId;
  const taskDocumentActions: any = signature.taskDocumentActions;
  const signatureName = taskDocumentActions[0].documentName;
  const heading = !!signatureName ? signatureName : t(`${ns}.noSignatureName`);

  let expiryDateTime: string | undefined = signature.expiryDateTime;
  expiryDateTime = expiryDateTime.replace(/\./g, ':'); // replace all dots (.) with (:)
  expiryDateTime = expiryDateTime.replace(/:([^:]*)$/, '.$1'); // replace last (:) with (.)

  const intl = useInternationalization();
  const date = intl.formatDate(new Date(expiryDateTime as string), {
    date: 'short',
  });

  const signingRequestsBaseUri =
    envVars.API_BASE_URI + '/user/signing-requests/';

  const redirectToESign = async (e: React.MouseEvent) => {
    e.preventDefault();
    const token = await authService.getAccessToken();
    const config = getAxiosConfig(token, 'json');
    const url = signingRequestsBaseUri + eSignTaskId + '/redirectUrl';

    dispatch(setLoading(Loading.Show));
    dispatch(setLoadingMessage(t(`${ns}.redirectToESignLoadingText`)));

    await axios
      .get(url, config)
      .then(response => {
        window.location.assign(response.data);
      })
      .catch(error => {
        dispatch(setLoading(Loading.Hide));
        dispatch(setLoadingMessage(null));
        // Show error message after submission fails
        dispatch(setAlerts({ message: error.message, type: 'error' }));
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      });
  };

  return (
    <ActionRequest
      type='signature'
      onClick={e => redirectToESign(e)}
      heading={heading}
      notification={
        !!expiryDateTime ? `${t(`${ns}.signBefore`)} ${date}` : null
      }
    />
  );
};

export default SignatureRequest;
