import React from 'react';
import { useInternationalization } from '@progress/kendo-react-intl';
import { useConfig } from '../../../../hooks/useConfig';
import { useTranslations } from '../../../../hooks/useTranslations';

export interface PortfoliosHeaderProps {
  isLoading: boolean;
  totalMarketValue: number;
}

const PortfoliosHeader = ({
  isLoading,
  totalMarketValue,
}: PortfoliosHeaderProps) => {
  const intl = useInternationalization();
  const config = useConfig();

  const ns = 'construo.homepage.portfoliosSection';
  const { t } = useTranslations();

  return isLoading ? null : (
    <>
      <header className='portfolios-header'>
        <p className='total-market-value'>
          <span className='total-market-value-label'>
            {t(`${ns}.totalMarketValue`)}
          </span>
          <span className='total-market-value-number'>
            {intl.formatNumber(totalMarketValue, 'n0')}{' '}
            {config.currencyAbbreviation}
          </span>
        </p>
      </header>
    </>
  );
};

export default PortfoliosHeader;
