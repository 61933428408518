import React from 'react';
import HelmetTitle from '../../common/HelmetTitle';
import { Card, CardBody, CardSubtitle } from '@progress/kendo-react-layout';
import Layout from '../../layout/Layout';
import LanguageSwitcher from '../../common/LanguageSwitcher';
import { useLocation } from 'react-router-dom';
import { useTranslations } from '../../../hooks/useTranslations';

const NotFound = () => {
  const location = useLocation();
  const pathName = `${location?.pathname}`;
  const ns = 'construo.global';
  const { t } = useTranslations();
  return (
    <>
      <HelmetTitle title={t(`${ns}.page404Title`)} />

      <Layout isSplashLayout={true} layoutClassName='not-found-layout'>
        <div className='main-section not-found'>
          <div className='container'>
            <Card>
              <CardBody>
                <h1 className='k-card-title'>{t(`${ns}.page404Title`)}</h1>
                <CardSubtitle>
                  {t(`${ns}.page404Text`, [pathName])}
                </CardSubtitle>
              </CardBody>
            </Card>
            <LanguageSwitcher />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default NotFound;
