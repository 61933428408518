import {
  useLocalization,
  useInternationalization,
} from '@progress/kendo-react-intl';

/**
 * Hook to get translations
 */
export const useTranslations = () => {
  const localizationService = useLocalization();
  const intlService = useInternationalization();

  /**
   * Translate a key to the current language using the localization service
   * and the intl service to format the string, while replacing placeholders
   * @param key The key to translate
   * @param placeholders The placeholders to be replaced in the translation
   * @returns The translated string
   */
  const t = (key: string, placeholders?: any[]) => {
    return intlService.format(
      localizationService.toLanguageString(key, key),
      placeholders,
    );
  };
  return { t };
};
