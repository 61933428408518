import React from 'react';
import envVars from '../../resources/envVars';
import { useTranslations } from '../../hooks/useTranslations';

const Logo = () => {
  const customerName = envVars.CUSTOMER_NAME;
  const customFolderName = envVars.CUSTOM_FOLDER_NAME;
  const { t } = useTranslations();
  const logo = require(
    `../../custom/${customFolderName}/assets/media/logo.svg`,
  );

  return <img src={logo} alt={t('construo.header.logoAlt', [customerName])} />;
};

export default Logo;
