import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { Link } from 'react-router-dom';
import { Card, CardBody } from '@progress/kendo-react-layout';
import { Icon } from '@progress/kendo-react-common';
import PublicationsSectionSkeleton from './PublicationsSectionSkeleton';
import { useInternationalization } from '@progress/kendo-react-intl';
import { resetAlerts, setAlerts } from '../../../../app/slices/alertSlice';
import { handleDocumentClick } from '../../../../helpers/events';
import { selectRepresentationRights } from '../../../api-authorization/representationSlice';
import { useTranslations } from '../../../../hooks/useTranslations';

export interface PublicationsSectionProps {
  isLoading: boolean;
  publications: {
    [key: string]: object[];
  };
  publicationsError: string | null;
}

const PublicationsSection = ({
  isLoading,
  publications,
  publicationsError,
}: PublicationsSectionProps) => {
  const dispatch = useAppDispatch();
  const intl = useInternationalization();

  const ns = 'construo.homepage.publicationsSection';
  const { t } = useTranslations();
  const loadingMessage = t(`${ns}.loadingPublicationPreview`);
  const categoryNameTranslations = [
    t(`${ns}.categoryName_1`),
    t(`${ns}.categoryName_2`),
    t(`${ns}.categoryName_3`),
    t(`${ns}.categoryName_4`),
  ];

  const categories: string[] = Object.keys(publications);

  const publicationCategoryList = categories.map(
    (category: string, index: number) => {
      const publicationsInsideCategory = publications[category];
      const publicationList = publicationsInsideCategory.map(
        (publication: any) => {
          return (
            <li key={publication?._id} className='list-group-item'>
              <a
                href={`/documents/download/${publication?._id}`}
                onClick={e =>
                  handleDocumentClick(e, publication, dispatch, loadingMessage)
                }
              >
                <span className='item-text'>
                  <span className='item-link-label'>
                    {publication?.DocumentName}
                  </span>
                  <span className='item-date'>
                    {intl.formatDate(
                      new Date(publication.DocumentDate as string),
                      {
                        date: 'short',
                      },
                    )}
                  </span>
                </span>
                <Icon className='fal fa-file-alt icon' />
              </a>
            </li>
          );
        },
      );
      return (
        <div className='col-md-6' key={category}>
          <h3 className='h5'>{categoryNameTranslations[index]}</h3>
          {publicationList?.length > 0 ? (
            <ul className='list-group list-group-flush'>{publicationList}</ul>
          ) : (
            <p>{t(`${ns}.noPublicationsMessage`)}</p>
          )}
        </div>
      );
    },
  );

  useEffect(() => {
    if (!!publicationsError) {
      dispatch(
        setAlerts({
          message: publicationsError,
          type: 'error',
        }),
      );
      // And scroll to top so that alert is visible
      window.scrollTo(0, 0);
    }
  }, [dispatch, publicationsError]);

  useEffect(() => {
    return () => {
      dispatch(resetAlerts());
    };
  }, [dispatch]);

  const representationRights = useAppSelector(selectRepresentationRights);
  if (representationRights?.length === 0) return null;

  return (
    <section className='publications-section main-section'>
      <div className='container'>
        <h2>{t(`${ns}.title`)}</h2>
        <Card>
          <CardBody>
            {isLoading || !!publicationsError ? (
              <PublicationsSectionSkeleton />
            ) : (
              <>
                <div className='row'>{publicationCategoryList}</div>
                <div className='row'>
                  <p className='publications-link'>
                    <Link
                      className='btn btn-outline-primary'
                      to='/publications'
                    >
                      {t(`${ns}.goToPublications`)}
                    </Link>
                  </p>
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </div>
    </section>
  );
};

export default PublicationsSection;
