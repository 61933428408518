import React from 'react';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectUser } from '../../api-authorization/userSlice';
import { ApplicationPaths } from '../../api-authorization/ApiAuthorizationConstants';
import HomePrivate from './HomePrivate';
import HomePublic from './HomePublic';
import HelmetTitle from '../../common/HelmetTitle';
import Layout from '../../layout/Layout';
import { useTranslations } from '../../../hooks/useTranslations';

const Home = () => {
  const userState = useAppSelector(selectUser);
  const isUserLogged = userState.isLoggedIn;
  const userContactGuid = userState.contactGuid;
  const loginPath = ApplicationPaths.Login;
  const { t } = useTranslations();

  return (
    <>
      <HelmetTitle title={t('construo.menu.home')} />

      {isUserLogged ? (
        <Layout>
          <HomePrivate userContactGuid={userContactGuid} />
        </Layout>
      ) : (
        <Layout isSplashLayout={true} layoutClassName='home-public-layout'>
          <HomePublic loginPath={loginPath} />
        </Layout>
      )}
    </>
  );
};

export default Home;
