import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useIdleTimer } from 'react-idle-timer';
import envVars from '../../resources/envVars';
import {
  removeUser,
  selectUser,
  showMessage,
} from '../api-authorization/userSlice';
import authService, {
  AuthenticationResultStatus,
} from '../api-authorization/AuthorizeService';
import { navigateToReturnUrl } from '../../helpers/utils';
import { useTranslations } from '../../hooks/useTranslations';

interface IdleTimerContainerProps {
  isModalOpen?: boolean;
}

const IdleTimerContainer = ({
  isModalOpen: isModalOpenProp,
}: IdleTimerContainerProps) => {
  const dispatch = useAppDispatch();
  const ns = 'construo.idleTimer';
  const { t } = useTranslations();

  const allowedIdleDuration: number = envVars.IDLE_TIME || 600;
  const modalIdleDuration: number = envVars.IDLE_DIALOG_TIME || 30;

  const userState = useAppSelector(selectUser);

  // const [timeRemaining, setTimeRemaining] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const isVisible =
    isModalOpenProp === undefined ? isModalOpen : isModalOpenProp;

  const onPrompt = () => {
    setIsModalOpen(true);
  };

  const onIdle = () => {
    logOut();
    clearInterval(interval.current);
  };

  const {
    start,
    // getTabId,
    getRemainingTime,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    timeout: 1000 * allowedIdleDuration,
    promptBeforeIdle: 1000 * modalIdleDuration,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    crossTab: true,
    name: 'idle-timer',
    syncTimers: 200,
  });

  const logOut = () => {
    // Logout user
    logout('/');
  };

  const stayLoggedIn = () => {
    setIsModalOpen(false);
    start();
  };

  const logout = async (returnUrl: string): Promise<void> => {
    if (userState.isLoggedIn) {
      const result = await authService.signOut();
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          break;
        case AuthenticationResultStatus.Success:
          dispatch(removeUser());
          navigateToReturnUrl(returnUrl);
          break;
        case AuthenticationResultStatus.Fail:
          dispatch(showMessage(result.message));
          break;
        default:
          throw new Error(t('construo.errors.invalidAuth'));
      }
    } else {
      dispatch(showMessage(t('construo.global.successfullyLoggedOut')));
    }
  };

  // const tabId = getTabId();
  const interval: any = useRef(null);

  useEffect(() => {
    interval.current = setInterval(() => {
      // setTimeRemaining(Math.round(getRemainingTime() / 1000));
      if (Math.round(getRemainingTime() / 1000) > modalIdleDuration) {
        setIsModalOpen(false);
      }
    }, 1000);
    return () => {
      clearInterval(interval.current);
    };
  });

  return (
    <>
      {isVisible ? (
        <Dialog
          className='idle-time-modal'
          closeIcon={false}
          title={t(`${ns}.modalTitle`)}
        >
          <p>{t(`${ns}.modalMessage`)}</p>
          <DialogActionsBar layout='center'>
            <Button themeColor='secondary' onClick={logOut}>
              {t(`${ns}.logMeOut`)}
            </Button>
            <Button themeColor='primary' onClick={stayLoggedIn}>
              {t(`${ns}.keepMeSignedIn`)}
            </Button>
          </DialogActionsBar>
        </Dialog>
      ) : null}
      {/* Temp display box for time remaining, and tab id... to be used for debugging purposes */}
      {/* <div
        style={{
          position: 'fixed',
          right: '10px',
          bottom: '10px',
          backgroundColor: 'red',
          color: 'white',
          zIndex: '800',
          textAlign: 'right',
          padding: '10px 20px',
          minWidth: '200px',
        }}
      >
        Time remaining: {timeRemaining}
        <br />
        tab ID: {tabId}
      </div> */}
    </>
  );
};

export default IdleTimerContainer;
