import React from 'react';
import HelmetTitle from '../common/HelmetTitle';
import { Logout } from './Logout';
import { useTranslations } from '../../hooks/useTranslations';

interface LogoutActionProps {
  name: string;
}

const LogoutAction = ({ name }: LogoutActionProps) => {
  const ns = 'construo.global';
  const { t } = useTranslations();
  return (
    <>
      <HelmetTitle title={t(`${ns}.logoutTitle`)} />
      <Logout action={name}></Logout>
    </>
  );
};

export default LogoutAction;
