import React from 'react';
import { Holding } from '../pages/portfolios/types';
import { useConfig } from '../../hooks/useConfig';
import { useTranslations } from '../../hooks/useTranslations';

export interface PortfoliosLastUpdatedInfoProps {
  portfolio?: Holding;
  lastUpdatedInfoPlaceholders?: string[];
}

const PortfoliosLastUpdatedInfo = ({
  portfolio,
  lastUpdatedInfoPlaceholders: lastUpdatedInfoPlaceholdersProp,
}: PortfoliosLastUpdatedInfoProps) => {
  const config = useConfig();
  const ns = 'construo.portfolios';
  const { t } = useTranslations();

  const lastUpdatedInfoPlaceholders =
    lastUpdatedInfoPlaceholdersProp === undefined
      ? config.portfolios.lastUpdatedInfoPlaceholders
      : lastUpdatedInfoPlaceholdersProp;

  const lastUpdatedTxtArgs = lastUpdatedInfoPlaceholders.map(
    (key: keyof Holding) => {
      const date = portfolio && portfolio[key];
      const isStringValidDate = !isNaN(Date.parse(date as string));
      return isStringValidDate ? new Date(date as Date) : date;
    },
  );

  const isSecondSentenceVisible = portfolio?.int1 === 1; // If property "int1" is defined and equal to 1 show second sentence (lackOfUpdatesTxt) after lastUpdatedTxt
  const lackOfUpdates = isSecondSentenceVisible
    ? t(`${ns}.lackOfUpdatesTxt`)
    : '';

  return (
    <>
      {!!portfolio && (
        <p className='portfolios-last-updated-text'>
          {t(`${ns}.lastUpdatedTxt`, lastUpdatedTxtArgs)} {lackOfUpdates}
        </p>
      )}
    </>
  );
};

export default PortfoliosLastUpdatedInfo;
