import React from 'react';
import envVars from '../../resources/envVars';
import { useTranslations } from '../../hooks/useTranslations';

export interface FooterProps {
  text?: React.ReactNode;
  links?: { label: string; url: string }[];
}

const Footer = ({ text, links }: FooterProps) => {
  const customerName = envVars.CUSTOMER_NAME;
  const currentYear = new Date().getFullYear();

  const ns = 'construo.footer';
  const { t } = useTranslations();
  const translations = {
    links: () => {
      let linksArr: FooterProps['links'] = [];

      // Max 3 links in footer
      for (let i = 0; i < 3; i++) {
        const linkLabel = t(`${ns}.link${i + 1}Label`);
        const linkUrl = t(`${ns}.link${i + 1}URL`);

        if (linkLabel !== '') {
          linksArr.push({
            label: linkLabel,
            url: linkUrl,
          });
        }
      }

      return linksArr;
    },
  };

  text =
    text !== undefined ? text : t(`${ns}.text`, [customerName, currentYear]);
  links = links !== undefined ? links : translations.links();

  return (
    <footer className='global-footer'>
      <div className='container'>
        <div className='row'>
          {!!links?.length && (
            <div className='col-md-6 order-md-1'>
              <div className='global-footer-navigation'>
                <ul className='global-footer-nav'>
                  {links?.map((link, index) => (
                    <li key={index}>
                      <a
                        href={link.url}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {link.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          <div className='col-md-6 order-md-0'>
            <div className='global-footer-text'>{!!text && text}</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
