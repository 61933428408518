import React, { Dispatch } from 'react';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import {
  selectBulkDownloadItems,
  setBulkDownloadItems,
} from '../pages/documents/documentsSlice';
import { useInternationalization } from '@progress/kendo-react-intl';
import { handleDocumentClick } from '../../helpers/events';
import { useTranslations } from '../../hooks/useTranslations';

export interface ListItemProps {
  props: any;
  checkedArray: object[];
}

const ListItem = ({ props, checkedArray }: ListItemProps) => {
  const dispatch: Dispatch<any> = useAppDispatch();
  const intl = useInternationalization();

  const ns = 'construo.documents';
  const { t } = useTranslations();

  let item = props.dataItem;
  const itemId = item._id;

  const checked: object[] =
    checkedArray.length > 0
      ? checkedArray.filter((checked: any) => checked._id === itemId)
      : [];
  const isChecked = checked.length > 0;

  const date = intl.formatDate(new Date(item.DocumentDate as string), {
    date: 'short',
  });

  const bulkDownloadItems = useAppSelector(selectBulkDownloadItems);

  const handleCheckboxChange = (e: any, item: any) => {
    const checkObject = {
      _id: itemId,
      FileName: item.FileName,
      checked: e.value,
    };
    let newArray: object[];
    if (checkObject.checked) {
      // add element to array
      newArray = [...bulkDownloadItems, checkObject];
    } else {
      // remove element from array
      newArray = bulkDownloadItems.filter(
        (el: any) => el._id !== checkObject._id,
      );
    }
    dispatch(setBulkDownloadItems(newArray));
  };

  const contactsList =
    item.Contacts?.length > 0
      ? item.Contacts?.map((contact: any, index: number) => {
          const fullName = contact.FullName;
          return <span key={index}>{fullName}</span>;
        })
      : '';

  return (
    <>
      <div className='list-item'>
        <div className='list-item-checkbox'>
          <Checkbox
            checked={isChecked}
            onChange={e => handleCheckboxChange(e, item)}
          />
        </div>

        <div className='list-item-document'>
          <div className='list-item-document-name'>
            {item.Category && (
              <div className='list-item-document-category'>{item.Category}</div>
            )}
            <a
              href={`/documents/download/${itemId}`}
              onClick={e =>
                handleDocumentClick(
                  e,
                  item,
                  dispatch,
                  t(`${ns}.loadingDocumentPreview`),
                )
              }
            >
              {item.DocumentName}
            </a>
            <div className='list-item-document-contacts'>{contactsList}</div>
          </div>
          <div className='list-item-date'>{date}</div>
        </div>
      </div>
    </>
  );
};

export default ListItem;
