import React from 'react';
import { useTranslations } from '../../hooks/useTranslations';

const FormSealed = () => {
  const ns = 'construo.forms';
  const { t } = useTranslations();
  return (
    <>
      <h1>{t(`${ns}.formSealedHeading`)}</h1>
      <p>{t(`${ns}.formSealedText`)}</p>
    </>
  );
};

export default FormSealed;
