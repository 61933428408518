import React, { useState } from 'react';
import { useAppSelector } from '../../hooks/reduxHooks';
import { Loading, selectLoading } from '../../app/slices/loadingSlice';
import { Modal } from '../../app/slices/modalSlice';
import UploadRequestModal from './UploadRequestModal';
import ActionRequest from '../common/ActionRequest';
import { useTranslations } from '../../hooks/useTranslations';

type UploadRequestProps = {
  upload: any;
};

const UploadRequest = ({ upload }: UploadRequestProps) => {
  const ns = 'construo.uploadRequests';
  const { t } = useTranslations();

  const documentId = upload._id;
  const documentName = upload.DocumentName;
  const heading = !!documentName ? documentName : t(`${ns}.noDocumentName`);
  const commentToClient = upload.CommentToClient;
  const contacts = upload.Contacts;

  // TODO: adjust finding of companies if there is possibility to have more then one company in contacts array
  const companies = contacts?.find(
    (contact: any) => contact.ContactType === 'Company',
  );
  let company;
  if (!!companies) {
    company = companies.FullName;
  }

  const handleRequestClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setModalShow(Modal.Show);
  };

  const [modalShow, setModalShow]: any = useState(Modal.Hide);
  const docLoading = useAppSelector(selectLoading) === Loading.Show;

  return (
    <>
      {/* UPLOAD REQUEST MODAL DIALOG */}
      {modalShow === Modal.Show && !docLoading && (
        <UploadRequestModal
          documentId={documentId}
          companyName={company}
          documentName={documentName}
          commentToClient={commentToClient}
          setModalStatus={(modalStatus: any) => {
            setModalShow(modalStatus);
          }}
        />
      )}
      <ActionRequest
        type='upload'
        onClick={e => handleRequestClick(e)}
        heading={heading}
      />
    </>
  );
};

export default UploadRequest;
