import React from 'react';
import AccountMenu from './AccountMenu';
import Navigation, { NavigationPropTypes } from './Navigation';
import LanguageSwitcher from '../common/LanguageSwitcher';
import { UserState } from '../api-authorization/userSlice';
import { SelectedAccountProps } from '../api-authorization/representationSlice';
import { useTranslations } from '../../hooks/useTranslations';

export interface GlobalMenuProps {
  isMultiLanguage?: boolean;
  userProps: UserState;
  navigationProps: NavigationPropTypes;
  onHideMenu: () => void;
  hasRepresentations?: boolean;
  selectedAccount: SelectedAccountProps | null;
}

const GlobalMenu = ({
  isMultiLanguage,
  userProps,
  navigationProps,
  onHideMenu,
  hasRepresentations,
  selectedAccount,
}: GlobalMenuProps) => {
  const { t } = useTranslations();

  return (
    <>
      <div className='global-menu'>
        <button type='button' className='btn-close' onClick={onHideMenu}>
          <span className='sr-only'>{t('construo.menu.close')}</span>
        </button>

        <AccountMenu
          onHideMenu={onHideMenu}
          userProps={userProps}
          hasRepresentations={hasRepresentations}
          selectedAccount={selectedAccount}
        />

        <Navigation
          onHideMenu={onHideMenu}
          navigationProps={navigationProps}
          userProps={userProps}
        />

        <LanguageSwitcher isMultiLanguage={isMultiLanguage} />
      </div>
    </>
  );
};

export default GlobalMenu;
