import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@progress/kendo-react-common';
import { useTranslations } from '../../hooks/useTranslations';

export interface ActionRequestProps {
  type: 'signature' | 'upload' | 'submission' | 'workflow';
  heading: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  href?: string;
  notification?: string | null;
}

const ActionRequest = ({
  type,
  heading,
  onClick,
  href = '/',
  notification,
}: ActionRequestProps) => {
  const ns = 'construo.inbox';

  const { t } = useTranslations();

  const [actionName, setActionName] = useState<string>();
  const [iconClass, setIconClass] = useState<string>();

  useEffect(() => {
    switch (type) {
      case 'signature':
        setActionName(t(`${ns}.signatureRequired`));
        setIconClass('file-signature');
        break;
      case 'upload':
        setActionName(t(`${ns}.documentRequired`));
        setIconClass('cloud-upload');
        break;
      case 'submission':
        setActionName(t(`${ns}.completeForm`));
        setIconClass('sparkles');
        break;
      case 'workflow':
        setActionName(t(`${ns}.ongoingApplicationProcess`));
        setIconClass('business-time');
        break;
      default:
      // default code
    }
  }, [type, t]);

  return (
    <>
      <Link
        to={href}
        onClick={onClick}
        className='list-group-item list-group-item-action action-request'
      >
        <Icon className={`far fa-${iconClass} fa-fw`} />
        <div className='request-info'>
          <div className='request-info-text'>
            <p className='action-name'>{actionName}</p>
            <p className='action-heading'>{heading}</p>
          </div>
          {notification && <div className='badge'>{notification}</div>}
        </div>
      </Link>
    </>
  );
};

export default ActionRequest;
