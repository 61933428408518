import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useTranslations } from '../../hooks/useTranslations';

const FormInaccessible = () => {
  const ns = 'construo.forms';
  const { t } = useTranslations();

  return (
    <>
      <h1>{t(`${ns}.formInaccessibleHeading`)}</h1>
      <p>{t(`${ns}.formInaccessibleText`)}</p>
      <Button themeColor='primary' onClick={() => window.location.reload()}>
        {t(`${ns}.reload`)}
      </Button>
    </>
  );
};

export default FormInaccessible;
