import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import HelmetTitle from '../../common/HelmetTitle';
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout';
import Table from './Table';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import PieChart, { PieChartDataItem } from './PieChart';
import Graph, { PerformanceGraphData } from './Graph';
import { Holding, IndexRecord, Transaction } from './types';
import {
  TreeListColumnProps,
  createDataTree,
} from '@progress/kendo-react-treelist';
import PortfoliosLastUpdatedInfo from '../../common/PortfoliosLastUpdatedInfo';
import { useInternationalization } from '@progress/kendo-react-intl';
import { getSumTableRow } from '../../../helpers/utils';
import PortfoliosSkeleton from './PortfoliosSkeleton';
import { resetAlerts, setAlerts } from '../../../app/slices/alertSlice';
import { useConfig } from '../../../hooks/useConfig';
import Layout from '../../layout/Layout';
import { useTranslations } from '../../../hooks/useTranslations';

interface PortfoliosProps {
  isLoading: boolean;
  hasUserPortfolios: boolean;
  hasAccountPortfolios: boolean;
  accountPortfolios?: Holding[];
  initialTab?: number;
  selectedPortfolio?: Holding;
  selectedRecordId?: number;

  holdingsData: Holding[];
  timeSeriesData: PerformanceGraphData[];
  transactionsData: Transaction[];
  indicesData: IndexRecord[];

  isHoldingsFetched: boolean | undefined;
  isTimeSeriesFetched: boolean | undefined;
  isTransactionsFetched: boolean | undefined;
  isIndicesFetched: boolean | undefined;

  userPortfoliosError: string | null;
  holdingsError: string | false | undefined;
  timeSeriesError: string | false | undefined;
  transactionsError: string | false | undefined;
  indicesError: string | undefined;

  lastUpdatedInfo?: JSX.Element;

  allocationsPieChart?: JSX.Element;
  allocationsTable?: JSX.Element;
  allocationTableColumns: TreeListColumnProps[];
  allocationTableReportLevel: number;

  holdingsTable?: JSX.Element;
  holdingsTableColumns: TreeListColumnProps[];
  holdingsTableReportLevel: number;

  performanceGraph?: JSX.Element;
  performanceTable?: JSX.Element;
  performanceTableColumns: TreeListColumnProps[];
  performanceTableReportLevel: number;
  performanceTableTwo?: JSX.Element;
  performanceTableTwoColumns: TreeListColumnProps[];
  performanceTableTwoReportLevel: number;

  transactionsTable?: React.ReactElement;
  transactionsTableColumns: TreeListColumnProps[];
  transactionsReportLevel: number;

  indexTable?: JSX.Element;
  indexTableColumns: TreeListColumnProps[];
  indexTableReportLevel: number;

  onChange: (e: any) => void;
}

const Portfolios = ({
  isLoading,
  accountPortfolios,
  initialTab: initialTabProp,
  selectedPortfolio,
  selectedRecordId,
  hasUserPortfolios,
  hasAccountPortfolios,

  holdingsData,
  timeSeriesData,
  transactionsData,
  indicesData,

  isHoldingsFetched,
  isTimeSeriesFetched,
  isTransactionsFetched,
  isIndicesFetched,

  userPortfoliosError,
  holdingsError,
  timeSeriesError,
  transactionsError,
  indicesError,

  lastUpdatedInfo,

  allocationsPieChart,
  allocationsTable,
  allocationTableColumns,
  allocationTableReportLevel,

  holdingsTable,
  holdingsTableColumns,
  holdingsTableReportLevel,

  performanceGraph,
  performanceTable,
  performanceTableColumns,
  performanceTableReportLevel,
  performanceTableTwo,
  performanceTableTwoColumns,
  performanceTableTwoReportLevel,

  transactionsTable,
  transactionsTableColumns,
  transactionsReportLevel,

  indexTable,
  indexTableColumns,
  indexTableReportLevel,

  onChange,
}: PortfoliosProps) => {
  const ns = 'construo.portfolios';
  const { t } = useTranslations();

  const dispatch = useAppDispatch();

  const [hasErrors, setHasErrors] = useState<boolean>(false);

  useEffect(() => {
    if (!!userPortfoliosError) {
      setHasErrors(true);
      dispatch(
        setAlerts({
          message: `${t(`${ns}.userPortfoliosError`)} ${userPortfoliosError}`,
          type: 'error',
        }),
      );
    }
  }, [dispatch, userPortfoliosError, t]);

  useEffect(() => {
    if (!!holdingsError) {
      setHasErrors(true);
      dispatch(
        setAlerts({
          message: `${t(`${ns}.holdingsError`)} ${holdingsError}`,
          type: 'error',
        }),
      );
    }
  }, [dispatch, holdingsError, t]);

  useEffect(() => {
    if (!!timeSeriesError) {
      setHasErrors(true);
      dispatch(
        setAlerts({
          message: `${t(`${ns}.timeSeriesError`)} ${timeSeriesError}`,
          type: 'error',
        }),
      );
    }
  }, [dispatch, timeSeriesError, t]);

  useEffect(() => {
    if (!!transactionsError) {
      setHasErrors(true);
      dispatch(
        setAlerts({
          message: `${t(`${ns}.transactionsError`)} ${transactionsError}`,
          type: 'error',
        }),
      );
    }
  }, [dispatch, transactionsError, t]);

  useEffect(() => {
    if (!!indicesError) {
      setHasErrors(true);
      dispatch(
        setAlerts({
          message: `${t(`${ns}.indicesError`)} ${indicesError}`,
          type: 'error',
        }),
      );
    }
  }, [dispatch, indicesError, t]);

  useEffect(() => {
    return () => {
      dispatch(resetAlerts());
    };
  }, [dispatch]);

  const initialTab = initialTabProp === undefined ? 0 : initialTabProp;
  const [selectedTab, setSelectedTab] = useState<number>(initialTab);

  const intl = useInternationalization();

  const handleSelectTab = (e: TabStripSelectEventArguments) => {
    setSelectedTab(e.selected);
  };

  const marketValue: number = !!selectedPortfolio?.periodEndMarketValue
    ? selectedPortfolio.periodEndMarketValue
    : 0;

  const LastUpdatedInfo = () => {
    return lastUpdatedInfo === undefined ? (
      <PortfoliosLastUpdatedInfo portfolio={selectedPortfolio} />
    ) : (
      lastUpdatedInfo
    );
  };

  const config = useConfig();
  const pieChartColors = config.portfolios.allocationsPieChart.colors;

  const AllocationsPieChart = () => {
    let data = holdingsData ? holdingsData : [];
    data = data.filter((item: any) => item.parentId === selectedRecordId);
    const pieChartData: PieChartDataItem[] = data.map(
      (item: any, index: number) => {
        return {
          title: item.title,
          periodEndMarketValue: item.periodEndMarketValue,
          color: !!pieChartColors && pieChartColors[index],
        };
      },
    );
    const isLoading =
      !isHoldingsFetched ||
      !isTimeSeriesFetched ||
      !isTransactionsFetched ||
      !isIndicesFetched;
    return allocationsPieChart === undefined ? (
      <>
        <PieChart data={pieChartData} isLoading={isLoading} />
      </>
    ) : (
      allocationsPieChart
    );
  };

  const AllocationsTable = () => {
    let flatData = holdingsData ? holdingsData : [];
    flatData = flatData.filter(
      (item: any) =>
        item.portfolioId === selectedPortfolio?.portfolioId &&
        item.reportLevel < allocationTableReportLevel + 1,
    );
    const subItemsField = 'children';
    const data = createDataTree(
      flatData,
      i => i.recordId,
      i => i.parentId,
      subItemsField,
    );
    const lastRow = getSumTableRow(accountPortfolios, selectedRecordId);
    if (!!lastRow) {
      const lastRowUpdated = {
        ...lastRow,
        title: t(`${ns}.tableAllocationFooterRowTitle`),
      };
      data.push(lastRowUpdated);
    }
    return allocationsTable === undefined ? (
      <>
        <Table
          title={t(`${ns}.tableAllocationTitle`)}
          columns={allocationTableColumns}
          data={data}
          markLastAsFooter={true}
          isLoading={!data.length || !allocationTableColumns}
        />
      </>
    ) : (
      allocationsTable
    );
  };

  const HoldingsTable = () => {
    let flatData = holdingsData ? holdingsData : [];
    flatData = flatData.filter(
      (item: any) =>
        item.portfolioId === selectedPortfolio?.portfolioId &&
        item.reportLevel < holdingsTableReportLevel + 1,
    );
    const subItemsField = 'children';
    const data = createDataTree(
      flatData,
      i => i.recordId,
      i => i.parentId,
      subItemsField,
    );
    const lastRow = getSumTableRow(accountPortfolios, selectedRecordId);
    if (!!lastRow) {
      const lastRowUpdated = {
        ...lastRow,
        title: t(`${ns}.tableHoldingsFooterRowTitle`),
      };
      data.push(lastRowUpdated);
    }
    return holdingsTable === undefined ? (
      <Table
        title={t(`${ns}.tableHoldingsTitle`)}
        columns={holdingsTableColumns}
        data={data}
        markLastAsFooter={true}
        isLoading={!data.length || !holdingsTableColumns}
      />
    ) : (
      holdingsTable
    );
  };

  const PerformanceGraph = () => {
    return performanceGraph === undefined ? (
      <Graph data={timeSeriesData} isLoading={!timeSeriesData.length} />
    ) : (
      performanceGraph
    );
  };

  const PerformanceTable = () => {
    let flatData = holdingsData ? holdingsData : [];
    flatData = flatData.filter(
      (item: any) =>
        item.portfolioId === selectedPortfolio?.portfolioId &&
        item.reportLevel < performanceTableReportLevel + 1,
    );
    const subItemsField = 'children';
    const data = createDataTree(
      flatData,
      i => i.recordId,
      i => i.parentId,
      subItemsField,
    );
    const lastRow = getSumTableRow(accountPortfolios, selectedRecordId);
    if (!!lastRow) {
      const lastRowUpdated = {
        ...lastRow,
        title: t(`${ns}.tablePerformanceFooterRowTitle`),
      };
      data.push(lastRowUpdated);
    }
    return performanceTable === undefined ? (
      <Table
        title={t(`${ns}.tablePerformanceTitle`)}
        columns={performanceTableColumns}
        data={data}
        markLastAsFooter={true}
        isLoading={!data.length || !performanceTableColumns}
      />
    ) : (
      performanceTable
    );
  };

  const PerformanceTableTwo = () => {
    let flatData = holdingsData ? holdingsData : [];
    flatData = flatData.filter(
      (item: any) =>
        item.portfolioId === selectedPortfolio?.portfolioId &&
        item.reportLevel < performanceTableTwoReportLevel + 1,
    );
    const subItemsField = 'children';
    const data = createDataTree(
      flatData,
      i => i.recordId,
      i => i.parentId,
      subItemsField,
    );
    const lastRow = getSumTableRow(accountPortfolios, selectedRecordId);
    if (!!lastRow) {
      const lastRowUpdated = {
        ...lastRow,
        title: t(`${ns}.tablePerformanceTwoFooterRowTitle`),
      };
      data.push(lastRowUpdated);
    }
    return performanceTableTwo === undefined ? (
      <Table
        title={t(`${ns}.tablePerformanceTwoTitle`)}
        columns={performanceTableTwoColumns}
        data={data}
        markLastAsFooter={true}
        isLoading={!data.length || !performanceTableTwoColumns}
      />
    ) : (
      performanceTableTwo
    );
  };

  const TransactionsTable = () => {
    const transactionsDataTranslated =
      transactionsData &&
      transactionsData.map((item: Transaction) => {
        return {
          ...item,
          type: t(`${ns}.transactionsTableData.type.${item['type']}`),
          status: t(`${ns}.transactionsTableData.status.${item['status']}`),
        };
      });

    let flatData = transactionsDataTranslated ? transactionsDataTranslated : [];
    flatData = flatData.filter(
      (item: any) => item.reportLevel < transactionsReportLevel + 1,
    );
    const subItemsField = 'children';
    const data = createDataTree(
      flatData,
      i => i.recordId,
      i => i.parentId,
      subItemsField,
    );

    return transactionsTable === undefined ? (
      <Table
        title={t(`${ns}.tableTransactionsTitle`)}
        columns={transactionsTableColumns}
        data={data}
        isLoading={!isTransactionsFetched || !transactionsTableColumns}
        isLoadMoreEnabled={true}
        filterColumns={['transactionDate', 'type']}
      />
    ) : (
      transactionsTable
    );
  };

  const IndexTable = () => {
    let flatData = indicesData ? indicesData : [];
    flatData = flatData.filter(
      (item: any) => item.reportLevel < indexTableReportLevel + 1,
    );
    const subItemsField = 'children';
    const data = createDataTree(
      flatData,
      i => i.recordId,
      i => i.parentId,
      subItemsField,
    );
    return indexTable === undefined ? (
      <Table
        title={t(`${ns}.tableIndexTitle`)}
        columns={indexTableColumns}
        data={data}
        isLoading={!data.length || !indexTableColumns}
      />
    ) : (
      indexTable
    );
  };

  return (
    <>
      <HelmetTitle title={t(`${ns}.title`)} />
      <Layout>
        <div className='main-section portfolios'>
          <div className='container'>
            <h1>{t(`${ns}.title`)}</h1>
            {isLoading || hasErrors ? (
              <PortfoliosSkeleton />
            ) : !hasUserPortfolios ? (
              <>
                <p>{t(`${ns}.userWithoutPortfoliosText`)}</p>
              </>
            ) : !hasAccountPortfolios ? (
              <>
                <p>{t(`${ns}.accountWithoutPortfoliosText`)}</p>
              </>
            ) : (
              <>
                {accountPortfolios!.length > 1 ? (
                  <DropDownList
                    className='portfolio-drop-down h2'
                    data={accountPortfolios}
                    textField='title'
                    dataItemKey='portfolioId'
                    value={selectedPortfolio}
                    fillMode={null}
                    size='large'
                    onChange={(e: any) => onChange(e)}
                  />
                ) : (
                  <h2>{selectedPortfolio?.title}</h2>
                )}

                <h3 className='portfolio-value'>
                  {intl.formatNumber(marketValue, 'n0')}{' '}
                  {config.currencyAbbreviation}
                </h3>

                <TabStrip selected={selectedTab} onSelect={handleSelectTab}>
                  <TabStripTab
                    title={t(`${ns}.tabAllocationTitle`)}
                    contentClassName='allocation-tab'
                  >
                    <LastUpdatedInfo />
                    <AllocationsPieChart />
                    <AllocationsTable />
                  </TabStripTab>
                  <TabStripTab
                    title={t(`${ns}.tabHoldingsTitle`)}
                    contentClassName='holdings-tab'
                  >
                    <LastUpdatedInfo />
                    <HoldingsTable />
                  </TabStripTab>
                  <TabStripTab
                    title={t(`${ns}.tabPerformanceTitle`)}
                    contentClassName='performance-tab'
                  >
                    <LastUpdatedInfo />
                    <PerformanceGraph />
                    <PerformanceTable />
                    <PerformanceTableTwo />
                  </TabStripTab>
                  <TabStripTab
                    title={t(`${ns}.tabTransactionsTitle`)}
                    contentClassName='transactions-tab'
                  >
                    <LastUpdatedInfo />
                    <TransactionsTable />
                  </TabStripTab>
                  <TabStripTab
                    title={t(`${ns}.tabIndexTitle`)}
                    contentClassName='index-tab'
                  >
                    <LastUpdatedInfo />
                    <IndexTable />
                  </TabStripTab>
                </TabStrip>

                <p className='disclaimer-text'>{t(`${ns}.disclaimerText`)}</p>
              </>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Portfolios;
