import React from 'react';
import { TaskProps } from './Task';
import TaskList from './TaskList';
import Layout from '../layout/Layout';
import { useTranslations } from '../../hooks/useTranslations';

export interface WorkflowProps {
  id: string;
  displayName: string;
  contactFullName: string;
  contactGuid: string;
  tasks: TaskProps[];
}

const Workflow = ({ displayName, tasks }: WorkflowProps) => {
  const { t } = useTranslations();

  return (
    <>
      <Layout>
        <section className='workflow-details main-section'>
          <div className='container'>
            <h1>{displayName}</h1>

            <p>{t(`construo.workflows.workflowDescription`)}</p>

            <TaskList tasks={tasks} />
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Workflow;
