import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import envVars from '../../resources/envVars';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { setAlerts } from '../../app/slices/alertSlice';
import { useTranslations } from '../../hooks/useTranslations';
import { setLoadingMessage } from '../../app/slices/loadingMessageSlice';
import { useNavigate } from 'react-router';
import { Loading, setLoading } from '../../app/slices/loadingSlice';

type SignalRConnectionProps = {
  connection: null | HubConnection;
  setConnection: Dispatch<SetStateAction<HubConnection | null>>;
  isConnectionStarted: boolean;
  setIsConnectionStarted: any;
  isDirectSign: boolean;
  isFormSubmitted: boolean;
};

const SignalRConnection = ({
  connection,
  setConnection,
  isConnectionStarted,
  setIsConnectionStarted,
  isDirectSign,
  isFormSubmitted,
}: SignalRConnectionProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const ns = 'construo.forms';
  const { t } = useTranslations();

  const timer = useRef<NodeJS.Timeout>();
  const display = useRef<NodeJS.Timeout>();
  const i = useRef<number>(0);

  const stopTimer = () => {
    clearTimeout(timer.current);
    clearInterval(display.current);
  };

  useEffect(() => {
    return () => {
      stopTimer();
    };
  }, []);

  const connectionId = useRef<string | null>(null);

  useEffect(() => {
    const gatewayHubUrl: string = envVars.GATEWAY_HUB_URL || '';
    const signalRLogLevel =
      process.env.NODE_ENV === 'production' ? LogLevel.None : LogLevel.Debug;

    if (!connection) {
      // OPEN SignalR Connection
      const connect = new HubConnectionBuilder()
        .withUrl(gatewayHubUrl)
        .configureLogging(signalRLogLevel)
        .withAutomaticReconnect()
        .build();
      // SET Connection
      setConnection(connect);
    }

    return () => {
      connection?.stop();
    };
  }, [connection, setConnection]);

  useEffect(() => {
    // START (if not started) SignalR Connection and LISTEN for EVENTS ('Notify' and 'RedirectToSign')
    if (
      !!connection &&
      !connectionId.current &&
      connection.state === 'Disconnected' &&
      isDirectSign
    ) {
      connection
        .start()
        .then(() => {
          !!setIsConnectionStarted && setIsConnectionStarted(true);
          connectionId.current = connection.connectionId;
          connection.on('RedirectToUrl', redirectURL => {
            stopTimer();
            window.location.href = redirectURL;
          });
          connection.on('Notify', message => {
            // Show messages (translations) depending of message.status, status values can be 1 or 0
            if (message.status === 1) {
              dispatch(
                setLoadingMessage(t(`${ns}.loaderCreatingSigningRequest`)),
              );
            } else {
              dispatch(setLoading(Loading.Hide));
              dispatch(setLoadingMessage(null));
              navigate('/forms/information-page');
            }
          });
        })
        .catch(error => {
          dispatch(
            setAlerts({
              message: `${t(
                `${ns}.alertErrorCreatingSigningRequest`,
              )}. ${error.toString()}`,
              type: 'error',
            }),
          );
        });
    }
  }, [dispatch, navigate, connection, setIsConnectionStarted, isDirectSign, t]);

  useEffect(() => {
    const SUBMISSION_TIMEOUT_TIME = envVars.SUBMISSION_TIMEOUT_TIME || 180;
    const timeoutTime = SUBMISSION_TIMEOUT_TIME * 1000; // convert seconds in ms

    const timeExpired = () => {
      dispatch(setLoading(Loading.Hide));
      dispatch(setLoadingMessage(null));
      stopTimer();
      navigate('/forms/information-page');
    };

    if (isFormSubmitted && isConnectionStarted) {
      /**
       * Start Timeout timer
       * Timeout time is defined in configuration variable
       * Default value 180 seconds
       * If time expire error message is shown in Alert box
       */
      timer.current = setTimeout(timeExpired, timeoutTime);

      display.current = setInterval(() => {
        i.current++;
      }, 1000);
    }
  }, [dispatch, navigate, isConnectionStarted, isFormSubmitted]);

  return null;
};

export default SignalRConnection;
