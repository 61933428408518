import nbKendoMessages from './kendo/nb.json';

export const nbProductMessages = {
  ...nbKendoMessages,
  construo: {
    global: {
      loginTitle: 'Logg inn',
      logoutTitle: 'Logg ut',
      loading: 'Laster ...',
      successfullyLoggedOut: 'Du er nå logget ut',
      loggedOutHeading: 'Takk for besøket',
      loggedOutSubHeading:
        'Du har logget ut. Hvis du bruker en offentlig datamaskin anbefaler vi at du lukker nettleseren.',
      logBackIn: 'Logg inn igjen',
      download: 'Last ned',
      previewNotSupported:
        'Forhåndsvisning er ikke tilgjengelig på denne enheten. Last ned filen for å se den.',
      previewNotAvailable:
        'Denne filen kan ikke forhåndsvises. Last ned filen for å se den.',
      processingLogin:
        'Vi sjekker nettleseren din for å logge deg inn. Dette kan ta et øyeblikk.',
      processingLoginCallback: 'Vi logger deg inn. Vennligst vent.',
      processingLogout:
        'Vi logger deg av på en sikker måte. Vennligst ikke lukk dette vinduet.',
      processingLogoutCallback: 'Du blir logget ut.',
      gotoLoginPage: 'Klikk her for å logge inn',
      page404Title: '404 Error - Side ikke funnet',
      page404Text:
        'Siden med URL {0} ble ikke funnet. Gå tilbake til hjemmesiden og prøv igjen.',
      resource404Title: '{0} ble ikke funnet',
      resource404Text:
        '{0} du prøver å åpne ble ikke funnet. Den eksisterer kanskje ikke, eller du har ikke lenger tilgang til den. Hvis du åpnet denne siden ved å skrive inn en URL i nettleseren din, vennligst kontroller at du har skrevet den inn riktig.',
      resourceType: 'Siden',
      submissionType: 'Skjemaet',
      workflowType: 'Arbeidsflyten',
      taskType: 'Oppgaven',
      conversationType: 'Meldingen',
      documentType: 'Dokumentet',
      id: 'ID',
      resource: 'Ressurs',
      type: 'Type',
      invalid: 'Ugyldig ID-format',
    },
    header: {
      logoAlt: 'Logo av {0}',
      menuBtnLabel: 'Meny',
    },
    desktopNavigation: {
      link1Label: '',
      link1URL: '',
      link2Label: '',
      link2URL: '',
      link3Label: '',
      link3URL: '',
      link4Label: '',
      link4URL: '',
      link5Label: '',
      link5URL: '',
    },
    footer: {
      text: '© {0} {1}',
      link1Label: 'Lenke 1',
      link1URL: 'https://google.com/',
      link2Label: 'Lenke 2',
      link2URL: 'https://google.com/',
      link3Label: '',
      link3URL: '',
    },
    menu: {
      close: 'Lukk',
      home: 'Hjem',
      portfolios: 'Porteføljer',
      documents: 'Dokumenter',
      publications: 'Publikasjoner',
      messages: 'Meldinger',
      inbox: 'Innboks',
      login: 'Logg inn',
      logout: 'Logg ut',
      languageSwitcherLabel: 'Språk',
      english: 'English',
      norwegian: 'Norsk',
      finnish: 'Suomi',
      swedish: 'Svenska',
      accountNameTagline: '{0} for',
      switchAccount: 'Bytt enhet',
    },
    selectAccount: {
      title: 'Velg enhet',
      heading: 'Velkommen, {0}',
      subheading: 'Velg selskap / kunde du ønsker å se informasjon for',
    },
    homepage: {
      publicHeading: 'Velkommen til vår portal',
      publicSubHeading: 'Vennligst logg inn for å fortsette',
      login: 'Logg inn',
      actionRequired: 'Dine oppgaver:',
      latestDocuments: 'Siste dokumenter',
      goToDocuments: 'Gå til alle dokumenter',
      signingCompleted: 'Signaturen er fullført',
      signingCompletedText: 'Takk! Du har fullført signeringen.',
      signingUncompleted: 'Signatur mislyktes',
      signingUncompletedText:
        'Dokumentet ble ikke signert. Vennligst prøv igjen senere.',
      portfoliosSection: {
        title: 'Porteføljer',
        totalMarketValue: 'Markedsverdi',
        periodEndMarketValue: 'Markedsverdi',
        mtdTotalGain: 'Avkastning hittil i måneden',
        ytdTotalGain: 'Avkastning hittil i år',
        sinceInceptionTotalGain: 'Avkastning siden oppstart {0}',
        seePortfolio: 'Se hele porteføljen',
      },
      publicationsSection: {
        title: 'Publikasjoner',
        goToPublications: 'Se alle publikasjoner',
        loadingPublicationPreview: 'Laster inn publikasjoner...',
        noPublicationsMessage:
          'Det er ingen publikasjoner å vise for øyeblikket.',
        categoryName_1: 'Kategori 1',
        categoryName_2: 'Kategori 2',
        categoryName_3: 'Kategori 3',
        categoryName_4: 'Kategori 4',
      },
    },
    signatureRequests: {
      noSignatureName: 'Din signeringsforespørsel',
      signBefore: 'Signatur kreves før',
      redirectToESignLoadingText: 'Vi vidersender deg til dokumentet ...',
      userMissingNinAndBankId:
        'Vi kunne dessverre ikke hente ut din informasjon fra BankID på dette tidspunktet. Vennligst prøv igjen senere. Dersom problemet vedvarer, vennligst ta kontakt for assistanse.',
      userMissingNinAndLocal:
        'e-signeringsforespørsler er kun tilgjengelige etter innlogging med digital identitet. Vennligst logg inn med BankID for å se din signaturforespørsel. Dersom du har mottatt en e-post fra oss med varsel om en åpen e-signeringsforespørsel og du ikke har BankID, vennligst ta kontakt.',
    },
    uploadRequests: {
      uploadBefore: 'Last opp før',
      upload: 'Last opp',
      docUpload: 'Opplasting',
      uploadingDoc: 'Dokumentet ditt lastes opp',
      requireDoc: 'Vi trenger følgende dokument fra deg:',
      requireAttachments:
        'Vennligst last opp den forespurte dokumentasjonen her. Du kan laste opp 15 dokumenter om gangen.',
      fileRestriction:
        'På mobil kan du velge en fil eller ta et bilde. Kun PDF-dokumenter og bilder (.jpg, .jpeg, .png) er tillatt. Maksimal filstørrelse for opplasting er 10 MB.',
      noDocumentName: 'Din dokumentforespørsel',
      noCommentToClient: '',
      uploadSuccessful: 'Takk, vi har mottatt dokumentet',
      uploadFailed: 'Vi beklager, noe gikk galt med opplastingen:',
    },
    documents: {
      title: 'Dokumenter',
      publications: 'Publikasjoner',
      documentName: 'Dokumentnavn',
      contacts: 'Kontakt',
      category: 'Kategori',
      documentType: 'Dokumenttype',
      documentDate: 'Dato',
      nameAsc: 'Dokumentnavn (A-Z)',
      nameDesc: 'Dokumentnavn (Z-A)',
      dateNewestFirst: 'Dato (siste først)',
      dateOldestFirst: 'Dato (eldste først)',
      noDocumentsMessage: 'Det er ingen dokumenter å vise for øyeblikket.',
      noResultsMessage:
        'Det er ingen resultater som samsvarer med søkekriteriene eller filterinnstillingene.',
      selectedToDownload: 'markert for nedlasting',
      bulkDownloadZipFileName: 'Dokumenter',
      preparingBulkDownload: 'Vi forbereder dokumentene dine for nedlasting',
      filters: 'Filtre',
      filterByContacts: 'Kontakt',
      filterByCategory: 'Kategori',
      filterByType: 'Dokumenttype',
      filterByYear: 'År',
      filterByDocumentName: 'Søk med dokumentnavn',
      closeFilters: 'Lukk filtre',
      clearFilters: 'Tilbakestill filtre',
      applyFilters: 'Bruk filtre',
      sortBy: 'Sorter etter',
      loadMore: 'Last inn mer',
      loadingDocumentPreview: 'Laster inn dokument...',
    },
    idleTimer: {
      modalTitle: 'Er du fortsatt her?',
      modalMessage:
        'Du har vært inaktiv en stund og blir automatisk logget ut om et øyeblikk',
      logMeOut: 'Logg meg ut',
      keepMeSignedIn: 'Forbli logget inn',
    },
    forms: {
      alertTest: 'Dette er et testskjema og kan ikke sendes inn.',
      alertErrorCreatingSigningRequest:
        'Beklager, det oppstod en feil under behandlingen. Vi vil kontakte deg med mer informasjon.',
      loaderCreatingSigningRequest: 'Ett øyeblikk, du vil bli omdirigert.',
      loaderSubmittingForm: 'Dine svar blir sendt inn.',
      loaderGeneratingPdf: 'Ett øyeblikk, vi behandler opplysningene dine.',
      processingFormAuthentication: 'Vennligst vent, siden lastes.',
      processingFormAuthenticationCallback:
        'Vi identifiserer deg, takk for at du venter.',
      formSubmittedHeading: 'Skjema er sendt inn',
      formSubmittedText: 'Takk, du har sendt inn svarene dine.',
      formInaccessibleHeading: 'Skjema utilgjengelig',
      formInaccessibleText:
        'Beklager, dette skjemaet er ikke lenger tilgjengelig. Kontakt oss ​​for videre assistanse.',
      reload: 'Oppdater',
      smsSecurityCode: 'Engangskode på SMS',
      inOrderToAccess:
        'For å få tilgang til skjemaet trenger du en engangskode som sendes til ditt mobiltelefonnummer.',
      sendCode: 'Send koden',
      sentSixDigitCode: 'Vi har sendt deg en sekssifret engangskode.',
      enterBelow: 'Oppgi engangskoden nedenfor:',
      accessBtnLabel: 'Tilgang',
      didNotReceiveCode: 'Fikk du ikke koden?',
      requestCode: 'Send ny kode',
      waitToRequestCode: 'Vent 60 sekund før du ber om en ny kode.',
      inputValidatorText:
        'Vi har sent deg en SMS-sikkerhetskode. Vennligst skriv inn koden nedenfor.',
      fieldHint: 'Skriv inn SMS-koden her',
      bankIdLoginWarningHeading: 'Logg inn for å fortsette',
      bankIdLoginWarningText:
        'For å få tilgang til dette innholdet må du logge på med en e-identitet som BankId.',
      tokenExpiredHeading: 'Lenken er ikke lenger gyldig',
      tokenExpiredText:
        'Tilgangslenken til dette skjemaet utløp {0}. Ta kontakt med oss ​​for å motta en ny lenke.',
      formSealedHeading: 'Låst skjema',
      formSealedText:
        'Dette skjemaet er allerede sendt inn og kan ikke lenger endres.',
      pleaseCompleteForm: 'Vennligst fullfør skjemaet',
      chooseFormSubmissionTitle: 'Hvordan ønsker du å fortsette?',
      chooseFormSubmissionP1Text: 'Dette skjemaet har blitt automatisk lagret.',
      chooseFormSubmissionP2Text:
        'Vil du vise den automatisk lagrede eller tidligere versjon?',
      tempButtonLabel: 'Automatisk lagret',
      savedButtonLabel: 'Tidligere versjon',
      noSubmissionName: 'Ditt kundeskjema',
      submissionCouldNotBeLoaded:
        'Noe gikk galt. Skjema kunne ikke lastes opp.',
      otherUserWorkingOnSubmission:
        'Skjema er ikke tilgjengelig, da det er i bruk av noen andre.',
      submissionSavedTitle: 'Skjema er lagret',
      submissionSavedText:
        'Skjema er lagret, så du kan trygt forlate siden og navigere tilbake når du ønsker å fortsette.',
      saveForLater: 'Lagre',
      saveForLaterNote: 'Dersom du ønsker å fortsette senere kan du trykke',
      returnHome: 'Gå tilbake til hjemmesiden',
      formLockedByUser:
        'Du kan ikke oppgi informasjon i dette skjemaet fordi noen andre arbeider i skjemaet for øyeblikket. Vennligst forsøk å åpne skjemaet igjen om noen minutter.',
      formSaved:
        'Endringene dine har blitt lagret. Du kan nå lukke skjemaet og fortsette på et senere tidspunkt.',
      formNotSaved:
        'Vi beklager, noe gikk galt da vi lagret endringene dine. Vennligst prøv igjen.',
      formSubmitted: 'Takk, vi har mottatt din innlevering.',
      formSubmissionNot: 'Vi beklager, noe gikk galt og skjemaet kunne ikke',
      locked: 'låses',
      unlocked: 'låses opp',
      submitError: 'Noe gikk galt med skjemainnleveringen:',
      autoSaveError: 'Noe gikk galt med automatisk lagring av skjema:',
      noFormData:
        'På grunn av manglende data kan ikke skjemaet vises. Vennligst kontakt oss.',
      showSubmittedData: 'Vis innsendte data',
      hideSubmittedData: 'Skjul innsendte data',
      requiredAuthenticationInfoPageHeader: 'Vi vil komme tilbake til deg',
      requiredAuthenticationInfoPageText:
        'Vi vil komme tilbake til deg i løpet av de neste dagene.',
    },
    workflows: {
      noWorkflowName: 'Din søknadsprosess',
      workflowDescription:
        'Vi trenger ytterligere informasjon fra deg for å ferdigstille prosessen. Vennligst fullfør oppgavene under.',
      accessedBy: 'Denne oppgaven er tilgjengelig for:',
      noAttachmentsAdded: 'Ingen vedlegg har blitt lagt til enda.',
      addFirstDocument: 'Legg til første dokument',
      addDocuments: 'Legg til flere dokumenter',
      reviewingSubmission:
        'Vi gjennomgår den mottatte dokumentasjonen og kontakter deg ved behov for mer informasjon.',
      completedSubmission: 'Fullført',
      completeBtnLabel: 'Fullfør dette steget',
      noActionAdded: 'Ingen åpne oppgaver.',
      completeActionSuccess: 'Takk, steget er fullført.',
      completeActionError: 'Steget kunne ikke fullføres:',
      unreadMessages: 'Ulest melding',
      readReceiptsError: 'Systemet kunne ikke markere meldingen som lest:',
      messagesTitle: 'Meldinger',
      initializationText: 'Skriv melding…',
      placeholderText: 'Skriv melding…',
      anonymousAuthorName: 'Kundesenteret',
      workflowIdNotFound: 'Arbeidsflyt-ID-en ble ikke funnet i API-svaret',
    },
    inbox: {
      title: 'Innboks',
      newMessage: 'Ny melding',
      yourPendingActivities: 'Dine åpne oppgaver',
      noPendingActivities: 'Du har ingen åpne oppgaver.',
      yourMessages: 'Dine meldinger',
      noMessages: 'Du har ingen meldinger.',
      unreadMessage: 'Ulest melding',
      signBy: 'Signatur kreves før',
      uploadBy: 'Vennligst last opp innen',
      signatureRequired: 'Vi trenger din signatur',
      documentRequired: 'Vi trenger et dokument fra deg',
      completeForm: 'Vennligst fyll ut dette skjemaet',
      ongoingApplicationProcess: 'Pågående søknadsprosess',
      uploadActionDetailsTitle: 'Dokumentopplastning',
      uploadActionDetailsText: 'Vi trenger følgende dokument fra deg:',
      uploadActionDetailsNote:
        'På mobil kan du velge en fil eller ta et bilde. Kun PDF-dokumenter og bilder (.jpg, .jpeg, .png) er tillatt. Maksimal filstørrelse for opplasting er 10 MB.',
      uploadActionDetailsButtonLabel: 'Last opp dokumentet',
      backToInbox: 'Tilbake til innboksen',
      createNewMessage: 'Ny melding',
      send: 'Send',
      messageTopic: 'Velg et tema…',
      messageSubject: 'Skriv emne…',
      messageText: 'Skriv melding…',
      couldNotFoundContent: 'Vi kunne ikke finne innholdet til denne siden.',
      taskDoesNotExist:
        'Oppgaven eksisterer ikke eller er ikke lenger tilgjengelig for deg.',
      canSeeHistory: 'Du kan se meldingshistorikken for denne oppgaven',
      conversationLinkLabel: 'her',
      warningUnavailableTask:
        'Du kan ikke lenger delta i denne samtalen. For å kontakte oss, vennligst start en ny melding fra innboksen.',
      warningConversationStatus:
        'Meldingene har blitt arkivert og du kan ikke skrive nye meldinger lenger. For å kontakte oss, vennligst start en ny melding fra innboksen.',
    },
    portfolios: {
      title: 'Porteføljer',
      tabAllocationTitle: 'Allokering',
      tabHoldingsTitle: 'Beholdning',
      tabPerformanceTitle: 'Avkastning',
      tabTransactionsTitle: 'Transaksjoner',
      tabIndexTitle: 'Indeks',
      tableLinkLabel: 'Åpne',
      tableAllocationTitle: 'Allokering',
      tableHoldingsTitle: 'Allokering',
      tablePerformanceTitle: 'Avkastning',
      tablePerformanceTwoTitle: 'Avkastning (%)',
      tableTransactionsTitle: 'Transaksjoner',
      tableIndexTitle: 'Indeks',
      tableAllocationFooterRowTitle: 'Totalt',
      tableHoldingsFooterRowTitle: 'Totalt',
      tablePerformanceFooterRowTitle: 'Totalt',
      tablePerformanceTwoFooterRowTitle: 'Totalt',
      graphPerformanceDatepickerStartLabel: 'Start',
      graphPerformanceDatepickerEndLabel: 'Slutt',
      graphPerformanceValueAxisTitle: 'Avkastning (indeksert)',
      graphDatePickerInvalidStartDate: 'Ugyldig startdato',
      graphDatePickerInvalidEndDate: 'Ugyldig sluttdato',
      loadMore: 'Last inn mer',
      lastUpdatedTxt:
        'Sist oppdatert den {0:d} med siste tilgjengelige priser den {1:d}.',
      lackOfUpdatesTxt:
        'Manglende oppdatering skyldes transaksjoner i prosess.',
      disclaimerText: 'Vi tar forbehold om eventuelle feil i rapporten.',
      userWithoutPortfoliosText:
        'Vi kan ikke finne noen aktive porteføljer knyttet til profilen din for øyeblikket. Porteføljerapporter kan være midlertidig utilgjengelige mens data oppdateres. Vennligst prøv igjen senere eller kontakt oss for ytterligere hjelp.',
      accountWithoutPortfoliosText:
        'Vi kan ikke finne noen aktive porteføljer knyttet til denne enheten for øyeblikket. Porteføljerapporter kan være midlertidig utilgjengelige mens data oppdateres. Vennligst prøv igjen senere eller kontakt oss for ytterligere hjelp.',
      userPortfoliosError:
        'Denne porteføljen er midlertidig utilgjengelig. Vennligst prøv igjen senere.',
      holdingsError: 'Data kunne ikke lastes. Vennligst prøv igjen senere.',
      timeSeriesError:
        'Visualiseringen kunne ikke lastes inn. Vennligst prøv igjen senere.',
      transactionsError: 'Data kunne ikke lastes. Vennligst prøv igjen senere.',
      indicesError: 'Data kunne ikke lastes. Vennligst prøv igjen senere.',
      tableFilterLabels: {
        transactionDate: 'Filtrer på handelsår',
        expectedSettlementDate: 'Filtrer etter forventet oppgjørsår',
        type: 'Filtrer etter type',
      },
      allocationTableColumns: {
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Rapportnivå',
        sortOrder: 'Sorteringsrekkefølge',
        isIndex: 'Er indeks',
        currentHolding: 'Nåværende beholdning',
        contactGuid: 'Contact GUID',
        accountId: 'Kontonummer',
        portfolioId: 'Portefølje-ID',
        portfolioName: 'Porteføljenavn',
        title: 'Aktivaklasse',
        securitySymbol: 'Symbol',
        isin: 'ISIN',
        sfdr: 'SFDR',
        portfolioBaseTypeCode: 'Basekode',
        securityTypeCode: 'Instrumentkode',
        allocation: 'Fordeling',
        costBasis: 'Kostbasis',
        fifoCost: 'Kostkurs',
        indexWeight: 'Strategisk vekt',
        lowerLimit: 'Minimum vekt',
        upperLimit: 'Maksimum vekt',
        movement: 'Innskudd/uttak',
        quantity: 'Antall',
        unrealizedGainLoss: 'Urealisert gevinst/tap',
        itdAnnualizedTwr: 'TWR siden start',
        itdFromDate: 'Startdato',
        oneYearTwr: '1 års TWR',
        oneYearTotalGain: '1 års totalgevinst',
        oneYearFromDate: '1 års dato',
        threeYearTotalGain: '3 års totalgevinst',
        threeYearAnnualizedTwr: '3 års TWR',
        threeYearFromDate: '3 års dato',
        fiveYearAnnualizedTwr: '5 års TWR',
        fiveYearTotalGain: '5 års totalgevinst',
        fiveYearFromDate: '5 års dato',
        ytdTotalGain: 'Totalgevinst YTD',
        ytdTwr: 'TWR YTD',
        ytdFromDate: 'YTD-dato',
        mtdTotalGain: 'Avkastning denne måneden',
        mtdTwr: 'TWR MTD',
        mtdFromDate: 'MTD-dato',
        periodEndMarketValue: 'Markedsverdi',
        periodEndRelativeMarketValue: 'Relativ MKV',
        periodEndTotalGain: 'Totalgevinst',
        periodEndWeight: 'Porteføljevekt',
        periodEndDate: 'Sluttdato for periode',
        sinceInceptionTotalGain: 'Totalgevinst',
        sinceInceptionAnnualizedTwr: 'Årlig TWR',
        sinceInceptionDate: 'Startdato',
        thruDate: 'Gjennom datering',
        date1: 'Siste kursdato',
        date2: 'Investert dato',
        date3: 'Date 3',
        date4: 'Date 4',
        date5: 'Date 5',
        double1: 'Double 1',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'double 4',
        double5: 'Double 5',
        int1: 'Int 1',
        int2: 'Int 2',
        int3: 'Int 3',
        int4: 'Int 4',
        int5: 'Int 5',
        string1: 'Frihetsgrader',
        string2: 'String 2',
        string3: 'String 3',
        string4: 'String 4',
        string5: 'Dok.',
      },
      holdingsTableColumns: {
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Rapportnivå',
        sortOrder: 'Sorteringsrekkefølge',
        isIndex: 'Er indeks',
        currentHolding: 'Nåværende beholdning',
        contactGuid: 'Contact GUID',
        accountId: 'Kontonummer',
        portfolioId: 'Portefølje-ID',
        portfolioName: 'Porteføljenavn',
        title: 'Beholdning',
        securitySymbol: 'Symbol',
        isin: 'ISIN',
        sfdr: 'SFDR',
        portfolioBaseTypeCode: 'Basekode',
        securityTypeCode: 'Instrumentkode',
        allocation: 'Fordeling',
        costBasis: 'Kostbasis',
        fifoCost: 'Kostkurs',
        indexWeight: 'Strategisk vekt',
        lowerLimit: 'Nedre grense',
        upperLimit: 'Øvre grense',
        movement: 'Bevegelse',
        quantity: 'Antall',
        unrealizedGainLoss: 'Urealisert gevinst/tap',
        itdAnnualizedTwr: 'TWR siden start',
        itdFromDate: 'Startdato',
        oneYearTwr: '1 års TWR',
        oneYearTotalGain: '1 års totalgevinst',
        oneYearFromDate: '1 års dato',
        threeYearTotalGain: '3 års totalgevinst',
        threeYearAnnualizedTwr: '3 års TWR',
        threeYearFromDate: '3 års dato',
        fiveYearAnnualizedTwr: '5 års TWR',
        fiveYearTotalGain: '5 års totalgevinst',
        fiveYearFromDate: '5 års dato',
        ytdTotalGain: 'Totalgevinst YTD',
        ytdTwr: 'TWR YTD',
        ytdFromDate: 'YTD-dato',
        mtdTotalGain: 'Totalgevinst MTD',
        mtdTwr: 'TWR MTD',
        mtdFromDate: 'MTD-dato',
        periodEndMarketValue: 'Markedsverdi',
        periodEndRelativeMarketValue: 'Relativ MKV',
        periodEndTotalGain: 'Totalgevinst',
        periodEndWeight: 'Allokering',
        periodEndDate: 'Sluttdato for periode',
        sinceInceptionTotalGain: 'Totalgevinst',
        sinceInceptionAnnualizedTwr: 'Årlig TWR',
        sinceInceptionDate: 'Startdato',
        thruDate: 'Gjennom datering',
        date1: 'Siste kursdato',
        date2: 'Investert dato',
        date3: 'Date 3',
        date4: 'Date 4',
        date5: 'Date 5',
        double1: 'Markedskurs',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'double 4',
        double5: 'Double 5',
        int1: 'Int 1',
        int2: 'Int 2',
        int3: 'Int 3',
        int4: 'Int 4',
        int5: 'Int 5',
        string1: 'String 1',
        string2: 'String 2',
        string3: 'String 3',
        string4: 'String 4',
        string5: 'Dok.',
      },
      performanceTableColumns: {
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Rapportnivå',
        sortOrder: 'Sorteringsrekkefølge',
        isIndex: 'Er indeks',
        currentHolding: 'Nåværende beholdning',
        contactGuid: 'Contact GUID',
        accountId: 'Kontonummer',
        portfolioId: 'Portefølje-ID',
        portfolioName: 'Porteføljenavn',
        title: 'Avkastning (NOK)',
        securitySymbol: 'Symbol',
        isin: 'ISIN',
        sfdr: 'SFDR',
        portfolioBaseTypeCode: 'Basekode',
        securityTypeCode: 'Instrumentkode',
        allocation: 'Fordeling',
        costBasis: 'Kostbasis',
        fifoCost: 'FIFO-kostnad',
        indexWeight: 'Strategisk vekt',
        lowerLimit: 'Nedre grense',
        upperLimit: 'Øvre grense',
        movement: 'Bevegelse',
        quantity: 'Antall',
        unrealizedGainLoss: 'Urealisert gevinst/tap',
        itdAnnualizedTwr: 'TWR siden start',
        itdFromDate: 'Startdato',
        oneYearTwr: '1 års TWR',
        oneYearTotalGain: '1 års totalgevinst',
        oneYearFromDate: '1 års dato',
        threeYearTotalGain: 'Siste 3 år',
        threeYearAnnualizedTwr: '3 års TWR',
        threeYearFromDate: '3 års dato',
        fiveYearAnnualizedTwr: '5 års TWR',
        fiveYearTotalGain: 'Siste 5 år',
        fiveYearFromDate: '5 års dato',
        ytdTotalGain: 'Hittil i år',
        ytdTwr: 'TWR YTD',
        ytdFromDate: 'YTD-dato',
        mtdTotalGain: 'Denne måned',
        mtdTwr: 'TWR MTD',
        mtdFromDate: 'Denne måned',
        periodEndMarketValue: 'Markedsverdi ved periodens slutt',
        periodEndRelativeMarketValue: 'Relativ MKV ved periodens slutt',
        periodEndTotalGain: 'Totalgevinst ved periodens slutt',
        periodEndWeight: 'Porteføljevekt ved periodens slutt',
        periodEndDate: 'Sluttdato for periode',
        sinceInceptionTotalGain: 'Siden oppstart',
        sinceInceptionAnnualizedTwr: 'Årlig TWR siden oppstart',
        sinceInceptionDate: 'Startdato',
        thruDate: 'Gjennom datering',
        date1: 'Date 1',
        date2: 'Date 2',
        date3: 'Date 3',
        date4: 'Date 4',
        date5: 'Date 5',
        double1: 'Double 1',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'double 4',
        double5: 'Double 5',
        int1: 'Int 1',
        int2: 'Int 2',
        int3: 'Int 3',
        int4: 'Int 4',
        int5: 'Int 5',
        string1: 'String 1',
        string2: 'String 2',
        string3: 'String 3',
        string4: 'String 4',
        string5: 'Dok.',
      },
      performanceTableTwoColumns: {
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Rapportnivå',
        sortOrder: 'Sorteringsrekkefølge',
        isIndex: 'Er indeks',
        currentHolding: 'Nåværende beholdning',
        contactGuid: 'Contact GUID',
        accountId: 'Kontonummer',
        portfolioId: 'Portefølje-ID',
        portfolioName: 'Porteføljenavn',
        title: 'Avkastning (%)',
        securitySymbol: 'Symbol',
        isin: 'ISIN',
        sfdr: 'SFDR',
        portfolioBaseTypeCode: 'Basekode',
        securityTypeCode: 'Instrumentkode',
        allocation: 'Fordeling',
        costBasis: 'Kostbasis',
        fifoCost: 'FIFO-kostnad',
        indexWeight: 'Strategisk vekt',
        lowerLimit: 'Nedre grense',
        upperLimit: 'Øvre grense',
        movement: 'Bevegelse',
        quantity: 'Antall',
        unrealizedGainLoss: 'Urealisert gevinst/tap',
        itdAnnualizedTwr: 'TWR siden start',
        itdFromDate: 'Startdato',
        oneYearTwr: '1 års TWR',
        oneYearTotalGain: '1 års totalgevinst',
        oneYearFromDate: '1 års dato',
        threeYearTotalGain: '3 års totalgevinst',
        threeYearAnnualizedTwr: 'Siste 3 år',
        threeYearFromDate: '3 års dato',
        fiveYearAnnualizedTwr: 'Siste 5 år',
        fiveYearTotalGain: '5 års totalgevinst',
        fiveYearFromDate: '5 års dato',
        ytdTotalGain: 'Totalgevinst YTD',
        ytdTwr: 'Hittil i år',
        ytdFromDate: 'YTD-dato',
        mtdTotalGain: 'Totalgevinst MTD',
        mtdTwr: 'Denne måned',
        mtdFromDate: 'MTD-dato',
        periodEndMarketValue: 'Markedsverdi ved periodens slutt',
        periodEndRelativeMarketValue: 'Relativ MKV ved periodens slutt',
        periodEndTotalGain: 'Totalgevinst ved periodens slutt',
        periodEndWeight: 'Porteføljevekt ved periodens slutt',
        periodEndDate: 'Sluttdato for periode',
        sinceInceptionTotalGain: 'Totalgevinst siden oppstart',
        sinceInceptionAnnualizedTwr: 'Siden oppstart ann.',
        sinceInceptionDate: 'Startdato',
        thruDate: 'Gjennom datering',
        date1: 'Date 1',
        date2: 'Date 2',
        date3: 'Date 3',
        date4: 'Date 4',
        date5: 'Date 5',
        double1: 'Double 1',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'double 4',
        double5: 'Double 5',
        int1: 'Int 1',
        int2: 'Int 2',
        int3: 'Int 3',
        int4: 'Int 4',
        int5: 'Int 5',
        string1: 'String 1',
        string2: 'String 2',
        string3: 'String 3',
        string4: 'String 4',
        string5: 'Dok.',
      },
      transactionsTableColumns: {
        accountId: 'Kontonummer',
        portfolioId: 'Portefølje-ID',
        portfolioName: 'Porteføljenavn',
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Report Level',
        sortOrder: 'Sorteringsrekkefølge',
        isHighlighted: 'Is Highlighted',
        contactGuid: 'Contact Guid',
        title: 'Fond',
        status: ' ',
        type: 'Type',
        transactionDate: 'Handelsdato',
        expectedSettlementDate: 'Forventet oppgjørsdato',
        amount: 'Beløp',
        costPrice: 'Kostpris',
        quantity: 'Antall',
        isin: 'ISIN',
        securityTypeCode: 'Instrumentkode',
        date1: 'Date 1',
        date2: 'Date 2',
        date3: 'Date 3',
        date4: 'Date 4',
        date5: 'Date 5',
        double1: 'Double 1',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'Double 4',
        double5: 'Double 5',
        int1: 'Integer 1',
        int2: 'Integer 2',
        int3: 'Integer 3',
        int4: 'Integer 4',
        int5: 'Integer 5',
        string1: 'String 1',
        string2: 'String 2',
        string3: 'String 3',
        string4: 'String 4',
        string5: 'String 5',
      },
      transactionsTableData: {
        type: {
          1: 'Type 1',
          2: 'Type 2',
        },
        status: {
          1: 'Status 1',
          2: 'Status 2',
        },
      },
      indexTableColumns: {
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Rapportnivå',
        sortOrder: 'Sorteringsrekkefølge',
        indexGroup: 'Indeksgruppe',
        title: 'Indeksmonitor, avkastning i NOK',
        annualTwr: 'Hittil i år',
        oneYearTwr: 'Siste år',
        threeYearTwr: 'Siste 3 år',
        fiveYearTwr: 'Siste 5 år',
        yield: 'Yield',
        lastPrice: 'Kurs',
        lastPriceDate: 'Siste kursdata',
        date1: 'Date 1',
        date2: 'Date 2',
        double1: 'Double 1',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'Double 4',
        double5: 'Double 5',
        int1: 'Int 1',
        int2: 'Int 2',
        string1: 'String 1',
        string2: 'String 2',
      },
    },
    errors: {
      settingsError:
        'Det oppstod en feil. Gå tilbake til hjemmesiden og prøv igjen. ({0})',
      invalidStatus:
        'Det oppstod en feil. Gå tilbake til hjemmesiden og prøv igjen.',
      shouldNotRedirect:
        'Beklager, vi kunne ikke autentisere deg. Gå tilbake til hjemmesiden og prøv å logge inn igjen.',
      invalidAuth:
        'Beklager, vi kunne ikke autentisere deg. Gå tilbake til hjemmesiden og prøv å logge inn igjen.',
      invalidAction:
        'Beklager, denne handlingen er ikke tillatt. Gå tilbake til hjemmesiden og prøv igjen.',
      invalidReturnUrl:
        'Beklager, vi kunne ikke autentisere deg. Gå tilbake til hjemmesiden og prøv å logge inn igjen.',
      getAccessTokenError:
        'Beklager, vi kunne ikke autentisere deg. Gå tilbake til hjemmesiden og prøv å logge inn igjen.',
      silentAuthError:
        'Beklager, vi kunne ikke autentisere deg. Gå tilbake til hjemmesiden og prøv å logge inn igjen.',
      silentTokenError:
        'Vi beklager, noe gikk galt med innlasting av siden. Vennligst oppdater siden eller logg inn på nytt.',
    },
  },
};
