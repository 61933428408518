import React from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Skeleton } from '@progress/kendo-react-indicators';
import { useTranslations } from '../../../hooks/useTranslations';

const PortfoliosSkeleton = () => {
  const ns = 'construo.portfolios';
  const { t } = useTranslations();
  return (
    <>
      <h2>
        <Skeleton shape={'text'} style={{ maxWidth: 300, height: 60 }} />
      </h2>
      <h3 className='portfolio-value'>
        <Skeleton shape={'text'} style={{ maxWidth: 100, height: 40 }} />
      </h3>
      <TabStrip selected={0}>
        <TabStripTab title={t(`${ns}.tabAllocationTitle`)}>
          <p>
            <Skeleton shape={'text'} />
          </p>
          <Skeleton shape={'rectangle'} style={{ height: 300 }} />
        </TabStripTab>
        <TabStripTab title={t(`${ns}.tabHoldingsTitle`)}></TabStripTab>
        <TabStripTab title={t(`${ns}.tabPerformanceTitle`)}></TabStripTab>
        <TabStripTab title={t(`${ns}.tabTransactionsTitle`)}></TabStripTab>
        <TabStripTab title={t(`${ns}.tabIndexTitle`)}></TabStripTab>
      </TabStrip>
      <p>
        <Skeleton shape={'text'} />
      </p>
    </>
  );
};

export default PortfoliosSkeleton;
