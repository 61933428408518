import React from 'react';
import ActionRequest from '../common/ActionRequest';
import { useTranslations } from '../../hooks/useTranslations';

const SubmissionRequest = (props: any) => {
  const { submission } = props;

  const ns = 'construo.forms';
  const { t } = useTranslations();
  const submissionName = submission.Title;
  const heading = !!submissionName
    ? submissionName
    : t(`${ns}.noSubmissionName`);
  const formSubmissionGuid = submission.FormSubmissionGuid;

  return (
    <ActionRequest
      type='submission'
      href={`/user/submissions/${formSubmissionGuid}`}
      heading={heading}
    />
  );
};

export default SubmissionRequest;
