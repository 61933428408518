import React from 'react';
import HelmetTitle from '../common/HelmetTitle';
import { Login } from './Login';
import { useTranslations } from '../../hooks/useTranslations';

interface LoginActionProps {
  name: string;
}

const LoginAction = ({ name }: LoginActionProps) => {
  const ns = 'construo.global';
  const { t } = useTranslations();
  return (
    <>
      <HelmetTitle title={t(`${ns}.loginTitle`)} />
      <Login action={name}></Login>
    </>
  );
};

export default LoginAction;
