import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useTranslations } from '../../../hooks/useTranslations';

export interface TableLoadMoreBtnProps {
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}

const TableLoadMoreBtn = ({
  pageNumber,
  setPageNumber,
}: TableLoadMoreBtnProps) => {
  const { t } = useTranslations();

  const handleLoadMoreClick = () => {
    setPageNumber(pageNumber + 1);
  };

  return (
    <>
      <div className='portfolio-load-more'>
        <Button themeColor='primary' onClick={() => handleLoadMoreClick()}>
          {t(`construo.portfolios.loadMore`)}
        </Button>
      </div>
    </>
  );
};

export default TableLoadMoreBtn;
