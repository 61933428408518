import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { useParams } from 'react-router-dom';
import envVars from '../../../resources/envVars';
import { resetAlerts, setAlerts } from '../../../app/slices/alertSlice';
import TaskDetails, { TaskDetailsProps } from './TaskDetails';
import {
  fetchWorkflows,
  selectWorkflows,
  selectWorkflowsFetched,
} from '../workflowSlice';
import {
  fetchTask,
  resetTaskDetailsData,
  selectTaskDetails,
  selectTaskDetailsFetched,
} from './taskDetailsSlice';
import {
  fetchMessages,
  selectMessages,
  selectMessagesFetched,
} from './messagesSlice';
import { useTranslations } from '../../../hooks/useTranslations';

export interface TaskRouteParams {
  id: string;
}

const TaskDetailsProvider = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const conversationGuid = urlParams.get('conversationGuid');

  const isScrollIntoView = !!conversationGuid ? true : false;

  const dispatch = useAppDispatch();

  const { taskId } = useParams();

  const { t } = useTranslations();

  const [workflowId, setWorkflowId] = useState<string | null>(null);

  const API_BASE_URI = envVars.API_BASE_URI || '';

  const taskDetails: TaskDetailsProps = useAppSelector(selectTaskDetails);
  const taskDetailsFetched = useAppSelector(selectTaskDetailsFetched);

  const taskStatus = taskDetails?.status;
  const taskProgress = taskDetails?.progress;
  const taskTitle = taskDetails?.displayName;
  const taskDescription = taskDetails?.description;
  const taskContacts = taskDetails?.accessRightsContacts;
  const taskActions = taskDetails?.actions;

  const workflows: workflowsType = useAppSelector(selectWorkflows);
  const workflowsFetched = useAppSelector(selectWorkflowsFetched);

  useEffect(() => {
    if (workflowsFetched === null) {
      const dataObject = {
        baseUrl: API_BASE_URI,
      };
      dispatch(fetchWorkflows(dataObject));
    }
  }, [dispatch, API_BASE_URI, workflowsFetched]);

  const findWorkflowNameByWorkflowId = (id: string | null) => {
    const workflow: {
      id: string;
      displayName: string;
      description: string;
      tasks: object[];
    } = workflows.filter(workflow => workflow.id === id)[0];
    const workflowName = workflow.displayName;
    return workflowName;
  };

  const workflowName =
    workflows.length > 0 &&
    !!workflowId &&
    findWorkflowNameByWorkflowId(workflowId);

  const findWorkflowIdByTaskId = useCallback(
    (taskId: any) => {
      let _wfId: string | null = null;
      const isTaskIncludedInWorkflow = (wf: any) => {
        const isExists =
          wf.tasks.filter((task: any) => task.id === taskId).length > 0;

        return isExists;
      };
      workflows.forEach((wf: any) => {
        if (isTaskIncludedInWorkflow(wf)) {
          _wfId = wf.id;
        } else {
          return false;
        }
      });
      return _wfId;
    },
    [workflows],
  );

  const [workflowIdAttempt, setWorkflowIdAttempt] = useState(false);

  useEffect(() => {
    if (workflows.length > 0 && workflowId === null && !workflowIdAttempt) {
      // Prevent to many renders in case conversation could not be found in workflows and tasks
      setWorkflowIdAttempt(true);
      const workflowId = findWorkflowIdByTaskId(taskId);
      setWorkflowId(workflowId);
    }
  }, [
    findWorkflowIdByTaskId,
    taskId,
    workflowId,
    workflowIdAttempt,
    workflows.length,
  ]);

  useEffect(() => {
    const dataObject = {
      baseUrl: API_BASE_URI,
      workflowId,
      taskId,
    };
    if (taskDetailsFetched === null && !!workflowId) {
      dispatch(fetchTask(dataObject));
    }
  }, [dispatch, API_BASE_URI, taskDetailsFetched, workflowId, taskId]);

  // Messages
  const messages = useAppSelector(selectMessages);
  const messagesFetched = useAppSelector(selectMessagesFetched);

  useEffect(() => {
    if (messagesFetched === null && !!workflowId) {
      const conversationsUrl: string =
        `${envVars?.API_BASE_URI}/user/conversations?resourceId=${taskId}&conversationType=1&resourceType=1&parentId=${workflowId}` ||
        '';
      const dataObject = {
        url: conversationsUrl,
      };
      dispatch(fetchMessages(dataObject));
    } else {
      if (workflowIdAttempt && !workflowId) {
        dispatch(resetAlerts());
        dispatch(
          setAlerts({
            message: t('construo.inbox.couldNotFoundContent'),
            type: 'warning',
          }),
        );
      }
    }
  }, [
    dispatch,
    messagesFetched,
    taskId,
    workflowId,
    conversationGuid,
    workflowIdAttempt,
    t,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetTaskDetailsData());
      dispatch(resetAlerts());
    };
  }, [dispatch]);

  const cantShowTaskDetails = workflowIdAttempt && !workflowId;

  return (
    <TaskDetails
      taskDetailsFetched={taskDetailsFetched}
      messagesFetched={messagesFetched}
      workflowId={workflowId}
      workflowName={workflowName}
      id={taskId!}
      displayName={taskTitle}
      description={taskDescription}
      status={taskStatus}
      accessRightsContacts={taskContacts}
      actions={taskActions}
      progress={taskProgress}
      isScrollIntoView={isScrollIntoView}
      messages={messages}
      conversationGuid={conversationGuid}
      cantShowTaskDetails={cantShowTaskDetails}
    />
  );
};

type workflowsType = {
  id: string;
  displayName: string;
  description: string;
  tasks: object[];
}[];

export default TaskDetailsProvider;
