import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useTranslations } from '../../hooks/useTranslations';

type TempResponseProps = {
  chooseTemp: () => void;
  chooseLatest: () => void;
};

const TempResponse = ({ chooseTemp, chooseLatest }: TempResponseProps) => {
  const ns = 'construo.forms';
  const { t } = useTranslations();

  return (
    <div className='temp-response'>
      <h1>{t(`${ns}.chooseFormSubmissionTitle`)}</h1>
      <p>{t(`${ns}.chooseFormSubmissionP1Text`)}</p>
      <p>{t(`${ns}.chooseFormSubmissionP2Text`)}</p>
      <div className='action-btns'>
        <Button themeColor='secondary' onClick={chooseTemp}>
          {t(`${ns}.tempButtonLabel`)}
        </Button>
        <Button themeColor='primary' onClick={chooseLatest}>
          {t(`${ns}.savedButtonLabel`)}
        </Button>
      </div>
    </div>
  );
};

export default TempResponse;
