import fiKendoMessages from './kendo/fi.json';

export const fiProductMessages = {
  ...fiKendoMessages,
  construo: {
    global: {
      loginTitle: 'Kirjaudu sisään',
      logoutTitle: 'Kirjaudu ulos',
      loading: 'Ladataan...',
      successfullyLoggedOut: 'Olet kirjautunut ulos',
      loggedOutHeading: 'Kiitos vierailustasi',
      loggedOutSubHeading:
        'Olet onnistuneesti kirjautunut ulos. Jos käytät julkista tietokonetta, suosittelemme, että suljet selaimen.',
      logBackIn: 'Kirjaudu takaisin sisään',
      download: 'Lataa',
      previewNotSupported:
        'Esikatselua ei ole saatavilla tällä laitteella. Lataa tiedosto nähdäksesi sen.',
      previewNotAvailable:
        'Tätä tiedostoa ei voi esikatsella. Lataa tiedosto nähdäksesi sen.',
      processingLogin:
        'Tarkistamme selaimesi voidaksesi kirjautua sisään turvallisesti. Tämä vie hetken.',
      processingLoginCallback:
        'Kirjaudutaan sisään turvallisesti. Odota hetki, ole hyvä.',
      processingLogout:
        'Kirjaudutaan ulos turvallisesti. Ole hyvä ja älä sulje selainikkunaa.',
      processingLogoutCallback: 'Kirjaudutaan ulos.',
      gotoLoginPage: 'Napsauta tästä kirjautuaksesi sisään.',
      page404Title: '404 Error - Sivua ei löydy',
      page404Text: 'Sivua URL {0} ei löytynyt. Ole hyvä ja palaa etusivulle.',
      resource404Title: '{0} ei löydetty',
      resource404Text:
        '{0}, jota yrität käyttää, ei löydy. Sitä ei ehkä ole olemassa tai sinulla ei ehkä enää ole pääsyä siihen. Jos päädyit tälle sivulle syöttämällä URL-osoite selaimeesi, varmista, että olet kirjoittanut sen oikein.',
      resourceType: 'Sivu',
      submissionType: 'Lomake',
      workflowType: 'Työnkulku',
      taskType: 'Tehtävä',
      conversationType: 'Viesti',
      documentType: 'Asiakirja',
      id: 'ID',
      resource: 'Resurssi',
      type: 'Tyyppi',
      invalid: 'Virheellinen ID-muoto',
    },
    header: {
      logoAlt: '{0} logo',
      menuBtnLabel: 'Valikko',
    },
    desktopNavigation: {
      link1Label: '',
      link1URL: '',
      link2Label: '',
      link2URL: '',
      link3Label: '',
      link3URL: '',
      link4Label: '',
      link4URL: '',
      link5Label: '',
      link5URL: '',
    },
    footer: {
      text: '© {0} {1}',
      link1Label: 'Linkki 1',
      link1URL: 'https://google.com/',
      link2Label: 'Linkki 2',
      link2URL: 'https://google.com/',
      link3Label: '',
      link3URL: '',
    },
    menu: {
      close: 'Sulje',
      home: 'Etusivu',
      portfolios: 'Salkut',
      documents: 'Dokumentit',
      publications: 'Julkaisut',
      messages: 'Viestit',
      inbox: 'Inbox',
      login: 'Kirjaudu sisään',
      logout: 'Kirjaudu ulos',
      languageSwitcherLabel: 'Kieli',
      english: 'English',
      norwegian: 'Norsk',
      finnish: 'Suomi',
      swedish: 'Svenska',
      accountNameTagline: '{0} puolesta',
      switchAccount: 'Vaihda entiteettiä',
    },
    selectAccount: {
      title: 'Valitse entiteetti',
      heading: 'Tervetuloa, {0}',
      subheading: 'Valitse yritys / asiakas, jonka tietoja haluat tarkastella',
    },
    homepage: {
      publicHeading: 'Tervetuloa portaalimme',
      publicSubHeading: 'Ole hyvä ja kirjaudu sisään jatkaaksesi',
      login: 'Kirjaudu sisään',
      actionRequired: 'Toimenpiteitä vaaditaan:',
      latestDocuments: 'Viimeisimmät dokumenttia',
      goToDocuments: 'Siirry kaikkiin dokumentteihin',
      signingCompleted: 'Allekirjoitus suoritettu',
      signingCompletedText: 'Kiitos! Allekirjoitus on tehty onnistuneesti.',
      signingUncompleted: 'Allekirjoitus epäonnistui',
      signingUncompletedText:
        'Dokumentin allekirjoitus epäonnistui. Ole hyvä ja yritä uudelleen myöhemmin.',
      portfoliosSection: {
        title: 'Salkut',
        totalMarketValue: 'Markkina-arvo',
        periodEndMarketValue: 'Markkina-arvo kauden lopussa',
        mtdTotalGain: 'Tuotto tähän mennessä kuukaudessa',
        ytdTotalGain: 'Tuotto tähän mennessä vuodessa',
        sinceInceptionTotalGain: 'Tuotto alusta alkaen {0}',
        seePortfolio: 'Näytä koko salkku',
      },
      publicationsSection: {
        title: 'Julkaisut',
        goToPublications: 'Näytä kaikki julkaisut',
        loadingPublicationPreview: 'Ladataan julkaisun esikatselua...',
        noPublicationsMessage: 'Tällä hetkellä ei ole näytettäviä julkaisuja.',
        categoryName_1: 'Kategoria 1',
        categoryName_2: 'Kategoria 2',
        categoryName_3: 'Kategoria 3',
        categoryName_4: 'Kategoria 4',
      },
    },
    signatureRequests: {
      noSignatureName: 'Allekirjoituspyyntösi',
      signBefore: 'Vaatii allekirjoituksen',
      redirectToESignLoadingText: 'Uudelleenohjaamme sinut dokumenttiin',
      userMissingNinAndBankId:
        'Emme valitettavasti kyenneet hakemaan tietojasi BankID:n kautta. Ole hyvä ja yritä myöhemmin uudelleen. Jos ongelma toistuu, ole hyvä ja ota yhteyttä meihin saadaksesi apua.',
      userMissingNinAndLocal:
        'eSignature pyynnöt ovat käytettävissä ainoastaan sisäänkirjautumisen jälkeen. Ole hyvä ja kirjaudu sisään BankId:n kanssa nähdäksesi allekirjoituspyyntösi. Jos olet saanut meiltä sähköpostilla ilmoituksen avoimesta eSignature pyynnöstä mutta sinulla ei ole BankID:tä, ole hyvä ja ota meihin yhteyttä saadaksesi apua.',
    },
    uploadRequests: {
      uploadBefore: 'Siirrä palvelimelle ennen',
      upload: 'Siirrä palvelimelle',
      docUpload: 'Dokumentin siirto palvelimelle',
      uploadingDoc: 'Dokumenttiasi siirretään palvelimelle',
      requireDoc: 'Seuraavat dokumentit ovat pakollisia:',
      requireAttachments:
        'Ole hyvä ja lähetä pyydetyt dokumentit tässä. Voit lähettää 15 dokumenttia samanaikaisesti.',
      fileRestriction:
        'Käyttäessäsi mobiililaitetta, napauta valitaksesi tiedoston tai ottaaksesi kuvan. Ainoastaan PDF- ja kuvatiedostot (.jpg, .jpeg, .png) ovat sallittuja. Suurin sallittu tiedostokoko on 10MB.',
      noDocumentName: 'Dokumenttianomuksesi',
      noCommentToClient: ' ',
      uploadSuccessful:
        'Kiitos, dokumentti on siirretty palvelimelle onnistuneesti',
      uploadFailed: 'Jokin meni pieleen ja dokumenttia ei kyetty lähettämään:',
    },
    documents: {
      title: 'Dokumentit',
      publications: 'Julkaisut',
      documentName: 'Dokumentin nimi',
      contacts: 'Kontaktit',
      category: 'Kategoria',
      documentType: 'Dokumenttityyppi',
      documentDate: 'Päiväys',
      nameAsc: 'Dokumentin nimi (A-Z)',
      nameDesc: 'Dokumentin nimi (Z-A)',
      dateNewestFirst: 'Päiväys (uusin ensin)',
      dateOldestFirst: 'Päiväys (vanhin ensin)',
      noDocumentsMessage:
        'Tällä hetkellä yhtään dokumenttia ei ole näytettävissä.',
      noResultsMessage: 'Hakuehdoillasi ei löytynyt tuloksia.',
      selectedToDownload: 'valittu ladattavaksi',
      bulkDownloadZipFileName: 'Dokumentit',
      preparingBulkDownload: 'Valmistelemmme dokumentteja ladattavaksi',
      filters: 'Rajaukset',
      filterByContacts: 'Rajaa kontaktin mukaan',
      filterByCategory: 'Rajaa kategorian mukaan',
      filterByType: 'Rajaa tyypin mukaan',
      filterByYear: 'Rajaa vuoden mukaan',
      filterByDocumentName: 'Rajaa dokumentin nimen mukaan',
      closeFilters: 'Sulje rajaukset',
      clearFilters: 'Tyhjää rajaukset',
      applyFilters: 'Lisää rajaukset',
      sortBy: 'Lajittele {0} mukaan',
      loadMore: 'Lataa lisää',
      loadingDocumentPreview: 'Ladataan dokumenttia...',
    },
    idleTimer: {
      modalTitle: 'Oletko vielä siellä?',
      modalMessage:
        'Et ole ollut aktiivinen vähään aikaan, joten kirjaudutaan ulos hetken kuluttua.',
      logMeOut: 'Kirjaa minut ulos',
      keepMeSignedIn: 'Pidä minut sisäänkirjautuneena',
    },
    forms: {
      alertTest: 'Tämä on testilomake jota ei voi lähettää.',
      alertErrorCreatingSigningRequest:
        'Pahoittelemme, tapahtui virhe käsittelyn aikana. Olemme yhteydessä lisätietojen kanssa.',
      loaderCreatingSigningRequest: 'Sinut ohjataan hetken kuluttua uudelleen.',
      loaderSubmittingForm: 'Vastauksesi lähetetään.',
      loaderGeneratingPdf: 'Odota hetki, käsittelemme tietojasi.',
      processingFormAuthentication: 'Odota hetki, sivu latautuu.',
      processingFormAuthenticationCallback:
        'Tunnistamme sinut, kiitos odotuksestasi.',
      formSubmittedHeading: 'Lomake on lähetetty',
      formSubmittedText: 'Kiitos, vastauksesi on lähetetty onnistuneesti.',
      formInaccessibleHeading: 'Lomake ei ole saatavilla',
      formInaccessibleText:
        'Pahoittelut, tämä lomake ei ole enää saatavissa. Ole hyvä ja ota yhteyttä meihin apua saadaksesi.',
      reload: 'Lataa uudelleen',
      smsSecurityCode: 'Vahvistuskoodi tekstiviestitse',
      inOrderToAccess:
        'Päästäksesi käsiksi tähän lomakkeeseen tarvitset vahvistuskoodin joka lähetetään tekstiviestitse puhelimeesi.',
      sendCode: 'Lähetä vahvistuskoodi',
      sentSixDigitCode:
        'Olemme lähettäneet sinulle kuusinumeroisen vahvistuskoodin tekstiviestitse.',
      enterBelow: 'Ole hyvä ja kirjoita se tähän:',
      accessBtnLabel: 'Yhteys',
      didNotReceiveCode: 'Etkö saanut vahvistuskoodia?',
      requestCode: 'Pyydä uusi vahvistuskoodi',
      waitToRequestCode: 'Odota 60 sekuntia pyytääksesi uutta vahvistuskoodia.',
      inputValidatorText: 'Ole hyvä ja kirjoita vahvistuskoodi tähän',
      fieldHint: 'Kirjoita vahvistuskoodi tähän',
      bankIdLoginWarningHeading: 'Kirjaudu sisään jatkaaksesi',
      bankIdLoginWarningText:
        'Päästäksesi käsiksi tähän sisältöön, ole hyvä ja kirjaudu sisään sähköisen tunnisteen kuten BankID:n kanssa.',
      tokenExpiredHeading: 'Linkki ei enää toimi',
      tokenExpiredText:
        'Linkkisi tähän lomakkeeseen on mennyt umpeen {0}. Ole hyvä ja ota meihin yhteyttä saadaksesi uuden linkin.',
      formSealedHeading: 'Lukittu',
      formSealedText: 'Lomake on jo lähetetty ja sitä ei voi enää muokata.',
      pleaseCompleteForm: 'Ole hyvä ja täytä lomake',
      chooseFormSubmissionTitle: 'Kuinka haluat jatkaa?',
      chooseFormSubmissionP1Text:
        'Versio tästä lomakkeesta on tallennettu automaattisesti.',
      chooseFormSubmissionP2Text:
        'Haluatko katsella automaattisesti tallennettua vai aiemmin tallennettua versiota?',
      tempButtonLabel: 'Tallennettu automaattisesti',
      savedButtonLabel: 'Edellinen versio',
      noSubmissionName: 'Sinun lomakepyyntösi',
      submissionCouldNotBeLoaded:
        'Jokin meni pieleen. Lomaketta ei voitu ladata.',
      otherUserWorkingOnSubmission:
        'Lomake ei ole saatavilla, koska se on jonkun toisen käytössä.',
      submissionSavedTitle: 'Lomake tallennettu',
      submissionSavedText:
        'Lomake on tallennettu. Voit turvallisesti poistua sivulta ja palata takaisin kun haluat jatkaa.',
      saveForLater: 'Tallenna',
      saveForLaterNote: 'Jos haluat jatkaa myöhemmin, voit painaa',
      returnHome: 'Palaa etusivulle',
      formLockedByUser:
        'Et voi täyttää lomaketta tällä hetkellä koska se on jonkun toisen käytössä. Ole hyvä ja kokeile uudelleen muutaman minuutin kuluttua.',
      formSaved:
        'Muutoksesi on tallennettu onnistuneesti. Voit sulkea lomakkeen ja jatkaa muokkausta myöhemmin.',
      formNotSaved:
        'Pahoittelut, jokin meni pieleen muutoksia tallennettaessa. Ole hyvä ja yritä uudelleen.',
      formSubmitted: 'Kiitos, lomakkeesi on vastaanotettu',
      formSubmissionNot:
        'Pahoittelut, jokin meni pieleen ja lomaketta ei voitu',
      locked: 'Lukittu',
      unlocked: 'Lukitsematon',
      submitError: 'Jokin meni pieleen ja lomaketta ei voitu lähettää:',
      autoSaveError:
        'Jokin meni pieleen ja lomaketta ei voitu tallentaa automaattisesti:',
      noFormData:
        'Puuttuvien tietojen vuoksi lomaketta ei voida näyttää. Ota meihin yhteyttä saadaksesi tukea.',
      showSubmittedData: 'Näytä lähetetyt tiedot',
      hideSubmittedData: 'Piilota lähetetyt tiedot',
      requiredAuthenticationInfoPageHeader: 'Palaamme sinulle',
      requiredAuthenticationInfoPageText:
        'Palaamme sinulle seuraavien päivien aikana.',
    },
    workflows: {
      noWorkflowName: 'Hakemuksesi käsittely',
      workflowDescription:
        'Voidaksemme viimeistellä käsittelyn tarvitsemme hieman lisää tietoja sinulta. Ole hyvä ja täydennä seuraavat tehtävät.',
      accessedBy: 'Tähän tehtävään on pääsy:',
      noAttachmentsAdded: 'Yhtään liitetiedostoa ei ole vielä lisätty.',
      addFirstDocument: 'Lisää ensimmäinen dokumentti',
      addDocuments: 'Lisää dokumentteja',
      reviewingSubmission:
        'Käymme läpi asiakirjasi ja otamme sinuun yhteyttä jos lisätietoja tarvitaan.',
      completedSubmission: 'Suoritettu',
      completeBtnLabel: 'Suorita tämä vaihe',
      noActionAdded: 'Ei muita vaadittavia toimenpiteitä.',
      completeActionSuccess: 'Kiitos, tämä vaihe suoritettu.',
      completeActionError: 'Vaihetta ei kyetty suorittamaan:',
      unreadMessages: 'Lukematon viesti',
      readReceiptsError: 'Viestiä ei kyetty merkitsemään luetuksi:',
      messagesTitle: 'Viestit',
      initializationText: 'Kirjoita viesti…',
      placeholderText: 'Kirjoita viesti…',
      anonymousAuthorName: 'Asiakaspalvelu',
      workflowIdNotFound: 'Työnkulun ID ei löytynyt API-vastauksesta',
    },
    inbox: {
      title: 'Inbox',
      newMessage: 'Uusi viesti',
      yourPendingActivities: 'Odottavat toiminnot',
      noPendingActivities: 'Sinulla ei ole vireillä olevia toimintoja.',
      yourMessages: 'Sinun viestisi',
      noMessages: 'Sinulla ei ole viestejä.',
      unreadMessage: 'Lukematon viesti',
      signBy: 'Vaatii allekirjoituksen',
      uploadBy: 'Lataa viimeistään',
      signatureRequired: 'Allekirjoitus vaaditaan',
      documentRequired: 'Tarvitsemme asiakirjan sinulta',
      completeForm: 'Täytä tämä lomake',
      ongoingApplicationProcess: 'Hakuprosessi käynnissä',
      uploadActionDetailsTitle: 'Asiakirjan lataus',
      uploadActionDetailsText: 'Vaadimme sinulta seuraavan asiakirjan:',
      uploadActionDetailsNote:
        'Käyttäessäsi mobiililaitetta, napauta valitaksesi tiedoston tai ottaaksesi kuvan. Ainoastaan PDF- ja kuvatiedostot (.jpg, .jpeg, .png) ovat sallittuja. Suurin sallittu tiedostokoko on 10MB.',
      uploadActionDetailsButtonLabel: 'Lataa asiakirja',
      backToInbox: 'Takaisin postilaatikkoon',
      createNewMessage: 'Uusi viesti',
      send: 'Lähetä',
      messageTopic: 'Valitse aihe…',
      messageSubject: 'Kirjoita viestisi aihe…',
      messageText: 'Kirjoita viesti…',
      couldNotFoundContent: 'Tämän sivun sisältöä ei löytynyt.',
      taskDoesNotExist: 'Tätä tehtävää ei löydy tai se ei ole enää saatavilla.',
      canSeeHistory: 'Voit nähdä tämän tehtävän viestihistorian',
      conversationLinkLabel: 'tähän',
      warningUnavailableTask:
        'Et voi enää osallistua tähän keskusteluun. Ottaaksesi meihin yhteyttä, ole hyvä ja lähetä meille uusi viesti.',
      warningConversationStatus:
        'Tämä keskustelu on arkistoitu ja et voi enää kirjoittaa uusia viestejä. Ottaaksesi meihin yhteyttä, ole hyvä ja lähetä meille uusi viesti.',
    },
    portfolios: {
      title: 'Salkut',
      tabAllocationTitle: 'Allokointi',
      tabHoldingsTitle: 'Omistus',
      tabPerformanceTitle: 'Tuotto',
      tabTransactionsTitle: 'Portfoliotapahtumat',
      tabIndexTitle: 'Indeksi',
      tableLinkLabel: 'Avaa',
      tableAllocationTitle: 'Allokointi',
      tableHoldingsTitle: 'Omistus',
      tablePerformanceTitle: 'Tuotto',
      tablePerformanceTwoTitle: 'Tuotto (%)',
      tableTransactionsTitle: 'Portfoliotapahtumat',
      tableIndexTitle: 'Indeksi',
      tableAllocationFooterRowTitle: 'Yhteensä',
      tableHoldingsFooterRowTitle: 'Yhteensä',
      tablePerformanceFooterRowTitle: 'Yhteensä',
      tablePerformanceTwoFooterRowTitle: 'Yhteensä',
      graphPerformanceDatepickerStartLabel: 'Alku',
      graphPerformanceDatepickerEndLabel: 'Loppu',
      graphPerformanceValueAxisTitle: 'Tuotto (Indeksoitu)',
      graphDatePickerInvalidStartDate: 'Virheellinen aloituspäivä',
      graphDatePickerInvalidEndDate: 'Virheellinen lopetuspäivä',
      loadMore: 'Lataa lisää',
      lastUpdatedTxt:
        'Viimeksi päivitetty {0:d} viimeisimmillä saatavilla olevilla hinnoilla {1:d}.',
      lackOfUpdatesTxt:
        'Päivitysten puute johtuu meneillään olevista tapahtumista.',
      disclaimerText: 'Pidätämme oikeuden raportissa oleviin virheisiin.',
      userWithoutPortfoliosText:
        'Emme löydä aktiivisia salkkuja, jotka liittyvät profiiliisi tällä hetkellä. Salkkuraportit saattavat olla väliaikaisesti saatavilla päivitysten aikana. Yritä myöhemmin uudelleen tai ota meihin yhteyttä lisäavun saamiseksi.',
      accountWithoutPortfoliosText:
        'Emme löydä aktiivisia salkkuja, jotka liittyvät tähän laitteeseen tällä hetkellä. Salkkuraportit saattavat olla väliaikaisesti saatavilla päivitysten aikana. Yritä myöhemmin uudelleen tai ota meihin yhteyttä lisäavun saamiseksi.',
      userPortfoliosError:
        'Tämä salkku ei ole tällä hetkellä käytettävissä. Yritä myöhemmin uudelleen.',
      holdingsError: 'Tietoja ei voitu ladata. Yritä myöhemmin uudelleen.',
      transactionsError:
        'Visualisointia ei voitu ladata. Yritä myöhemmin uudelleen.',
      timeSeriesError:
        'Visualisointia ei voitu ladata. Yritä myöhemmin uudelleen.',
      indicesError: 'Tietoja ei voitu ladata. Yritä myöhemmin uudelleen.',
      tableFilterLabels: {
        transactionDate: 'Suodata vuoden mukaan',
        expectedSettlementDate: 'Suodata vuoden mukaan',
        type: 'Suodata tyypin mukaan',
      },
      allocationTableColumns: {
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Rapportnivå',
        sortOrder: 'Lajittelujärjestys',
        isIndex: 'Onko indeksi',
        currentHolding: 'Nykyinen omistus',
        contactGuid: 'Yhteystiedon GUID',
        accountId: 'Tilinumero',
        portfolioId: 'Salkun tunniste',
        portfolioName: 'Salkun nimi',
        title: 'Omaisuusluokka',
        securitySymbol: 'Symboli',
        isin: 'ISIN',
        sfdr: 'SFDR',
        portfolioBaseTypeCode: 'Peruskoodi',
        securityTypeCode: 'Arvopaperikoodi',
        allocation: 'Allokointi',
        costBasis: 'Hankintameno',
        fifoCost: 'FIFO-kustannus',
        indexWeight: 'Strateginen paino',
        lowerLimit: 'Alaraja',
        upperLimit: 'Yläraja',
        movement: 'Liike',
        quantity: 'Määrä',
        unrealizedGainLoss: 'Realisoimaton voitto/tappio',
        itdAnnualizedTwr: 'TWR alusta asti',
        itdFromDate: 'Aloituspäivämäärä',
        oneYearTwr: '1 vuoden TWR',
        oneYearTotalGain: '1 vuoden kokonaisvoitto',
        oneYearFromDate: '1 vuoden päivämäärä',
        threeYearTotalGain: '3 vuoden kokonaisvoitto',
        threeYearAnnualizedTwr: '3 vuoden TWR',
        threeYearFromDate: '3 vuoden päivämäärä',
        fiveYearAnnualizedTwr: '5 vuoden TWR',
        fiveYearTotalGain: '5 vuoden kokonaisvoitto',
        fiveYearFromDate: '5 vuoden päivämäärä',
        ytdTotalGain: 'Kokonaisvoitto YTD',
        ytdTwr: 'TWR YTD',
        ytdFromDate: 'YTD-päivämäärä',
        mtdTotalGain: 'Kuukauden kokonaisvoitto',
        mtdTwr: 'TWR MTD',
        mtdFromDate: 'MTD-päivämäärä',
        periodEndMarketValue: 'Markkina-arvo kauden lopussa',
        periodEndRelativeMarketValue:
          'Suhteellinen markkina-arvo kauden lopussa',
        periodEndTotalGain: 'Kokonaisvoitto kauden lopussa',
        periodEndWeight: 'Salkun paino kauden lopussa',
        periodEndDate: 'Kauden päätöspäivämäärä',
        sinceInceptionTotalGain: 'Kokonaisvoitto alusta asti',
        sinceInceptionAnnualizedTwr: 'Vuotuinen TWR alusta asti',
        sinceInceptionDate: 'Aloituspäivämäärä',
        thruDate: 'Asti päivämäärä',
        date1: 'Date 1',
        date2: 'Date 2',
        date3: 'Date 3',
        date4: 'Date 4',
        date5: 'Date 5',
        double1: 'Double 1',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'double 4',
        double5: 'Double 5',
        int1: 'Int 1',
        int2: 'Int 2',
        int3: 'Int 3',
        int4: 'Int 4',
        int5: 'Int 5',
        string1: 'Frihetsgrader',
        string2: 'String 2',
        string3: 'String 3',
        string4: 'String 4',
        string5: 'Dok.',
      },
      holdingsTableColumns: {
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Raporttitaso',
        sortOrder: 'Lajittelujärjestys',
        isIndex: 'Onko indeksi',
        currentHolding: 'Nykyinen omistus',
        contactGuid: 'Yhteystiedon GUID',
        accountId: 'Tilinumero',
        portfolioId: 'Salkun tunniste',
        portfolioName: 'Salkun nimi',
        title: 'Omaisuusluokka',
        securitySymbol: 'Symboli',
        isin: 'ISIN',
        sfdr: 'SFDR',
        portfolioBaseTypeCode: 'Peruskoodi',
        securityTypeCode: 'Arvopaperikoodi',
        allocation: 'Allokointi',
        costBasis: 'Hankintameno',
        fifoCost: 'FIFO-kustannus',
        indexWeight: 'Strateginen paino',
        lowerLimit: 'Alaraja',
        upperLimit: 'Yläraja',
        movement: 'Liike',
        quantity: 'Määrä',
        unrealizedGainLoss: 'Realisoimaton voitto/tappio',
        itdAnnualizedTwr: 'TWR alusta asti',
        itdFromDate: 'Aloituspäivämäärä',
        oneYearTwr: '1 vuoden TWR',
        oneYearTotalGain: '1 vuoden kokonaisvoitto',
        oneYearFromDate: '1 vuoden päivämäärä',
        threeYearTotalGain: '3 vuoden kokonaisvoitto',
        threeYearAnnualizedTwr: '3 vuoden TWR',
        threeYearFromDate: '3 vuoden päivämäärä',
        fiveYearAnnualizedTwr: '5 vuoden TWR',
        fiveYearTotalGain: '5 vuoden kokonaisvoitto',
        fiveYearFromDate: '5 vuoden päivämäärä',
        ytdTotalGain: 'Kokonaisvoitto YTD',
        ytdTwr: 'TWR YTD',
        ytdFromDate: 'YTD-päivämäärä',
        mtdTotalGain: 'Kuukauden kokonaisvoitto',
        mtdTwr: 'TWR MTD',
        mtdFromDate: 'MTD-päivämäärä',
        periodEndMarketValue: 'Markkina-arvo kauden lopussa',
        periodEndRelativeMarketValue:
          'Suhteellinen markkina-arvo kauden lopussa',
        periodEndTotalGain: 'Kokonaisvoitto kauden lopussa',
        periodEndWeight: 'Salkun paino kauden lopussa',
        periodEndDate: 'Kauden päätöspäivämäärä',
        sinceInceptionTotalGain: 'Kokonaisvoitto alusta asti',
        sinceInceptionAnnualizedTwr: 'Vuotuinen TWR alusta asti',
        sinceInceptionDate: 'Aloituspäivämäärä',
        thruDate: 'Asti päivämäärä',
        date1: 'Siste kursdato',
        date2: 'Investert dato',
        date3: 'Date 3',
        date4: 'Date 4',
        date5: 'Date 5',
        double1: 'Markedskurs',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'double 4',
        double5: 'Double 5',
        int1: 'Int 1',
        int2: 'Int 2',
        int3: 'Int 3',
        int4: 'Int 4',
        int5: 'Int 5',
        string1: 'String 1',
        string2: 'String 2',
        string3: 'String 3',
        string4: 'String 4',
        string5: 'Dok.',
      },
      performanceTableColumns: {
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Raporttitaso',
        sortOrder: 'Lajittelujärjestys',
        isIndex: 'Onko indeksi',
        currentHolding: 'Nykyinen omistus',
        contactGuid: 'Yhteystiedon GUID',
        accountId: 'Tilinumero',
        portfolioId: 'Salkun tunniste',
        portfolioName: 'Salkun nimi',
        title: 'Omaisuusluokka',
        securitySymbol: 'Symboli',
        isin: 'ISIN',
        sfdr: 'SFDR',
        portfolioBaseTypeCode: 'Peruskoodi',
        securityTypeCode: 'Arvopaperikoodi',
        allocation: 'Allokointi',
        costBasis: 'Hankintameno',
        fifoCost: 'FIFO-kustannus',
        indexWeight: 'Strateginen paino',
        lowerLimit: 'Alaraja',
        upperLimit: 'Yläraja',
        movement: 'Liike',
        quantity: 'Määrä',
        unrealizedGainLoss: 'Realisoimaton voitto/tappio',
        itdAnnualizedTwr: 'TWR alusta asti',
        itdFromDate: 'Aloituspäivämäärä',
        oneYearTwr: '1 vuoden TWR',
        oneYearTotalGain: '1 vuoden kokonaisvoitto',
        oneYearFromDate: '1 vuoden päivämäärä',
        threeYearTotalGain: '3 vuoden kokonaisvoitto',
        threeYearAnnualizedTwr: '3 vuoden TWR',
        threeYearFromDate: '3 vuoden päivämäärä',
        fiveYearAnnualizedTwr: '5 vuoden TWR',
        fiveYearTotalGain: '5 vuoden kokonaisvoitto',
        fiveYearFromDate: '5 vuoden päivämäärä',
        ytdTotalGain: 'Kokonaisvoitto YTD',
        ytdTwr: 'TWR YTD',
        ytdFromDate: 'YTD-päivämäärä',
        mtdTotalGain: 'Kuukauden kokonaisvoitto',
        mtdTwr: 'TWR MTD',
        mtdFromDate: 'MTD-päivämäärä',
        periodEndMarketValue: 'Markkina-arvo kauden lopussa',
        periodEndRelativeMarketValue:
          'Suhteellinen markkina-arvo kauden lopussa',
        periodEndTotalGain: 'Kokonaisvoitto kauden lopussa',
        periodEndWeight: 'Salkun paino kauden lopussa',
        periodEndDate: 'Kauden päätöspäivämäärä',
        sinceInceptionTotalGain: 'Kokonaisvoitto alusta asti',
        sinceInceptionAnnualizedTwr: 'Vuotuinen TWR alusta asti',
        sinceInceptionDate: 'Aloituspäivämäärä',
        thruDate: 'Asti päivämäärä',
        date1: 'Date 1',
        date2: 'Date 2',
        date3: 'Date 3',
        date4: 'Date 4',
        date5: 'Date 5',
        double1: 'Double 1',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'double 4',
        double5: 'Double 5',
        int1: 'Int 1',
        int2: 'Int 2',
        int3: 'Int 3',
        int4: 'Int 4',
        int5: 'Int 5',
        string1: 'String 1',
        string2: 'String 2',
        string3: 'String 3',
        string4: 'String 4',
        string5: 'Dok.',
      },
      performanceTableTwoColumns: {
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Raporttitaso',
        sortOrder: 'Lajittelujärjestys',
        isIndex: 'Onko indeksi',
        currentHolding: 'Nykyinen omistus',
        contactGuid: 'Yhteystiedon GUID',
        accountId: 'Tilinumero',
        portfolioId: 'Salkun tunniste',
        portfolioName: 'Salkun nimi',
        title: 'Omaisuusluokka',
        securitySymbol: 'Symboli',
        isin: 'ISIN',
        sfdr: 'SFDR',
        portfolioBaseTypeCode: 'Peruskoodi',
        securityTypeCode: 'Arvopaperikoodi',
        allocation: 'Allokointi',
        costBasis: 'Hankintameno',
        fifoCost: 'FIFO-kustannus',
        indexWeight: 'Strateginen paino',
        lowerLimit: 'Alaraja',
        upperLimit: 'Yläraja',
        movement: 'Liike',
        quantity: 'Määrä',
        unrealizedGainLoss: 'Realisoimaton voitto/tappio',
        itdAnnualizedTwr: 'TWR alusta asti',
        itdFromDate: 'Aloituspäivämäärä',
        oneYearTwr: '1 vuoden TWR',
        oneYearTotalGain: '1 vuoden kokonaisvoitto',
        oneYearFromDate: '1 vuoden päivämäärä',
        threeYearTotalGain: '3 vuoden kokonaisvoitto',
        threeYearAnnualizedTwr: '3 vuoden TWR',
        threeYearFromDate: '3 vuoden päivämäärä',
        fiveYearAnnualizedTwr: '5 vuoden TWR',
        fiveYearTotalGain: '5 vuoden kokonaisvoitto',
        fiveYearFromDate: '5 vuoden päivämäärä',
        ytdTotalGain: 'Kokonaisvoitto YTD',
        ytdTwr: 'TWR YTD',
        ytdFromDate: 'YTD-päivämäärä',
        mtdTotalGain: 'Kuukauden kokonaisvoitto',
        mtdTwr: 'TWR MTD',
        mtdFromDate: 'MTD-päivämäärä',
        periodEndMarketValue: 'Markkina-arvo kauden lopussa',
        periodEndRelativeMarketValue:
          'Suhteellinen markkina-arvo kauden lopussa',
        periodEndTotalGain: 'Kokonaisvoitto kauden lopussa',
        periodEndWeight: 'Salkun paino kauden lopussa',
        periodEndDate: 'Kauden päätöspäivämäärä',
        sinceInceptionTotalGain: 'Kokonaisvoitto alusta asti',
        sinceInceptionAnnualizedTwr: 'Vuotuinen TWR alusta asti',
        sinceInceptionDate: 'Aloituspäivämäärä',
        thruDate: 'Asti päivämäärä',
        date1: 'Date 1',
        date2: 'Date 2',
        date3: 'Date 3',
        date4: 'Date 4',
        date5: 'Date 5',
        double1: 'Double 1',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'double 4',
        double5: 'Double 5',
        int1: 'Int 1',
        int2: 'Int 2',
        int3: 'Int 3',
        int4: 'Int 4',
        int5: 'Int 5',
        string1: 'String 1',
        string2: 'String 2',
        string3: 'String 3',
        string4: 'String 4',
        string5: 'Dok.',
      },
      transactionsTableColumns: {
        accountId: 'Tilinumero',
        portfolioId: 'Salkun tunniste',
        portfolioName: 'Salkun nimi',
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Report Level',
        sortOrder: 'Lajittelujärjestys',
        isHighlighted: 'Is Highlighted',
        contactGuid: 'Contact Guid',
        title: 'Rahoittaa',
        status: ' ',
        type: 'Tyyppi',
        transactionDate: 'Tapahtumapäivä',
        expectedSettlementDate: 'Odotettu selvityspäivä',
        amount: 'Määrä',
        costPrice: 'Hankintahinta',
        quantity: 'Määrä',
        isin: 'ISIN',
        securityTypeCode: 'Arvopaperikoodi',
        date1: 'Date 1',
        date2: 'Date 2',
        date3: 'Date 3',
        date4: 'Date 4',
        date5: 'Date 5',
        double1: 'Double 1',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'Double 4',
        double5: 'Double 5',
        int1: 'Integer 1',
        int2: 'Integer 2',
        int3: 'Integer 3',
        int4: 'Integer 4',
        int5: 'Integer 5',
        string1: 'String 1',
        string2: 'String 2',
        string3: 'String 3',
        string4: 'String 4',
        string5: 'String 5',
      },
      transactionsTableData: {
        type: {
          1: 'Type 1',
          2: 'Type 2',
        },
        status: {
          1: 'Status 1',
          2: 'Status 2',
        },
      },
      indexTableColumns: {
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Raporttitaso',
        sortOrder: 'Lajittelujärjestys',
        indexGroup: 'Indeksiryhmä',
        title: 'Indeksiseuranta, tuotto',
        annualTwr: 'Tähän mennessä tänä vuonna',
        oneYearTwr: 'Viimeinen vuosi',
        threeYearTwr: 'Viimeiset 3 vuotta',
        fiveYearTwr: 'Viimeiset 5 vuotta',
        yield: 'Tuotto',
        lastPrice: 'Kurssi',
        lastPriceDate: 'Viimeiset kurssidata',
        date1: 'Date 1',
        date2: 'Date 2',
        double1: 'Double 1',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'Double 4',
        double5: 'Double 5',
        int1: 'Int 1',
        int2: 'Int 2',
        string1: 'String 1',
        string2: 'String 2',
      },
    },
    errors: {
      settingsError:
        'On tapahtunut virhe. Ole hyvä palaa etusivulle ja yritä uudelleen.',
      invalidStatus:
        'On tapahtunut virhe. Ole hyvä palaa etusivulle ja yritä uudelleen.',
      shouldNotRedirect:
        'Pahoittelut, tunnistautumisesi epäonnistui. Ole hyvä palaa etusivulle ja kirjaudu sisään uudelleen.',
      invalidAuth:
        'Pahoittelut, tunnistautumisesi epäonnistui. Ole hyvä palaa etusivulle ja kirjaudu sisään uudelleen.',
      invalidAction:
        'Pahoittelut, tämä toimenpide ei ole sallittu. Ole hyvä palaa etusivulle ja yritä uudelleen.',
      invalidReturnUrl:
        'Pahoittelut, tunnistautumisesi epäonnistui. Ole hyvä palaa etusivulle ja kirjaudu sisään uudelleen.',
      getAccessTokenError:
        'Pahoittelut, tunnistautumisesi epäonnistui. Ole hyvä palaa etusivulle ja kirjaudu sisään uudelleen.',
      silentAuthError:
        'Pahoittelut, tunnistautumisesi epäonnistui. Ole hyvä palaa etusivulle ja kirjaudu sisään uudelleen.',
      silentTokenError:
        'Pahoittelut, sivua ei voitu ladata onnistuneesti. Ole hyvä ja kokeile ladata sivu tai kirjautua sisään uudelleen.',
    },
  },
};
