import React, { useEffect, useMemo, useState } from 'react';
import Portfolios from './Portfolios';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import {
  selectRepresentationRights,
  selectSelectedAccount,
  setSelectedRepresentation,
} from '../../api-authorization/representationSlice';
import {
  fetchUserPortfolios,
  fetchSelectedPortfolioHoldingsData,
  selectSelectedPortfolioId,
  getSelectedPortfolioHoldings,
  selectUserPortfolios,
  selectUserPortfoliosFetched,
  setSelectedPortfolioId,
  getSelectedPortfolioHoldingsFetched,
  selectHasUserPortfolios,
  getSelectedPortfolioTimeSeriesFetched,
  getSelectedPortfolioTimeSeries,
  fetchSelectedPortfolioTimeSeriesData,
  getSelectedPortfolioTransactionsFetched,
  getSelectedPortfolioTransactions,
  fetchSelectedPortfolioTransactionsData,
  fetchIndicesData,
  selectIndicesFetched,
  selectIndices,
  selectUserPortfoliosError,
  getSelectedPortfolioHoldingsError,
  getSelectedPortfolioTimeSeriesError,
  getSelectedPortfolioTransactionsError,
  selectIndicesError,
} from './portfoliosSlice';
import { TreeListColumnProps } from '@progress/kendo-react-treelist';
import { RootState } from '../../../app/store';
import envVars from '../../../resources/envVars';
import { PerformanceGraphData, TimeSeriesProps } from './Graph';
import {
  getDefaultPortfolio,
  getPortfolioHoldingsDataObject,
  getPortfolioIndicesDataObject,
  getPortfolioTimeSeriesDataObject,
  getPortfoliosDataObject,
  getPortfolioTransactionsDataObject,
} from '../../../helpers/utils';
import { Holding } from './types';
import { useLocalization } from '@progress/kendo-react-intl';
import { useConfig } from '../../../hooks/useConfig';
import { selectUser } from '../../api-authorization/userSlice';

const PortfoliosProvider = () => {
  let { id } = useParams();

  /**
   * Find out which portfolioId to use
   */
  const portfolioIdUrl = window.location.pathname.includes('/portfolios/')
    ? window.location.pathname.replace('/portfolios/', '')
    : null;
  let portfolioIdMatch: string | null =
    id !== portfolioIdUrl ? portfolioIdUrl : id;
  const isMatch = portfolioIdMatch !== null;
  const portfolioIdRedux = useAppSelector(selectSelectedPortfolioId);
  const portfolioId =
    portfolioIdMatch === null ? portfolioIdRedux : portfolioIdMatch;

  const dispatch = useAppDispatch();

  /**
   * Loading of initial config
   */
  const config = useConfig();
  const portfoliosHoldingsColumnsConfig = useMemo(() => {
    return [
      ...config.portfolios.allocationsTable.columns,
      ...config.portfolios.holdingsTable.columns,
      ...config.portfolios.performanceTable.columns,
      ...config.portfolios.performanceTableTwo.columns,
    ];
  }, [config.portfolios]);

  const portfoliosTimeSeriesColumnsConfig =
    config.portfolios.performanceGraph.columns;

  const portfoliosTransactionsColumnsConfig =
    config.portfolios.transactionsTable.columns;

  const portfoliosIndexColumnsConfig = config.portfolios.indexTable.columns;

  const lastUpdatedInfoConfig = config.portfolios.lastUpdatedInfoPlaceholders;

  const userState = useAppSelector(selectUser);
  const userContactGuid = userState.contactGuid;
  let selectedAccount = useAppSelector(selectSelectedAccount);

  const [selectedContactGuid, setSelectedContactGuid] = useState<
    string | false
  >(
    selectedAccount !== null
      ? selectedAccount?.contactGuid
      : !!userContactGuid && userContactGuid,
  );

  const portfolios: Holding[] | null = useAppSelector(selectUserPortfolios);
  const accountPortfolios: Holding[] | undefined = portfolios?.filter(
    (portfolio: any) => portfolio.contactGuid === selectedContactGuid,
  );

  const hasAccountPortfolios =
    !!accountPortfolios && accountPortfolios.length > 0;

  const isUserPortfoliosFetched = useAppSelector(selectUserPortfoliosFetched);
  const userPortfoliosError = useAppSelector(selectUserPortfoliosError);
  const hasUserPortfolios = useAppSelector(selectHasUserPortfolios);

  const [selectedPortfolio, setSelectedPortfolio] = useState<
    Holding | undefined
  >();

  const [selectedRecordId, setSelectedRecordId] = useState<number>();

  const GATEWAY_API_URL = envVars.GATEWAY_API_URL;
  const url = `${GATEWAY_API_URL}/portfolios`;

  /**
   * Check if portfolioId match (belongs to) accountPortfolios of selectedContactGuid
   */
  const representationRights = useAppSelector(selectRepresentationRights);
  useEffect(() => {
    const portfolioMatch: Holding | undefined = accountPortfolios?.filter(
      (portfolio: Holding) => portfolio.portfolioId === portfolioId,
    )[0];
    if (portfolioMatch === undefined && portfolioId !== null) {
      const contactGuid =
        !!portfolios &&
        portfolios.filter(
          (portfolio: Holding) => portfolio.portfolioId === portfolioId,
        )[0]?.contactGuid;
      setSelectedContactGuid(contactGuid);
      // Set selected account and accountPortfolios once we see that portfolioId doesn't belong to current selected account
      const account = representationRights?.filter(
        account => account.contactGuid === contactGuid,
      )[0];
      dispatch(setSelectedRepresentation(account));
    }
  }, [
    dispatch,
    representationRights,
    portfolios,
    accountPortfolios,
    portfolioId,
    selectedAccount,
    selectedContactGuid,
  ]);

  useEffect(() => {
    if (!portfolioId) {
      setSelectedPortfolio(undefined);
    }
  }, [portfolioId]);

  useEffect(() => {
    if (
      !selectedPortfolio &&
      !!accountPortfolios &&
      accountPortfolios?.length > 0
    ) {
      const defaultPortfolio = getDefaultPortfolio(
        isMatch,
        accountPortfolios,
        portfolioId,
      );

      if (!!defaultPortfolio) {
        setSelectedPortfolio(defaultPortfolio);
        setSelectedContactGuid(defaultPortfolio.contactGuid);
        setSelectedRecordId(defaultPortfolio.recordId);

        dispatch(setSelectedPortfolioId(defaultPortfolio.portfolioId));
      }
    }
  }, [dispatch, selectedPortfolio, isMatch, accountPortfolios, portfolioId]);

  /**
   * Fetch User Portfolios, if not fetched already
   */
  useEffect(() => {
    /**
     * The tricky behavior of useEffect hook in React 18
     * https://medium.com/geekculture/the-tricky-behavior-of-useeffect-hook-in-react-18-282ef4fb570a
     * let ignore
     */
    let ignore = false;
    if (isUserPortfoliosFetched === null) {
      const dataObject = getPortfoliosDataObject(
        selectedContactGuid,
        url,
        portfoliosHoldingsColumnsConfig,
        lastUpdatedInfoConfig,
      );

      setTimeout(() => {
        if (!ignore) {
          dispatch(fetchUserPortfolios(dataObject));
        }
      }, 0);
    }
    return () => {
      ignore = true;
    };
  }, [
    dispatch,
    url,
    selectedContactGuid,
    isUserPortfoliosFetched,
    portfoliosHoldingsColumnsConfig,
    lastUpdatedInfoConfig,
  ]);

  const isHoldingsFetched = useAppSelector((state: RootState) =>
    getSelectedPortfolioHoldingsFetched(state),
  );
  const isTimeSeriesFetched = useAppSelector((state: RootState) =>
    getSelectedPortfolioTimeSeriesFetched(state),
  );
  const isTransactionsFetched = useAppSelector((state: RootState) =>
    getSelectedPortfolioTransactionsFetched(state),
  );
  const isIndicesFetched = useAppSelector(selectIndicesFetched);

  const holdingsError = useAppSelector((state: RootState) =>
    getSelectedPortfolioHoldingsError(state),
  );
  const timeSeriesError = useAppSelector((state: RootState) =>
    getSelectedPortfolioTimeSeriesError(state),
  );
  const transactionsError = useAppSelector((state: RootState) =>
    getSelectedPortfolioTransactionsError(state),
  );
  const indicesError = useAppSelector(selectIndicesError);

  /**
   * Fetch Selected Portfolio Holdings Data, if not fetched already
   */
  useEffect(() => {
    if (
      !isHoldingsFetched &&
      !!portfolioId &&
      !!selectedRecordId &&
      hasAccountPortfolios
    ) {
      const dataObject = getPortfolioHoldingsDataObject(
        selectedContactGuid,
        portfolioId,
        url,
        portfoliosHoldingsColumnsConfig,
      );
      dispatch(fetchSelectedPortfolioHoldingsData(dataObject));
    }
  }, [
    dispatch,
    portfolioId,
    selectedContactGuid,
    selectedRecordId,
    url,
    portfoliosHoldingsColumnsConfig,
    isHoldingsFetched,
    hasAccountPortfolios,
  ]);

  /**
   * Fetch Selected Portfolio TimeSeriesData - when Holdings data is fetched, if not fetched already
   */
  useEffect(() => {
    if (
      !!isHoldingsFetched &&
      isTimeSeriesFetched === undefined &&
      !!portfolioId &&
      !!selectedRecordId &&
      hasAccountPortfolios
    ) {
      const dataObject = getPortfolioTimeSeriesDataObject(
        selectedContactGuid,
        portfolioId,
        url,
        portfoliosTimeSeriesColumnsConfig,
      );
      dispatch(fetchSelectedPortfolioTimeSeriesData(dataObject));
    }
  }, [
    dispatch,
    portfolioId,
    selectedContactGuid,
    selectedRecordId,
    url,
    portfoliosTimeSeriesColumnsConfig,
    isHoldingsFetched,
    isTimeSeriesFetched,
    hasAccountPortfolios,
  ]);

  /**
   * Fetch Selected Portfolio Transactions Data - when TimeSeriesData is fetched, if not fetched already
   */
  useEffect(() => {
    if (
      !!isTimeSeriesFetched &&
      isTransactionsFetched === undefined &&
      !!portfolioId &&
      !!selectedRecordId &&
      hasAccountPortfolios
    ) {
      const dataObject = getPortfolioTransactionsDataObject(
        selectedContactGuid,
        portfolioId,
        url,
        portfoliosTransactionsColumnsConfig,
      );
      dispatch(fetchSelectedPortfolioTransactionsData(dataObject));
    }
  }, [
    dispatch,
    portfolioId,
    selectedContactGuid,
    selectedRecordId,
    url,
    portfoliosTransactionsColumnsConfig,
    isTimeSeriesFetched,
    isTransactionsFetched,
    hasAccountPortfolios,
  ]);

  /**
   * Fetch Selected Portfolio IndexData - when Transactions data is fetched, if not fetched already
   */
  useEffect(() => {
    if (
      !!isTransactionsFetched &&
      isIndicesFetched === undefined &&
      !!portfolioId &&
      !!selectedRecordId &&
      hasAccountPortfolios
    ) {
      const dataObject = getPortfolioIndicesDataObject(
        url,
        portfoliosIndexColumnsConfig,
      );
      dispatch(fetchIndicesData(dataObject));
    }
  }, [
    dispatch,
    portfolioId,
    selectedContactGuid,
    selectedRecordId,
    url,
    portfoliosIndexColumnsConfig,
    isTransactionsFetched,
    isIndicesFetched,
    hasAccountPortfolios,
  ]);

  const holdings = useAppSelector((state: RootState) =>
    getSelectedPortfolioHoldings(state),
  );
  const timeSeries = useAppSelector((state: RootState) =>
    getSelectedPortfolioTimeSeries(state),
  );
  const transactions = useAppSelector((state: RootState) =>
    getSelectedPortfolioTransactions(state),
  );
  const indices = useAppSelector(selectIndices);

  const timeSeriesTitle = !!timeSeries && timeSeries[0]?.title;
  const timeSeriesPortfolioId = !!timeSeries && timeSeries[0]?.portfolioId;
  const timeSeriesArray: TimeSeriesProps[] = timeSeries?.map((ts: any) => {
    const dateObject = portfoliosTimeSeriesColumnsConfig.filter(
      (column: { field: string; name: string }) => column.name === 'date',
    )[0];
    const date = dateObject?.field;
    const valueObject = portfoliosTimeSeriesColumnsConfig.filter(
      (column: { field: string; name: string }) => column.name === 'value',
    )[0];
    const value = valueObject?.field;
    return {
      date: new Date(ts[date]),
      value: ts[value],
    };
  });

  const timeSeriesData: PerformanceGraphData[] = !!timeSeries
    ? [
        {
          title: timeSeriesTitle,
          portfolioId: timeSeriesPortfolioId,
          timeSeries: timeSeriesArray,
          color:
            !!config.portfolios.performanceGraph?.colors &&
            config.portfolios.performanceGraph.colors[0],
        },
      ]
    : [];

  const onChange = (e: any) => {
    const portfolio = e.value;
    const selectedContactGuid = portfolio.contactGuid;
    setSelectedContactGuid(selectedContactGuid);
    dispatch(setSelectedPortfolioId(portfolio.portfolioId));
    setSelectedRecordId(portfolio.recordId);
    setSelectedPortfolio(portfolio);

    window.history.replaceState(
      null,
      '',
      `portfolios/${portfolio.portfolioId}`,
    );
  };

  // Update title column properties for tables
  const localization = useLocalization();
  const translateColumn = (
    column: TreeListColumnProps,
    translationKey: string,
  ) => {
    column.title = localization.toLanguageString(
      translationKey,
      translationKey,
    );
    return column;
  };

  const allocationTableColumns = config.portfolios.allocationsTable.columns.map(
    (column: TreeListColumnProps) => {
      return translateColumn(
        column,
        `construo.portfolios.allocationTableColumns.${column.field}`,
      );
    },
  );
  const allocationTableReportLevel =
    config.portfolios.allocationsTable.reportLevelDepth || 1;

  const holdingsTableColumns = config.portfolios.holdingsTable.columns.map(
    (column: TreeListColumnProps) => {
      return translateColumn(
        column,
        `construo.portfolios.holdingsTableColumns.${column.field}`,
      );
    },
  );
  const holdingsTableReportLevel =
    config.portfolios.holdingsTable.reportLevelDepth || 2;

  const performanceTableColumns =
    config.portfolios.performanceTable.columns.map(
      (column: TreeListColumnProps) => {
        return translateColumn(
          column,
          `construo.portfolios.performanceTableColumns.${column.field}`,
        );
      },
    );
  const performanceTableReportLevel =
    config.portfolios.performanceTable.reportLevelDepth || 1;

  const performanceTableTwoColumns =
    config.portfolios.performanceTableTwo.columns.map(
      (column: TreeListColumnProps) => {
        return translateColumn(
          column,
          `construo.portfolios.performanceTableTwoColumns.${column.field}`,
        );
      },
    );
  const performanceTableTwoReportLevel =
    config.portfolios.performanceTableTwo.reportLevelDepth || 2;

  const transactionsTableColumns =
    config.portfolios.transactionsTable.columns.map(
      (column: TreeListColumnProps) => {
        return translateColumn(
          column,
          `construo.portfolios.transactionsTableColumns.${column.field}`,
        );
      },
    );
  const transactionsTableReportLevel =
    config.portfolios.transactionsTable.reportLevelDepth || 1;

  const indexTableColumns = portfoliosIndexColumnsConfig.map(
    (column: TreeListColumnProps) => {
      return translateColumn(
        column,
        `construo.portfolios.indexTableColumns.${column.field}`,
      );
    },
  );
  const indexTableReportLevel =
    config.portfolios.indexTable.reportLevelDepth || 1;

  const isLoading = !isUserPortfoliosFetched;

  return (
    <Portfolios
      isLoading={isLoading}
      hasUserPortfolios={hasUserPortfolios !== null && hasUserPortfolios}
      hasAccountPortfolios={hasAccountPortfolios}
      accountPortfolios={accountPortfolios}
      selectedPortfolio={selectedPortfolio}
      selectedRecordId={selectedRecordId}
      holdingsData={holdings}
      timeSeriesData={timeSeriesData}
      transactionsData={transactions}
      indicesData={indices}
      isHoldingsFetched={isHoldingsFetched}
      isTimeSeriesFetched={isTimeSeriesFetched}
      isTransactionsFetched={isTransactionsFetched}
      isIndicesFetched={isIndicesFetched}
      userPortfoliosError={userPortfoliosError}
      holdingsError={holdingsError}
      timeSeriesError={timeSeriesError}
      transactionsError={transactionsError}
      indicesError={indicesError}
      allocationTableColumns={allocationTableColumns}
      allocationTableReportLevel={allocationTableReportLevel}
      holdingsTableColumns={holdingsTableColumns}
      holdingsTableReportLevel={holdingsTableReportLevel}
      performanceTableColumns={performanceTableColumns}
      performanceTableReportLevel={performanceTableReportLevel}
      performanceTableTwoColumns={performanceTableTwoColumns}
      performanceTableTwoReportLevel={performanceTableTwoReportLevel}
      transactionsTableColumns={transactionsTableColumns}
      transactionsReportLevel={transactionsTableReportLevel}
      indexTableColumns={indexTableColumns}
      indexTableReportLevel={indexTableReportLevel}
      onChange={onChange}
    />
  );
};

export default PortfoliosProvider;
