import React, { useEffect } from 'react';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { Button } from '@progress/kendo-react-buttons';
import { selectPreviewProps } from '../documents/documentsSlice';
import Layout from '../../layout/Layout';
import { useTranslations } from '../../../hooks/useTranslations';
import { isSafari } from '../../../helpers/utils';

interface DocumentPreviewProps {
  guid: string;
}

const DocumentPreview = ({ guid }: DocumentPreviewProps) => {
  const previewProps = useAppSelector(selectPreviewProps);
  const contentType = previewProps.docType;
  const documentSrc = previewProps.url;
  const documentName = `document_${guid}`;
  const docLoading = false;

  const isPdf = contentType === 'application/pdf';
  const isImage = contentType.startsWith('image/');

  const ns = 'construo.global';
  const { t } = useTranslations();

  useEffect(() => {
    if (!docLoading && contentType !== '') {
      const object: HTMLElement | null = document.getElementById('object');
      const img: HTMLElement | null = document.getElementById('image');

      if (isPdf) {
        object?.setAttribute('data', documentSrc);
      } else if (isImage) {
        img?.setAttribute('src', documentSrc);
      }
    }
  }, [documentSrc, contentType, docLoading, isImage, isPdf]);

  const downloadDocument = () => {
    const link = document.createElement('a');
    link.href = documentSrc;
    link.download = `document_${guid}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Layout>
        <div className='main-section document-preview'>
          <div className='container'>
            {isPdf ? (
              <>
                <object
                  id='object'
                  title=''
                  type={isSafari ? '' : 'application/pdf'}
                  style={{
                    height: '100vh',
                    width: '100%',
                  }}
                >
                  <div id='append-container'>
                    {t(`${ns}.previewNotSupported`)}
                    <br />
                  </div>
                </object>
              </>
            ) : isImage ? (
              <>
                <img
                  id='image'
                  alt={documentName}
                  title={documentName}
                  style={{
                    maxHeight: '100%',
                    maxWidth: '100%',
                  }}
                />
              </>
            ) : (
              <>{t(`${ns}.previewNotAvailable`)}</>
            )}
            <div className='download-button'>
              <Button
                themeColor='primary'
                iconClass='fal fa-download'
                onClick={downloadDocument}
              >
                {t(`${ns}.download`)}
              </Button>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default DocumentPreview;
