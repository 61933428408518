import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { Button } from '@progress/kendo-react-buttons';
import ConversationListProvider from './ConversationListProvider';
import { useNavigate } from 'react-router-dom';
import OpenActionList from './OpenActionList';
import envVars from '../../../resources/envVars';
import HelmetTitle from '../../common/HelmetTitle';
import { resetAlerts } from '../../../app/slices/alertSlice';
import { selectRepresentationRights } from '../../api-authorization/representationSlice';
import Layout from '../../layout/Layout';
import { useTranslations } from '../../../hooks/useTranslations';

export interface InboxProps {
  title?: string;
  newMessage?: string;
  handleNewMessageClick?: () => void;
  isConversationFeature?: boolean;
  conversationList?: JSX.Element;
  openActionList?: JSX.Element;
}

const Inbox = ({
  title: titleProp,
  newMessage: newMessageProp,
  handleNewMessageClick: handleNewMessageClickProp,
  isConversationFeature: isConversationFeatureProp,
  conversationList: conversationListProp,
  openActionList: openActionListProp,
}: InboxProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const ns = 'construo.inbox';
  const { t } = useTranslations();

  const handleNewMessageClick = () => {
    navigate('/conversations/new-message');
  };

  const isConversationFeature =
    isConversationFeatureProp === undefined
      ? envVars.FEATURES?.includes('conversations')
      : isConversationFeatureProp;
  const title = titleProp === undefined ? t(`${ns}.title`) : titleProp;
  const newMessage =
    newMessageProp === undefined ? t(`${ns}.newMessage`) : newMessageProp;
  const newMessageClick =
    handleNewMessageClickProp === undefined
      ? handleNewMessageClick
      : handleNewMessageClickProp;

  useEffect(() => {
    return () => {
      dispatch(resetAlerts());
      sessionStorage.removeItem('alerts'); // Remove saved alerts (from session storage) once user leaves Inbox page/component
    };
  }, [dispatch]);

  const ConversationList = () => {
    return conversationListProp === undefined ? (
      <ConversationListProvider />
    ) : (
      conversationListProp
    );
  };

  const PendingActionsList = () => {
    return openActionListProp === undefined ? (
      <OpenActionList />
    ) : (
      openActionListProp
    );
  };

  const accounts = useAppSelector(selectRepresentationRights);
  const hasRepresentationRights = !!accounts && accounts.length > 0;

  return (
    <>
      <HelmetTitle title={title} />
      <Layout>
        <section className='main-section inbox'>
          <div className='container'>
            <header>
              <h1>{title}</h1>
              {isConversationFeature && hasRepresentationRights && (
                <Button
                  onClick={newMessageClick}
                  iconClass='order-2 fal fa-edit'
                >
                  {newMessage}
                </Button>
              )}
            </header>
            <PendingActionsList />
            {isConversationFeature && <ConversationList />}
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Inbox;
