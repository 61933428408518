import React from 'react';
import HelmetTitle from '../../common/HelmetTitle';
import Layout from '../../layout/Layout';
import { Card, CardBody, CardSubtitle } from '@progress/kendo-react-layout';
import LanguageSwitcher from '../../common/LanguageSwitcher';
import { useTranslations } from '../../../hooks/useTranslations';

function InformationPage() {
  const ns = 'construo.forms';
  const { t } = useTranslations();
  return (
    <>
      <HelmetTitle title={t(`${ns}.requiredAuthenticationInfoPageHeader`)} />

      <Layout isSplashLayout={true} layoutClassName='not-found-layout'>
        <div className='main-section not-found'>
          <div className='container'>
            <Card>
              <CardBody>
                <h1 className='k-card-title'>
                  {t(`${ns}.requiredAuthenticationInfoPageHeader`)}
                </h1>
                <CardSubtitle>
                  {t(`${ns}.requiredAuthenticationInfoPageText`)}
                </CardSubtitle>
              </CardBody>
            </Card>
            <LanguageSwitcher />
          </div>
        </div>
      </Layout>
    </>
  );
}

export default InformationPage;
