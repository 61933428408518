import { Chat } from '@progress/kendo-react-conversational-ui';
import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { getAxiosConfig } from '../../../helpers/utils';
import authService from '../../api-authorization/AuthorizeService';
import envVars from '../../../resources/envVars';
import HelmetTitle from '../../common/HelmetTitle';
import { resetAlerts, setAlerts } from '../../../app/slices/alertSlice';
import {
  fetchConversation,
  resetConversation,
  resetConversationList,
  resetUnreadConversations,
  selectConversation,
  selectConversationError,
  selectConversationFetched,
} from './conversationsSlice';
import Layout from '../../layout/Layout';
import { useTranslations } from '../../../hooks/useTranslations';

const Conversation = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const unavailableTask = urlParams.get('unavailable');
  const isUnavailableTask = !!unavailableTask;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { conversationId } = useParams();

  const ns = 'construo.inbox';
  const { t } = useTranslations();

  const baseUrl = envVars.API_BASE_URI;

  const conversation = useAppSelector(selectConversation);
  const conversationFetched = useAppSelector(selectConversationFetched);
  const conversationError = useAppSelector(selectConversationError);

  const conversationStatusId = conversation?.statusId;
  const isConversationClosed = !conversationStatusId
    ? false
    : conversationStatusId === 1
      ? false
      : true;

  useEffect(() => {
    if (conversationFetched === null) {
      const url = `${baseUrl}/user/conversations/${conversationId}`;
      const dataObject = {
        url,
      };
      dispatch(resetUnreadConversations()); // reset number of unread conversations so app can refresh and see any changes should be made to number inside navigation item
      dispatch(fetchConversation(dataObject));
    }
  }, [dispatch, baseUrl, conversationFetched, conversationId]);

  useEffect(() => {
    return () => {
      dispatch(resetConversation());
      dispatch(resetConversationList());
      dispatch(resetAlerts());
    };
  }, [dispatch]);

  const addMessage = async (message: any) => {
    const url = `${baseUrl}/user/conversations/${conversationId}/activities`;
    const data = JSON.stringify({
      identifier: conversationId,
      activity: {
        content: message.text,
        activityTypeId: 2,
        contentTypeId: 1,
      },
    });
    const token = await authService.getAccessToken();
    const config = getAxiosConfig(token, 'json');
    try {
      const response = await axios.post(url, data, config);
      const dataResponse = response.data.data;
      dispatch(resetConversation());
      dispatch(resetUnreadConversations());
      return dataResponse;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Show error message after Complete Action Event fails
        const errorResponse = error.message;
        dispatch(setAlerts({ message: errorResponse, type: 'error' }));
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      } else {
        dispatch(
          setAlerts({
            message:
              'Something went wrong. Try again and if error persist contact administrator.',
            type: 'error',
          }),
        );
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      }
    }
  };

  const addNewMessage = (event: any) => {
    addMessage(event.message);
  };

  const mappedParticipants = conversation?.participants?.map(
    (participant: any) => {
      return {
        id: participant.userId,
        name: participant.participantName,
        isCurrent: participant.isCurrent,
      };
    },
  );

  const currentUser = mappedParticipants?.filter(
    (participant: any) => participant.isCurrent === true,
  )[0];

  const mappedMessages = conversation?.activities?.map((activity: any) => {
    const authorId = activity.userName ? activity.userId : null;
    const authorName = activity.userName
      ? activity.userName
      : t(`construo.workflows.anonymousAuthorName`);
    const timestamp = new Date(activity.dateCreated);
    const text = activity.content;
    return {
      author: {
        id: authorId,
        name: authorName,
      },
      text: text,
      timestamp: timestamp,
    };
  });

  const notCurrentUserActivities = conversation?.activities?.filter(
    (activity: any) => activity.userId !== currentUser.id,
  );

  const lastActivityId = useRef(null);

  lastActivityId.current =
    !!notCurrentUserActivities &&
    notCurrentUserActivities[notCurrentUserActivities.length - 1]?.activityGuid;

  const unreadMessages = conversation.numberOfUnreadActivities;

  const [isReadReceipts, setIsReadReceipts] = useState(true);

  /**
   * 3 second after conversation is loaded trigger "Create read receipt" for last Outbound Activity (activityId) in THE Conversation (conversationId)
   * This will set all unread messages to have status "read"
   */
  useEffect(() => {
    if (!!conversationId && unreadMessages > 0 && isReadReceipts) {
      setTimeout(async () => {
        const activityId = lastActivityId.current;
        const url = `${baseUrl}/user/conversations/${conversationId}/activities/${activityId}/readReceipts`;
        const workflowId = conversation.parentId;
        const data = {
          parentId: workflowId,
        };
        const dataStringify = JSON.stringify(data);
        const token = await authService.getAccessToken();
        const config = getAxiosConfig(token, 'json');

        await axios
          .post(url, dataStringify, config)
          .then(response => response.data)
          .then(response => {
            dispatch(resetConversationList());
            dispatch(resetUnreadConversations());
            // dispatch(resetConversation());
            setIsReadReceipts(false);
            return response;
          })
          .catch(error => {
            // Show error message after Complete Action Event fails
            const errorGenericMessage = error.message;
            const errorResponseMessage = error.response.data.Message;
            const errorMessage = !!errorResponseMessage
              ? errorResponseMessage
              : errorGenericMessage;

            dispatch(
              setAlerts({
                message: `${t(`construo.workflows.readReceiptsError`)} ${errorMessage}`,
                type: 'error',
              }),
            );
            // And scroll to top so that alert is visible
            window.scrollTo(0, 0);
          });
      }, 3000); // readReceipts event is delayed for 3 sec
    }
  }, [
    dispatch,
    baseUrl,
    conversationId,
    conversation.parentId,
    unreadMessages,
    isReadReceipts,
    t,
  ]);

  // In case of conversation fetching error you're redirected to "No Resource Page"
  const [showError, setShowError] = useState<boolean>(false);
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setShowError(true);
    }
  }, []);
  useEffect(() => {
    if (!!conversationFetched && !!conversationError) {
      navigate(
        `/resource-not-found?type=conversation&guid=${conversationId}${
          showError ? `&error=${conversationError}` : ``
        }`,
      );
    }
  }, [
    navigate,
    conversationFetched,
    conversationId,
    showError,
    conversationError,
  ]);

  return (
    <>
      <HelmetTitle title={conversation.friendlyName} />

      <Layout>
        <section className='main-section task-messages'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-8 col-xl-9'>
                <NavLink className='back-link' to='/inbox'>
                  {t(`${ns}.backToInbox`)}
                </NavLink>
                <h2>{conversation.friendlyName}</h2>
                <Chat
                  className={`conversation ${
                    (isConversationClosed || isUnavailableTask) && 'disabled'
                  }`}
                  user={currentUser}
                  messages={mappedMessages}
                  onMessageSend={addNewMessage}
                  placeholder={
                    !!mappedMessages
                      ? t(`construo.workflows.placeholderText`)
                      : undefined
                  }
                />
                {(isConversationClosed || isUnavailableTask) && (
                  <p className='closed-conversation alert alert-secondary'>
                    {isConversationClosed && (
                      <span>{t(`${ns}.warningConversationStatus`)}</span>
                    )}
                    {isUnavailableTask && (
                      <span>{t(`${ns}.warningUnavailableTask`)}</span>
                    )}
                  </p>
                )}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Conversation;
