import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query';
import useAccountBasedSignatures from '../../../hooks/useAccountBasedSignatures';
import useAccountBasedUploads from '../../../hooks/useAccountBasedUploads';
import useAccountBasedSubmissions from '../../../hooks/useAccountBasedSubmissions';
import useAccountBasedWorkflows from '../../../hooks/useAccountBasedWorkflows';
import DocumentModal from '../documents/DocumentModal';
import DocumentList from '../documents/DocumentList';
import SignatureRequestList from '../../signatures/SignatureRequestList';
import UploadRequestList from '../../upload-requests/UploadRequestList';
import { Loading, selectLoading } from '../../../app/slices/loadingSlice';
import { Modal, selectModal } from '../../../app/slices/modalSlice';
import {
  clearLatestDocumentsError,
  clearLatestPublicationsError,
  populateRecentDocumentsData,
  selectLatestDocError,
  selectLatestDocFetched,
  selectLatestDocumentsData,
} from '../documents/documentsSlice';
import {
  clearSignaturesError,
  DigitalIdentityProps,
  selectDigitalIdentity,
  selectSignatureError,
  selectSignaturesFetched,
} from '../../signatures/signaturesSlice';
import {
  clearUploadsError,
  selectUploadError,
  selectUploadsFetched,
} from '../../upload-requests/uploadSlice';
import {
  selectSubmissionsError,
  selectSubmissionsFetched,
} from '../../submissions/submissionsSlice';
import { resetAlerts, setAlerts } from '../../../app/slices/alertSlice';
import envVars from '../../../resources/envVars';
import RequiredActionSkeleton from './RequiredActionSkeleton';
import SubmissionList from '../../submissions/SubmissionList';
import WorkflowList from '../../workflows/WorkflowList';
import {
  selectWorkflowsError,
  selectWorkflowsFetched,
} from '../../workflows/workflowSlice';
import {
  selectRepresentationRights,
  selectRepresentationsError,
  selectSelectedAccount,
} from '../../api-authorization/representationSlice';
import PortfoliosSectionProvider from './portfolios-section/PortfoliosSectionProvider';
import PublicationsSectionProvider, {
  categoryProps,
} from './publications-section/PublicationsSectionProvider';
import { getPublicationCategories } from '../../../helpers/utils';
import { useTranslations } from '../../../hooks/useTranslations';

type HomePrivateProps = {
  userContactGuid: string | null;
};

const HomePrivate = (props: HomePrivateProps) => {
  const dispatch = useAppDispatch();
  const { userContactGuid } = props;

  const urlString = window.location.href;
  const url = new URL(urlString);
  const eSignStatus = url.searchParams.get('esign');

  const API_BASE_URI = envVars.API_BASE_URI;
  const RECENT_DOC_NO: number = envVars.RECENT_DOC_NO;
  const RECENT_PUBLICATIONS_NO: number = envVars.RECENT_PUBLICATIONS_NO;
  const recentDocNo: number = RECENT_DOC_NO === 0 ? 0 : RECENT_DOC_NO;
  const recentPublicationsNo: number =
    RECENT_PUBLICATIONS_NO === 0 ? 0 : RECENT_PUBLICATIONS_NO;

  const selectedAccount = useAppSelector(selectSelectedAccount);
  const selectedContactGuid =
    selectedAccount !== null ? selectedAccount?.contactGuid : userContactGuid;
  const accounts = useAppSelector(selectRepresentationRights);
  const accountsContactGuids = accounts?.map(account => account.contactGuid);
  const representationsError = useAppSelector(selectRepresentationsError);

  const hasRepresentationRights = !!accounts && accounts.length > 0;

  useEffect(() => {
    if (selectedAccount === null) {
      const isRepresentationsError = !!representationsError;
      if (isRepresentationsError) {
        dispatch(
          setAlerts({
            message: `When trying to fetch representation rights for logged in user service failed with a message: ${representationsError}`,
            type: 'error',
          }),
        );
      }
    }
  }, [dispatch, selectedAccount, representationsError]);

  const categories: categoryProps[] = getPublicationCategories();

  const isLatestDocuments = recentDocNo > 0;
  const isLatestPublications =
    recentPublicationsNo > 0 && categories.length > 0;

  const isDocumentListFeature = envVars.FEATURES?.includes('documentList');
  const isPublicationListFeature =
    envVars.FEATURES?.includes('publicationList');
  const isESignRequestsFeature = envVars.FEATURES?.includes('eSignRequests');
  const isUploadRequestsFeature = envVars.FEATURES?.includes('uploadRequests');
  const isFormSharingFeature = envVars.FEATURES?.includes('formRequests');
  const isWorkflowsFeature = envVars.FEATURES?.includes('workflows');
  const isPortfoliosFeature = envVars.FEATURES?.includes('portfolios');

  const isActionsFeature =
    isESignRequestsFeature ||
    isUploadRequestsFeature ||
    isFormSharingFeature ||
    isWorkflowsFeature;

  const [docErrorSet, setDocErrorSet] = useState(false);
  const [workflowErrorSet, setWorkflowErrorSet] = useState(false);
  const [submissionErrorSet, setSubmissionErrorSet] = useState(false);
  const [uploadErrorSet, setUploadErrorSet] = useState(false);
  const [signatureErrorSet, setSignatureErrorSet] = useState(false);
  const [isLoadingRequiredActions, setIsLoadingRequiredActions] =
    useState(true);

  const [isActionsVisible, setIsActionsVisible] = useState(true);

  const signaturesFetched = useAppSelector(selectSignaturesFetched);
  const signatureError = useAppSelector(selectSignatureError);

  const submissionsFetched = useAppSelector(selectSubmissionsFetched);
  const submissionError = useAppSelector(selectSubmissionsError);

  const uploadsFetched = useAppSelector(selectUploadsFetched);
  const uploadError = useAppSelector(selectUploadError);

  const workflowsFetched = useAppSelector(selectWorkflowsFetched);
  const workflowError = useAppSelector(selectWorkflowsError);

  const latestDocuments = useAppSelector(selectLatestDocumentsData);
  const latestDocumentsFetched = useAppSelector(selectLatestDocFetched);
  const latestDocumentsError = useAppSelector(selectLatestDocError);

  useEffect(() => {
    if (!!signatureError && !signatureErrorSet) {
      setSignatureErrorSet(true);
      // Show error message
      dispatch(setAlerts({ message: signatureError, type: 'error' }));
      // And scroll to top so that alert is visible
      window.scrollTo(0, 0);
    }
    if (!!uploadError && !uploadErrorSet) {
      setUploadErrorSet(true);
      // Show error message
      dispatch(setAlerts({ message: uploadError, type: 'error' }));
      // And scroll to top so that alert is visible
      window.scrollTo(0, 0);
    }
    if (!!submissionError && !submissionErrorSet) {
      setSubmissionErrorSet(true);
      // Show error message
      dispatch(setAlerts({ message: submissionError, type: 'error' }));
      // And scroll to top so that alert is visible
      window.scrollTo(0, 0);
    }

    if (!!workflowError && !workflowErrorSet) {
      setWorkflowErrorSet(true);
      // Show error message
      dispatch(setAlerts({ message: workflowError, type: 'error' }));
      // And scroll to top so that alert is visible
      window.scrollTo(0, 0);
    }

    if (!!latestDocumentsError && !docErrorSet) {
      setDocErrorSet(true);
      // Show error message
      dispatch(setAlerts({ message: latestDocumentsError, type: 'error' }));
      dispatch(clearLatestDocumentsError()); // Clear latest documents -> error prop immediately when doc alert is added - setAlerts for docs
      // And scroll to top so that alert is visible
      window.scrollTo(0, 0);
    }
  }, [
    dispatch,
    workflowError,
    workflowErrorSet,
    submissionError,
    submissionErrorSet,
    uploadError,
    uploadErrorSet,
    signatureError,
    signatureErrorSet,
    latestDocumentsError,
    docErrorSet,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearSignaturesError());
      dispatch(clearUploadsError());
      dispatch(clearLatestDocumentsError());
      dispatch(clearLatestPublicationsError());
      dispatch(resetAlerts());
    };
  }, [dispatch]);

  const ns = 'construo.homepage';
  const { t } = useTranslations();

  /**
   * Fetch Latest Documents
   */
  useEffect(() => {
    /**
     * The tricky behavior of useEffect hook in React 18
     * https://medium.com/geekculture/the-tricky-behavior-of-useeffect-hook-in-react-18-282ef4fb570a
     * let ignore
     */
    let ignore = false;
    const returnSelectedContactGuidsArray = (guid: any) => {
      return [].concat(guid);
    };
    const selectedContactGuids = returnSelectedContactGuidsArray(
      !!selectedContactGuid ? selectedContactGuid : userContactGuid,
    );
    const otherContactGuids = accountsContactGuids?.filter(
      guid => guid !== userContactGuid,
    );
    const isUserContactGuid = selectedContactGuid === userContactGuid;
    const dataObject = {
      baseUrl: API_BASE_URI,
      recent: recentDocNo,
      selectedContactGuids: selectedContactGuids,
      otherContactGuids: otherContactGuids,
      isUserContactGuid: isUserContactGuid,
    };
    if (
      latestDocumentsFetched === null &&
      isDocumentListFeature &&
      isLatestDocuments
    ) {
      setTimeout(() => {
        if (!ignore) {
          dispatch(populateRecentDocumentsData(dataObject));
        }
      }, 0);
    }
    return () => {
      ignore = true;
    };
  }, [
    dispatch,
    latestDocumentsFetched,
    isDocumentListFeature,
    isLatestDocuments,
    API_BASE_URI,
    recentDocNo,
    selectedContactGuid,
    accountsContactGuids,
    userContactGuid,
  ]);

  // Found out about user Digital Identity
  const digitalIdentity: DigitalIdentityProps | null = useAppSelector(
    selectDigitalIdentity,
  );
  const nin = digitalIdentity?.nin;
  const idp = digitalIdentity?.idp;
  const isUserMissingNinOrIdp = !nin || !idp?.includes('bankid');

  useEffect(() => {
    if (
      ((signaturesFetched || !isESignRequestsFeature) &&
        (uploadsFetched || !isUploadRequestsFeature) &&
        (submissionsFetched || !isFormSharingFeature) &&
        (workflowsFetched || !isWorkflowsFeature)) ||
      (signaturesFetched === null && isUserMissingNinOrIdp) // when user doesn't have NIN or Idp signature requests can't be fetched
    ) {
      setIsLoadingRequiredActions(false);
    } else {
      setIsLoadingRequiredActions(true);
    }
  }, [
    dispatch,
    signaturesFetched,
    uploadsFetched,
    submissionsFetched,
    workflowsFetched,
    isESignRequestsFeature,
    isFormSharingFeature,
    isUploadRequestsFeature,
    isWorkflowsFeature,
    isLoadingRequiredActions,
    isUserMissingNinOrIdp,
  ]);

  const filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  const sort: SortDescriptor[] = [{ field: 'DocumentDate', dir: 'desc' }];

  const modalShow = useAppSelector(selectModal);
  const docLoading = useAppSelector(selectLoading) === Loading.Show;

  const allFetched =
    signaturesFetched &&
    uploadsFetched &&
    submissionsFetched &&
    workflowsFetched;

  const accountBasedSignatures = useAccountBasedSignatures();
  const accountBasedUploads = useAccountBasedUploads();
  const accountBasedSubmissions = useAccountBasedSubmissions();
  const accountBasedWorkflows = useAccountBasedWorkflows();

  const noActions =
    accountBasedSignatures.length === 0 &&
    accountBasedUploads.length === 0 &&
    accountBasedSubmissions.length === 0 &&
    accountBasedWorkflows.length === 0;

  useEffect(() => {
    if (!hasRepresentationRights || (allFetched && noActions)) {
      setIsActionsVisible(false);
    } else if (hasRepresentationRights && allFetched && !noActions) {
      setIsActionsVisible(true);
    }
  }, [hasRepresentationRights, allFetched, noActions]);

  return (
    <>
      {(isLatestDocuments || isLatestPublications) &&
        modalShow === Modal.Show &&
        !docLoading && <DocumentModal />}

      {!!eSignStatus && (
        <section className='main-section  '>
          <div className='container'>
            {eSignStatus === 'success' && (
              <div className='alert alert-success alert-signing' role='alert'>
                <div className='icon'>
                  <i
                    className='fas fa-check-circle'
                    style={{ fontSize: '24px' }}
                  />
                </div>
                <div className='text'>
                  <h5>{t(`${ns}.signingCompleted`)}</h5>
                  <p>{t(`${ns}.signingCompletedText`)}</p>
                </div>
              </div>
            )}
            {eSignStatus === 'failed' && (
              <div className='alert alert-danger alert-signing' role='alert'>
                <div className='icon'>
                  <i
                    className='fas fa-exclamation-circle'
                    style={{ fontSize: '24px' }}
                  />
                </div>
                <div className='text'>
                  <h5>{t(`${ns}.signingUncompleted`)}</h5>
                  <p>{t(`${ns}.signingUncompletedText`)}</p>
                </div>
              </div>
            )}
          </div>
        </section>
      )}

      {isActionsVisible && isActionsFeature && (
        <section className='action-required main-section'>
          <div className='container'>
            <h2>{t(`${ns}.actionRequired`)}</h2>
            <div className='list-group'>
              {/* E-SIGN REQUESTS */}
              {isESignRequestsFeature && <SignatureRequestList />}
              {/* DOCUMENT UPLOAD REQUESTS */}
              {isUploadRequestsFeature && <UploadRequestList />}
              {/* SUBMISSION REQUESTS */}
              {isFormSharingFeature && <SubmissionList />}
              {/* WORKFLOWS */}
              {isWorkflowsFeature && <WorkflowList />}
              {isLoadingRequiredActions && <RequiredActionSkeleton />}
            </div>
          </div>
        </section>
      )}

      {isPortfoliosFeature && <PortfoliosSectionProvider />}

      {/* RECENT DOCUMENTS - Latest N documents defined in env variable REACT_APP_FEATURES and configuration file  */}
      {isDocumentListFeature && isLatestDocuments && (
        <section className='latest-documents main-section'>
          <div className='container'>
            <h2>{t(`${ns}.latestDocuments`)}</h2>
            <DocumentList
              documents={latestDocuments}
              documentsLength={0}
              filter={filter}
              sort={sort}
              docFetched={latestDocumentsFetched}
              homePage={true}
            />
          </div>
        </section>
      )}

      {isPublicationListFeature && isLatestPublications && (
        <PublicationsSectionProvider />
      )}
    </>
  );
};

export default HomePrivate;
