import React from 'react';
import { TreeListCellProps } from '@progress/kendo-react-treelist';
import { useTranslations } from '../../../hooks/useTranslations';
import { Icon } from '@progress/kendo-react-common';

const TableCellLink = ({ field, dataItem, className }: TreeListCellProps) => {
  const ns = 'construo.portfolios';
  const { t } = useTranslations();
  const cellData = field && dataItem[field] ? dataItem[field] : '';

  return (
    <td className={`k-table-td link-cell ${className}`}>
      {!!cellData ? (
        <a
          href={cellData}
          target='_blank'
          rel='noopener noreferrer'
          aria-label={t(`${ns}.tableLinkLabel`)}
        >
          <Icon className='far fa-external-link fa-fw' />
        </a>
      ) : (
        ''
      )}
    </td>
  );
};

export default TableCellLink;
