import React from 'react';
import { TreeListCellProps } from '@progress/kendo-react-treelist';
import { useInternationalization } from '@progress/kendo-react-intl';

const TableCellDate = ({ field, dataItem, className }: TreeListCellProps) => {
  const intl = useInternationalization();
  const date =
    field && dataItem[field]
      ? intl.formatDate(new Date(dataItem[field]), {
          date: 'short',
        })
      : '';

  return <td className={`k-table-td ${className}`}>{date}</td>;
};

export default TableCellDate;
